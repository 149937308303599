import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        items: [],
        items_pg: [],
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_ITEM (state, item) {
            state.items = item;
        },

        SET_ITEM_WITH_PAGINATION (state, item) {
            state.items_pg = item;
        },

        ITEM_LOADING (state, loading) {
            state.projectLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateItem ({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_item + payload.item.id, payload.item, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchItemWithPagination', {currentPage:payload.page, perPage:payload.perPage, search_term:payload.search_term});
            return response
        },

        async deleteItem ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_item + payload.item, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchItemWithPagination', {currentPage:payload.page, perPage:payload.perPage, search_term:payload.search_term});
            return response
        },

        async fetchItemWithPagination ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
               
                let url = V1API.get_item_with_pagination + '?' + params.toString();
                let result = await axios.get(url, config);
                let response = result.data
                commit('SET_ITEM_WITH_PAGINATION', response.results);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchItem ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_item, config);
                let response = result.data

                commit('SET_ITEM', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addItem ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_item, payload.item, config).then(result=>{
                    let data = result.data
                    return data
                });
                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchItemWithPagination', {currentPage:payload.page, perPage:payload.perPage, search_term:payload.search_term})
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },

    getters: {}
}
