import { render, staticRenderFns } from "./request_list.vue?vue&type=template&id=2120f9c9&scoped=true&"
import script from "./request_list.vue?vue&type=script&lang=js&"
export * from "./request_list.vue?vue&type=script&lang=js&"
import style0 from "./request_list.vue?vue&type=style&index=0&id=2120f9c9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2120f9c9",
  null
  
)

export default component.exports