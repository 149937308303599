<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Bill Create" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input class="form-control" type="text" placeholder="Enter email address" v-model="bill.email">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Currency</label>
                                        <select class="form-control digits" v-model="bill.currency">
                                            <option v-for="(currency, index) in currencies" :key="index">{{ currency }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Vendor <span><button class="btn-success ml-3" v-b-modal.modal-prevent-closing>Add New</button></span></label>
                                        <select class="form-control digits" v-model="bill.vendor">
                                            <option v-for="(vendor, index) in vendors" :key="index" :value="vendor.user.id">{{ vendor.user.full_name }}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Vendor Address</label>
                                        <input class="form-control" type="text" placeholder="Enter vendor address" v-model="bill.vendor_address">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Billing Address</label>
                                        <input class="form-control" type="text" placeholder="Enter billing address" v-model="bill.billing_address">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Bill Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="bill.bill_date"></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Due Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="bill.due_date"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Upload Attachment</label>
                                        <b-form-file v-model="bill.bill_attachment" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadFile"></b-form-file>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Items</label>
                                        <ItemTable style="margin-bottom: 10px;" id="item-table" @itemsUpdated="updateItems" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <!-- Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Total</label>
                                        <input class="form-control" type="number" placeholder="Enter total amount" disabled v-model="bill.total" />
                                    </div>

                                    <!-- Vat -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Vat (%)</label>
                                        <input class="form-control" type="number" placeholder="Enter tax (%)" v-model="bill.vat" />
                                    </div>

                                    <!-- Tax -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Tax (%)</label>
                                        <input class="form-control" type="number" placeholder="Enter tax (%)" v-model="bill.tax" />
                                    </div>

                                    <!-- Discount -->
                                    <!-- <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                            <label style="margin-top: 8px; font-weight: 500; width: 120px;">Discount (%)</label>
                                            <input class="form-control" type="number" placeholder="Enter discount (%)" v-model="bill.discount" />
                                        </div> -->

                                    <!-- Grand Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Grand Total ({{ bill.currency ? bill.currency : "BDT" }})</label>
                                        <input class="form-control" type="number" placeholder="Enter grand total" disabled v-model="bill.subtotal" />
                                    </div>

                                    <!-- In Words -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">In Words</label>
                                        <input class="form-control" type="text" placeholder="Enter grand total in words" v-model="bill.in_words" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Remarks</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter remarks..." rows="2" v-model="bill.remarks"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                        <router-link to="/finance-and-accounts/finance/bill/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Vendor Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Create Vendor Contact" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Name" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="item.first_name" placeholder="Enter name"></b-form-input>
            </b-form-group>
            <b-form-group label="Email" label-for="name-input" invalid-feedback="Email is required">
                <b-form-input id="name-input" v-model="item.email" placeholder="Enter email address"></b-form-input>
            </b-form-group>
            <b-form-group label="Phone No" label-for="name-input" invalid-feedback="Phone no is required">
                <b-form-input id="name-input" v-model="item.phone" placeholder="Enter phone number"></b-form-input>
            </b-form-group>
            <b-form-group label="Company" label-for="name-input" invalid-feedback="Company is required">
                <b-form-input id="name-input" v-model="item.company" placeholder="Enter company name"></b-form-input>
            </b-form-group>
            <b-form-group label="Address" label-for="name-input" invalid-feedback="Address is required">
                <b-form-input id="name-input" v-model="item.address" placeholder="Enter address"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
    computed
} from "vue";
import ItemTable from "./item-table.vue";
import moment from "moment";

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable
    },

    data() {
        return {
            bill: {
                // vendor: null,
                // invoice: null,
                email: '',
                currency: 'BDT',
                vendor_address: '',
                vendor: '',
                billing_address: '',
                bill_date: null,
                due_date: null,
                bill_attachment:null,
                details: [],
                details_in_depth: [],
                total: computed(() => {
                    let total = 0;
                    if (this.bill.details_in_depth.length >= 1) {
                        this.bill.details_in_depth.map((item) => {
                            total = total + item.amount;
                        });
                    }
                    return total;
                }),
                tax: null,
                vat: null,
                discount: null,
                subtotal: computed(() => {
                    if (this.bill.total !== 0) {
                        const tax = this.bill.tax || 0;
                        const vat = this.bill.vat || 0;
                        const discount = this.bill.discount || 0;
                        let gtotal =
                            this.bill.total +
                            tax * (this.bill.total / 100) +
                            vat * (this.bill.total / 100) -
                            discount * (this.bill.total / 100);
                        return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                    }
                    return 0;
                }),
                in_words: '',
                remarks: ''
            },
            item: {
                first_name: '',
                email: '',
                phone: '',
                company: '',
                address: ''
            },

            currencies: ['BDT', 'USD', 'EUR'],
        };
    },

    computed: {
        ...mapState({
            items: state => state.item.items,
            vendors: state => state.vendor.vendor_without_pg,
            // invoices: state => state.invoice.invoice
        })
    },

    mounted() {
        this.$store.dispatch("item/fetchItem")
        this.$store.dispatch("vendor/fetchVendor_without_pg") 
        // this.$store.dispatch("invoice/fetchInvoice")
    },

    methods: {
        uploadFile(e) {
            this.bill.bill_attachment = e.target.files[0];
        },

        resetModal() {
            this.item.first_name = ''
            this.item.email = ''
            this.item.phone = ''
            this.item.company = ''
            this.item.address = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        updateItems(data) {
            if (data) {
                this.bill.details_in_depth = data;
                this.bill.details = data;
            }
        },

        handleSubmit() {
            this.$store.dispatch("vendor/addVendor", {item:this.item}).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.item.first_name = ''
                    this.item.email = ''
                    this.item.phone = ''
                    this.item.company = ''
                    this.item.address = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        onSubmit() {
            if (this.bill.bill_date !== null) {
                this.bill.bill_date = moment(String(this.bill.bill_date)).format("YYYY-MM-DD");
            }
            if (this.bill.due_date !== null) {
                this.bill.due_date = moment(String(this.bill.due_date)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("bill/addBill", this.bill).then(response => {
                // console.log(response)
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    setTimeout(() => {
                        this.$router.push('/finance-and-accounts/finance/bill/list')
                    }, 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
