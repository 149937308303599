<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Wallet"/>
        
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i> 
                                <h7>Add New Wallet</h7>
                            </button>
                        </div>
            
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(wid)="data">
                                        <span>
                                            <router-link :to="{ path: '/finance-and-accounts/account/wallet/' + data.item.wid }">
                                                {{ data.item.wid }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete"></feather>
                                            <feather @click="editWallet(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Create Wallet -->
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Create Wallet"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    label="Title"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="ledger.title"
                        placeholder="Enter wallet title"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Ledger"
                    label-for="name-input"
                    invalid-feedback="Ledger is required"
                >
                    <b-form-select v-model="ledger.ledger" :options="options"></b-form-select>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Edit Wallet -->
        <b-modal 
            id="modal-prevent-closing-edit" 
            ref="modal"
            title="Edit Wallet"  
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <b-form-group
                    label="Title"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="edit_ledger.title"
                        placeholder="Enter wallet title"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Ledger"
                    label-for="name-input"
                    invalid-feedback="Ledger is required"
                >
                    <b-form-select v-model="edit_ledger.ledger" :options="options"></b-form-select>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
  
<script>
  
export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'wid', label: 'Wallet ID', sortable: true },
                { key: 'title', label: 'Title', sortable: true },
                { key: 'ledger', label: 'Ledger', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],
    
            items: [
                { wid:'WID-101', title: 'Digital Marketing', ledger: 'Digital Marketing' },
                { wid:'WID-126', title: 'Front-end', ledger: 'Front-end' },
                { wid:'WID-133', title: 'Back-end', ledger: 'Back-end' }
            ],

            ledger: {
                title: '',
                ledger: ''
            },

            edit_ledger: {
                title: '',
                ledger: ''
            },

            options: ["Digital Marketing", "Front-end", "Back-end"],
    
            filter: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.ledger.title = ''
            this.ledger.ledger = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleSubmit() {
            alert(JSON.stringify(this.ledger))

            // Hide the modal manually
            this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editWallet(ledger) {
            this.edit_ledger.title = ledger.title
            this.edit_ledger.ledger = ledger.ledger
        },

        handleEditSubmit() {
            alert(JSON.stringify(this.edit_ledger))

            // Hide the modal manually
            this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing-edit')
            })
        }
    }
};
</script>
  