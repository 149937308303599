import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'ASSET_UPDATE',
    smsDelete : 'ASSET_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        asset: [],
        asset_count:null,
        asset_detail: {},
        get_my_asset:[],
        get_user_list:[],
        asset_type:[],
        count:null,


        asset_allocation_list:[],
        asset_allocation_count:null,

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_ASSET (state, {results,pg_count}) {
            state.asset = results;
            state.asset_count = pg_count;
        },

        SET_MY_ASSET(state, { results, count }){
            state.get_my_asset = results,
            state.count = count
        },
        SET_ASSET_ALLOCATION_LIST(state,{results,pg_count}){
            state.asset_allocation_list = results
            state.asset_allocation_count = pg_count;
        },
        SET_ASSET_TYPE(state,type){
            state.asset_type = type
        },
        
        SET_ASSET_DETAIL (state, asset) {
            state.asset_detail = asset;
        },



        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },
        SET_USER_LIST (state, asset) {
            state.get_user_list = asset;
        },

    },

    actions: {
        
        async fetchAsset ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let params = new URLSearchParams();

                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
                if (payload.search_term) params.append('search_term', payload.search_term);
               
    
                let url = V1API.get_asset_list + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results,pg_count} = result.data
                commit('SET_ASSET', {results,pg_count})

                return result;

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },


        
        
        async fetchMyAsset ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let params = new URLSearchParams();
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
                if (payload.search_term) params.append('search_term', payload.search_term);
               
                let url = V1API.get_my_asset + '?' + params.toString();
                let result = await axios.get(url, config);

                const { results, count } = result.data;
                commit('SET_MY_ASSET', { results, count });

                return result;

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },
        async fetchUserList ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.user_list, config);
                let response = result.data.data
              
                commit('SET_USER_LIST', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchAssetDetails ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.asset_details + payload, config);
                let response = result.data

                commit('SET_ASSET_DETAIL', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },


        async updateAsset ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  },
                  
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
               
                async () => {
                    payload.payload.specification=JSON.stringify(payload.payload.specification)
                

                    response= await axios.patch(V1API.update_asset+payload.id,payload.payload,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    // dispatch('fetchAsset');

                }
            )
            return response
        },
    

        
        async deleteAsset ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_asset + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchAsset',{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status,search_term:payload.search_term });

            return response
        },

        

        async addAsset ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{

                payload.specification=JSON.stringify(payload.specification)
         

                let data= new FormData()
                let payloadData = buildFormData(data, payload);
           

                let response = await axios.post(V1API.create_asset, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch ('fetchAsset')
                return response

            }catch (e) {
                
                commit('SUBMIT_ERROR', true);
            }finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },

        async assetAllocate ({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              
            };
            try{
                
                let response = await axios.post(V1API.asset_allocation_create, payload.member, config).then(result=>{
                    let data = result.data
                    return data
                });
               
                dispatch('fetchAsset',{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status,search_term:payload.search_term });
                commit('SUBMIT_SUCCESS', true);
                return response

            }catch (e) {     
                commit('SUBMIT_ERROR', true);
            }finally {   
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchAssetAllocationList ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };


            try{
                let params = new URLSearchParams();
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.employee) params.append('employee', payload.employee);
               
                let url = V1API.asset_allocation_list + '?' + params.toString();
                let result = await axios.get(url, config);

                const { results, pg_count} = result.data;
                commit('SET_ASSET_ALLOCATION_LIST', { results, pg_count });
                return result;




                // let result = await axios.get(V1API.asset_allocation_list, config);
                // let response = result.data.data
              
                // commit('SET_ASSET_ALLOCATION_LIST', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {  
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteAssetAllocation ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.asset_allocation_delete + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchAsset',{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status,search_term:payload.search_term });

            if(payload.from_detail){
                dispatch('fetchAssetDetails',payload.asset_id);
            }
            
            if(payload.from_allocation){
                dispatch('fetchAssetAllocationList',{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date, end_date:payload.end_date, status:payload.status,search_term:payload.search_term, employee:payload.employee});
            }
            

            return response
        },

        async addAssetAttachment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };
            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.attachment);
        
                let response = await axios.post(V1API.asset_add_attachment, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch("fetchAssetDetails",payload.asset_id)
                return response

            }catch (e) {
               
                commit('SUBMIT_ERROR', true);
            }finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteAttachmentFromAsset ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.asset_delete_attachment + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchAssetDetails',payload.asset_id)
            
            return response
        },



        ////

        
        async fetchAssetType({commit}) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_asset_type, config);
                let response = result.data.data

                commit('SET_ASSET_TYPE', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },
        

        async addAssetType ({commit, dispatch}, payload) {
            commit('SUBMIT_ERROR', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },

            };
            try{

                let response = await axios.post(V1API.create_asset_type, payload, config).then(result=>{

                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchAssetType')
                return response

            }catch (e) {

                commit('SUBMIT_ERROR', true);
            }finally {

                commit('SUBMIT_LOADING', false);
            }
        },



        async deleteAssetType ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_asset_type + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchAssetType');

            return response
        },


        async updateAssetType ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,

                async () => {
                    response = await axios.patch(V1API.update_asset_type + payload.id, {
                        name: payload.name,
                       
                    }, config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchAssetType');

                }
            )
            return response
        },







    },

    getters: {}
}
