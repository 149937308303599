<template>
<div>
    <Breadcrumbs main="HR" title="Time Sheet" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header d-flex flex-column" style="gap:8px">
                        <h6 class="text-danger">{{ user.user_name }}</h6>
                        <div style="display: flex; flex-direction: row; gap:10px">
                            <h7 class="font-weight-bold">{{ user.department + ','+' '+user.designation }}</h7>  
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="workSummary" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ (currentPage - 1) * perPage + data.index + 1 }}
                                </template>

                                <template #cell(date)="data">
                                    <!-- <router-link :to="`/hr/time-sheet/${this.$route.params.id}/${data.item.date}`" class="link-style"> {{ data.item.date  }} </router-link> -->
                                    <p @click="details(data.item)" class="link-style" style="cursor: pointer;">{{ data.item.date }}</p>

                                </template>
                                <template #cell(total_working_duration)="data">
                                    {{ workingDuration(data.item.total_working_duration) }}
                                </template>

                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this Employee?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true
                },

                {
                    key: 'total_working_duration',
                    label: 'Working Hours',
                    sortable: true
                },

            ],

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],

        };
    },

    computed: {
        ...mapState({
            workSummary: state => state.punch.work_sheet_summary,
            user: state => state.punch.user_info,

        }),

        // formattedWorkSummary() {
        //     return this.workSummary.map(item => {
        //         const totalSeconds = parseFloat(item.total_working_duration);
        //         const hours = Math.floor(totalSeconds / 3600);
        //         const minutes = Math.floor((totalSeconds % 3600) / 60);
        //         const seconds = Math.floor(totalSeconds % 60);

        //         let formatted_duration;
        //         if (hours > 0) {
        //             formatted_duration = `${hours}h ${minutes}m ${seconds}s`;
        //         } else if (minutes > 0) {
        //             formatted_duration = `${minutes}m ${seconds}s`;
        //         } else {
        //             formatted_duration = `${seconds}s`;
        //         }

        //         return {
        //             ...item,
        //             formatted_duration,
        //         };
        //     });
        // },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },

    },

    mounted() {

        this.$store.dispatch("punch/fetchWorkTimeSheetByID", this.$route.params.id).then(() => {
            this.items = this.workSummary
            this.totalRows = this.items.length;
        });
    },

    methods: {
        workingDuration(time) {
            const totalSeconds = parseFloat(time);
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = Math.floor(totalSeconds % 60);

            let formatted_duration;
            if (hours > 0) {
                formatted_duration = `${hours}h ${minutes}m ${seconds}s`;
            } else if (minutes > 0) {
                formatted_duration = `${minutes}m ${seconds}s`;
            } else {
                formatted_duration = `${seconds}s`;
            }

            return formatted_duration;
        },

        details(item) {
           
            const id = this.$route.params.id;
            const date = item.date;
            const workingHours = item.total_working_duration;

            this.$router.push(`/hr/time-sheet/${id}/${date}/${workingHours}`);
        },
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

    }
};
</script>
