<template>
<div>
    <Breadcrumbs main="HR" submain="My Workspace" title="My Assets" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group style="width: 340px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="statusSelected || start_date || end_date">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="my_asset" :fields="tablefields" :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(asset_name)="data">
                                        <span>
                                            <router-link :to="`/my-workspace/my-asset/details/${data.item.asset.id}`" class="link-style">
                                                {{data.item.asset.name}}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(status)="data">
                                        <span v-if="data.item.asset.status == 'PENDING'">
                                            <b-badge pill variant="warning">{{ data.item.asset.status }}</b-badge>
                                        </span>
                                        <span v-if="data.item.asset.status == 'APPROVED'">
                                            <b-badge pill variant="success">{{ data.item.asset.status }}</b-badge>
                                        </span>
                                        <span v-if="data.item.asset.status == 'RETURNED'">
                                            <b-badge pill variant="danger">{{ data.item.asset.status }}</b-badge>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Multiselect from 'vue-multiselect';
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import {
    myHost
} from '../../../_helpers/host';

export default {
    components: {
        Multiselect,
        Datepicker
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'asset_name',
                    label: 'Asset',
                    sortable: true
                },
                {
                    key: 'asset.asset_id',
                    label: 'Asset ID',
                    sortable: true
                },
                {
                    key: 'allocation_date',
                    label: 'Allocation Date',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                }
            ],
            filter: null,
            start_date: null,
            end_date: null,
            statusSelected: '',
            filterShow: false,
            statuses: [{
                    id: 1,
                    name: 'Pending',
                    value: 'PENDING'
                },
                {
                    id: 2,
                    name: 'Approved',
                    value: 'APPROVED'
                },
                {
                    id: 3,
                    name: 'Returned',
                    value: 'RETURNED',
                },

            ],
            initialLoad: true,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            isModifyingDate:false,
        };
    },

    watch: {
        filter(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("asset/fetchMyAsset", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }

        },

        start_date(newVal, oldVal) {
            if (!this.initialLoad) {

                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("asset/fetchMyAsset", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false; 
                        });
                    } else {
                        this.$store.dispatch("asset/fetchMyAsset", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false; 
                        });
                    }
                }

            }

        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;

                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("asset/fetchMyAsset", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false; 
                        });
                    } else {
                        this.$store.dispatch("asset/fetchMyAsset", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false; 
                        });
                    }

                }

            }

        },

        statusSelected(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("asset/fetchMyAsset", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }

        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/my-workspace/my-assets',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.$store.dispatch("asset/fetchMyAsset", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/my-workspace/my-assets',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.$store.dispatch("asset/fetchMyAsset", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        }
    },

    computed: {
        ...mapState({
            my_asset: state => state.asset.get_my_asset,
            loading: state => state.asset.submitLoading,
            error: state => state.asset.submitError
        }),

        formattedOptions() {
            return [{
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        }
    },

    mounted() {

        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {

                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitialEOD();
            } else {

                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitialEOD();
            }
        }
    },

    methods: {
        fetchInitialEOD() {
            this.$store.dispatch("asset/fetchMyAsset", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        },
        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        clearFilter() {
            this.filterShow = false,
                this.filter = null,
                this.statusSelected = '',
                this.start_date = null,
                this.end_date = null
        }
    }
};
</script>
