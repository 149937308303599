
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDmKG8V1lycwdQhnVD3vjJ8l093GCUQM1g",
  authDomain: "smicee-fedbe.firebaseapp.com",
  projectId: "smicee-fedbe",
  storageBucket: "smicee-fedbe.appspot.com",
  messagingSenderId: "230946566199",
  appId: "1:230946566199:web:48ea5592b08c3287488fb2",
  measurementId: "G-M9GP7L9ENM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export default app

