import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'ASSET_UPDATE',
    smsDelete : 'ASSET_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        my_updates: [],
        my_contact:[],
        count:null,
        submitLoading:false,
        submitError:false,
        submitSuccess:false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_MY_UPDATES (state, updates) {
            state.my_updates = updates;
        },
        SET_MY_CONTACT(state, {results,pg_count}) {
            state.my_contact = results;
            state.count = pg_count;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },


    },

    actions: {
        
        // async fetchMyUpdates ({ commit },payload) {
        //     commit('SUBMIT_LOADING', true);
        //     commit('SUBMIT_ERROR', false);

        //     let config = {
        //         headers: {
        //           Authorization: "Bearer " + localStorage.getItem("access_token"),
        //         }
        //     };

        //     try{
        //         if(payload.ref_type){
        //             let result = await axios.get(V1API.get_my_updates+'?ref_type='+payload.ref_type, config);
        //             let response = result.data.activity_log
                  
        //             commit('SET_MY_UPDATES', response);
        //         }
        //         else{
        //             let result = await axios.get(V1API.get_my_updates, config);
        //             let response = result.data.activity_log
                  
        //             commit('SET_MY_UPDATES', response);
        //         }
               

        //     } catch(error) {
        //         commit('SUBMIT_ERROR', true);
        //     }
        //     commit('SUBMIT_LOADING', false);
        // },


        async fetchMyUpdates({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
        
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
        
            try {
            
                let params = new URLSearchParams();
        
                if (payload.ref_type) params.append('ref_type', payload.ref_type);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
        
           
                let url = V1API.get_my_updates + '?' + params.toString();
        
                let result = await axios.get(url, config);
                let response = result.data.activity_log;
        
                commit('SET_MY_UPDATES', response);
        
            } catch (error) {
                commit('SUBMIT_ERROR', true);
            }
        
            commit('SUBMIT_LOADING', false);
        },
        



                
        async fetchMyContact ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.employee) params.append('employee', payload.employee);
               
           
                let url = V1API.get_my_contact + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results, pg_count} = result.data

                commit('SET_MY_CONTACT', {results, pg_count});
                return result

               

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally{
                commit('SUBMIT_LOADING', false);
            }
          
        },


        
        async readMyUpdates ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.read_my_updates+payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
              
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }, 






    },

    getters: {}
}
