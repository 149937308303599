<template>
<div>
    <Breadcrumbs main="My Workspace" title="My Contact" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">

                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex" style="gap:20px">
                                <b-input-group style="width: 400px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="search_term || selectedDepartment ||selectedDesignation || selectedEmployee">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDesignation">
                                        <b-form-select v-model="selectedEmployee" :options="formattedEmployee"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="contact_list" :fields="tablefields"  :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(typeOfLeave)="data">
                                        <div>
                                            <p v-b-tooltip.hover title="Details" v-b-modal.modal-prevent-closing-edit>{{ data.item.leave_type.name}}</p>
                                        </div>
                                    </template>

                                    <template #cell(photo)="data">
                                        <img :src="host+ data.item.photo" alt="" class="img-60 rounded-circle" style="object-fit: cover;">
                                    </template>

                                    <template #cell(user_name)="data">
                                        <span style="display: flex; flex-direction: column; gap: 4px;">
                                            <h7>{{data.item.user_name}}</h7>
                                            <small><span style="font-size: medium;">
                                                <b-badge pill variant="primary">
                                                    <a :href="`mailto:${data.item.email}`" class="text-white">
                                                        {{data.item.email}}
                                                    </a>
                                                </b-badge>
                                            </span></small>
                                        </span>
                                    </template>

                                    <template #cell(blood_group)="data">
                                        <h7 v-if="data.item.blood_group">{{ data.item.blood_group }}</h7>
                                        <h7 v-else> N/A </h7>
                                    </template>

                                    <template #cell(phone_number)="data">
                                        <h7 v-if="data.item.phone_number">
                                            <a :href="`tel:${data.item.phone_number}`">
                                                {{ data.item.phone_number }}
                                            </a>
                                        </h7>
                                        <h7 v-else> N/A </h7>
                                    </template>

                                    <template #cell(address)="data">
                                        <h7 v-if="data.item.address">{{ data.item.address }}</h7>
                                        <h7 v-else> N/A </h7>
                                    </template>

                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import { myHost } from '../../../_helpers/host';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'photo',
                    label: 'Picture',
                    sortable: true
                },
                {
                    key: 'user_name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'blood_group',
                    label: 'Blood Group',
                    sortable: true
                },
                {
                    key: 'phone_number',
                    label: 'Phone Number',
                    sortable: true
                },
                {
                    key: 'address',
                    label: 'Address',
                    sortable: true
                }
            ],

            filter: null,
            initialLoad: true,

            filterShow: false,
            selectedDepartment: '',
            selectedDesignation: '',
            selectedEmployee: '',

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            isModifyingDate: false,
        };
    },

    watch: {
        selectedDepartment(newVal, oldVal) {
            this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
        },

        selectedDesignation(newVal, oldVal) {
            this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
        },

        selectedEmployee(newVal, oldVal) {
            if(!this.initialLoad){
                this.$store.dispatch("myUpdates/fetchMyContact", {
                perPage: this.perPage,
                currentPage: 1,
                search_term: this.filter,
                employee: this.selectedEmployee
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
                this.initialLoad = false;
            });
            }
           
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/my-workspace/my-contact',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    },
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("myUpdates/fetchMyContact", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    search_term: this.filter,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/my-workspace/my-contact',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("myUpdates/fetchMyContact", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    search_term: this.filter,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        },

        filter(newVal, oldVal) {
            if(!this.initialLoad){
                this.$store.dispatch("myUpdates/fetchMyContact", {
                perPage: this.perPage,
                currentPage: 1,
                search_term: this.filter,
                employee: this.selectedEmployee
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
                this.initialLoad = false;
            });
            }
           
        }
    },

    computed: {
        ...mapState({
            contact_list: (state) => state.myUpdates.my_contact,
            count: (state) => state.myUpdates.count,
            loading: (state) => state.myUpdates.submitLoading,
            error: (state) => state.myUpdates.submitError,
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation
        }),

        formattedEmployee() {
            return [
                {
                    value: '',
                    text: 'Select Employee',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [
                {
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [
                {
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        }
    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("department/fetchDepartments")

        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {
               
                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitial();
            } else {
                
                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitial();
            }
        }
    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("myUpdates/fetchMyContact", {
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    search_term: this.filter,
                    employee: this.selectedEmployee
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        },
        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        clearFilter() {
            this.filterShow = false,
            this.filter = null,
            this.selectedDepartment = '',
            this.selectedDesignation = '',
            this.selectedEmployee = ''
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.img-60 {
    width: 60px !important;
    height: 60px !important;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
