<template>
<div>
    <Breadcrumbs main="HR" title="On Boarding" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" v-if="!policyLoading && !policyError && !complianceLoading && !complianceError">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row mt-4">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Boarding Type</label>

                                        <select class="form-control digits" v-model="boarding_type">
                                            <option v-for="(type, index) in onBoardType" :key="index" :value="type.id">{{ type.title }}</option>
                                        </select>

                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Policy</label>
                                        <select class="form-control digits" v-model="policy">
                                            <option v-for="(type, index) in policyForm" :key="index" :value="type.id">{{ type.form_name }}</option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Compliance</label>
                                        <select class="form-control digits" v-model="compliance">
                                            <option v-for="(type, index) in complianceForm" :key="index" :value="type.id">{{ type.form_name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group mb-0">
                                        <button @click="addPolicy()" class="btn btn-success mr-3">Add Policy</button>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group mb-0">
                                        <button @click="addCompliance()" class="btn btn-success mr-3">Add Compliance</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
            <div class="col-sm-12" v-else>
                <div class="card">
                    <div class="card-body">
                        <div class="loader-box">
                            <div class="loader-10"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="card-container" v-if="!policyLoading && !policyError">
                    <div class="card p-3 card-one-three task-board-comments">
                        <h6 class="my-2">Policy</h6>

                        <b-table sticky-header show-empty :items="policyList" :fields="tablePolicy" head-variant="light">
                            <template #cell(index)="data">
                                {{ (currentPage - 1) * perPage + data.index + 1 }}
                            </template>

                            <template #cell(onboarding)="data">
                                <span v-if="data.item.onboarding_flow.title === 'Client'">
                                    <b-badge pill variant="primary">{{ data.item.onboarding_flow.title }}</b-badge>
                                </span>
                                <span v-if="data.item.onboarding_flow.title === 'Employee'">
                                    <b-badge pill variant="success">{{ data.item.onboarding_flow.title }}</b-badge>
                                </span>

                                <span v-if="data.item.onboarding_flow.title === 'Vendor'">
                                    <b-badge pill variant="info">{{ data.item.onboarding_flow.title }}</b-badge>
                                </span>
                            </template>

                            <template #cell(action)="data">
                                <span style="display: flex; gap: 12px;">
                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-policy-delete @click="deletePolicy(data.item.id)" title="Delete"></feather>
                                </span>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="card-container" v-else>
                    <div class="card-body">
                        <div class="loader-box">
                            <div class="loader-10"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card-container" v-if="!complianceLoading && !complianceError">
                    <div class="card p-3 card-one-three task-board-comments">
                        <div class="d-flex justify-content-between ">
                            <h6 class="my-2">Compliance</h6>
                        </div>
                        <b-table sticky-header show-empty :items="complianceList" :fields="tableCompliance" head-variant="light">
                            <template #cell(index)="data">
                                {{ (currentPage - 1) * perPage + data.index + 1 }}
                            </template>

                            <template #cell(onboarding)="data">
                                <div v-if="data.item.onboarding_flow != null">
                                    <span v-if="data.item.onboarding_flow.title === 'Client'">
                                        <b-badge pill variant="primary">{{ data.item.onboarding_flow.title }}</b-badge>
                                    </span>
                                    <span v-if="data.item.onboarding_flow.title === 'Employee'">
                                        <b-badge pill variant="success">{{ data.item.onboarding_flow.title }}</b-badge>
                                    </span>

                                    <span v-if="data.item.onboarding_flow.title === 'Vendor'">
                                        <b-badge pill variant="info">{{ data.item.onboarding_flow.title }}</b-badge>
                                    </span>
                                </div>
                            </template>

                            <template #cell(action)="data">
                                <span style="display: flex; gap: 12px;">
                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-compliance-delete @click="deleteCompliance(data.item.id)" title="Delete"></feather>
                                </span>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="card-container" v-else>

                </div>
            </div>
        </div>
    </div>

    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-compliance-delete" centered hide-header @ok="handleComplianceDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this compliance from on boarding?</div>
    </b-modal>

    <!-- Delete Modal -->
    <b-modal id="modal-policy-delete" centered hide-header @ok="handlePolicyDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this policy from on boarding?</div>
    </b-modal>

</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import Multiselect from 'vue-multiselect';
import {
    mapState
} from 'vuex';

export default {

    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect
    },

    data() {
        return {

            boarding_type: null,
            compliance: null,
            policy: null,

            tableCompliance: [{
                    key: 'index',
                    label: '#'
                },

                {
                    key: 'compliance.form_name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'onboarding',
                    label: 'Boarding Type',
                    sortable: true
                },

                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                },

            ],

            tablePolicy: [{
                    key: 'index',
                    label: '#'
                },

                {
                    key: 'policy.form_name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'onboarding',
                    label: 'Boarding Type',
                    sortable: true
                },

                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                },

            ],
            delete_compliance: null,
            delete_policy: null,

        };
    },
    watch: {

    },

    methods: {

        deletePolicy(id) {
            this.delete_policy = id
        },
        handlePolicyDeleteOk() {
            this.$store.dispatch("onBoarding/deleteonBoardPolicy", this.delete_policy).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_compliance = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
        deleteCompliance(id) {
            this.delete_compliance = id
        },
        handleComplianceDeleteOk() {
            this.$store.dispatch("onBoarding/deleteonBoardCompliance", this.delete_compliance).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_compliance = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
        addCompliance() {
            if (this.compliance == null || this.boarding_type == null) {
                Swal.fire({
                    title: "Error!",
                    text: 'Please select boarding type and compliance',
                    icon: "error"
                });
            } else {
                this.$store.dispatch("onBoarding/addonBoardCompliance", {
                    onboarding_flow: this.boarding_type,
                    compliance: this.compliance
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.boarding_type = '',
                            this.compliance = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }

        },

        addPolicy() {

            if (this.policy == null || this.boarding_type == null) {
                Swal.fire({
                    title: "Error!",
                    text: 'Please select boarding type and policy',
                    icon: "error"
                });
            } else {
                this.$store.dispatch("onBoarding/addonBoardPolicy", {
                    onboarding_flow: this.boarding_type,
                    policy: this.policy
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.boarding_type = '',
                            this.policy = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }

        }
    },
    computed: {
        ...mapState({
            onBoardType: state => state.onBoarding.onBoardingType,
            complianceForm: state => state.compliance.compliance_form_list,
            policyForm: state => state.policy.policy_form_list,

            complianceList: state => state.onBoarding.onBoardCompliance,
            policyList: state => state.onBoarding.onBoardPolicy,

            policyLoading: state => state.policy.submitLoading,
            policyError: state => state.policy.submitError,

            complianceLoading: state => state.compliance.submitLoading,
            complianceError: state => state.compliance.submitError,

        }),

    },
    mounted() {

        this.$store.dispatch("onBoarding/fetchOnBoardTypeList");
        this.$store.dispatch("compliance/fetchComplianceFormList");
        this.$store.dispatch("policy/fetchPolicyFormList");

        this.$store.dispatch("onBoarding/fetchOnBoardCompliance");
        this.$store.dispatch("onBoarding/fetchOnBoardPolicy");

    },

};
</script>

<style scoped>
.task-board {

    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

::-webkit-scrollbar {
    height: 5px;
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
