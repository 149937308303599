<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Invoices" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="width: 100%">
                        <div style="max-width: fit-content">
                            <router-link to="/finance-and-accounts/finance/invoice/create/new">
                                <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                    <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                        <i class="fa fa-light fa-plus"></i>
                                        <h7>Add New Invoice</h7>
                                    </div>
                                </button>
                            </router-link>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 10px;">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                        <feather type="filter"></feather>
                                        <h7>Filter</h7>
                                    </div>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="start_date || end_date || status || client || project || selectedDepartment || selectedDesignation || selectedEmployee">
                                        <feather type="x"></feather>
                                        <h7>Clear All</h7>
                                    </div>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="status" :options="formattedStatus"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="project" :options="formattedProjects"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="client" :options="formattedClients"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDesignation">
                                        <b-form-select v-model="selectedEmployee" :options="formattedEmployee"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(invoice_id)="data">
                                        <span>
                                            <router-link :to="`/finance-and-accounts/finance/invoice/${data.item.invoice_id}`" class="link-style">
                                                {{ data.item.invoice_id }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(created_by)="data">
                                        <span>
                                            {{data.item.created_by.full_name }}
                                        </span>
                                    </template>

                                    <template #cell(subtotal)="data">
                                        <div class="d-flex flex-column" style="width: 200px;">
                                            <span class="font-weight-bold">Total: {{ data.item.subtotal }} {{ data.item.currency }}</span>
                                            <span> <span class="text-success font-weight-bold">Paid:</span> {{ data.item.paid_amount }} {{ data.item.currency }}</span>
                                            <span> <span class="text-danger font-weight-bold">Due:</span> {{ data.item.due_amount }} {{ data.item.currency }}</span>
                                        </div>
                                    </template>

                                    <template #cell(record)="data">
                                        <div @click="recordPayment(data)">
                                            <b-badge variant="primary" v-if="data.item.due_amount > 0" v-b-modal.modal-record>Record Payment</b-badge>
                                        </div>
                                    </template>

                                    <template #cell(status)="row">
                                        <!-- @click="getStatus(row.value, row.item.id)" v-b-modal.modal-prevent-closing -->
                                        <span v-if="row.value == 'PARTIALLY-PAID'">
                                            <b-badge pill variant="success">{{ row.value }}</b-badge>
                                        </span>
                                        <span v-if="row.value == 'FULLY-PAID'">
                                            <b-badge pill variant="primary">{{ row.value }}</b-badge>
                                        </span>
                                        <span v-if="row.value == 'DUE'">
                                            <b-badge pill variant="danger">{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteInvoice(data.item.id)" title="Delete"></feather>
                                            <router-link :to="`/finance-and-accounts/finance/invoice/edit/${data.item.invoice_id}`">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                            </router-link>
                                            <!-- <feather type="sidebar" size="20px" style="color: #51bb25; cursor: pointer;" v-b-tooltip.hover title="Invoice Payments"></feather> -->
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>
                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this invoice?</div>
    </b-modal>

    <!-- Record Payment -->
    <b-modal id="modal-record" ref="modal" title="Record Payment" @show="resetModal" @hidden="resetModal" @ok="handleRecordOk">
        <form ref="form" @submit.stop.prevent="handleRecordSubmit">
            <b-form-group label="Amount" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="record.paid_amount" placeholder="Enter Amount"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <b-modal id="modal-prevent-closing" ref="modal" title="Update Invoice Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'invoice_id',
                    label: 'Invoice ID',
                    sortable: true
                },
                {
                    key: 'created_by',
                    label: 'Raised By',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'subtotal',
                    label: 'Amount',
                    sortable: true
                },
                {
                    key: 'invoice_date',
                    label: 'Invoice Date',
                    sortable: true
                },
                {
                    key: 'due_date',
                    label: 'Due Date',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },

                {
                    key: 'record',
                    label: 'Record',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            record: {
                paid_amount: '',
                invoice: '',
            },
            invoice_due: '',
            initialLoad: true,
            isModifyingDate: false,

            filterShow: false,
            filter: null,
            start_date: null,
            end_date: null,
            status: '',
            client: '',
            project: '',
            selectedDepartment: '',
            selectedDesignation: '',
            selectedEmployee: '',
            delete_value: null,

            selectedStatus: null,
            update_id: null,
            statuses: ['DUE', 'FULLY-PAID', 'PARTIALLY-PAID'],
            statusOptions: [{
                    text: 'Due',
                    value: 'DUE'
                },
                {
                    text: 'Fully Paid',
                    value: 'FULLY-PAID'
                },
                {
                    text: 'Partially Paid',
                    value: 'PARTIALLY-PAID'
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState({
            items: state => state.invoice.invoice,
            count: state => state.invoice.invoice_count,
            loading: state => state.invoice.submitLoading,
            error: state => state.invoice.submitError,
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation,
            projects: state => state.project.project,
            clients: state => state.client.client_without_pg
        }),

        formattedProjects() {
            return [{
                    value: '',
                    text: 'Select a project',
                    disabled: true
                },
                ...this.projects.map(status => ({
                    value: status.id,
                    text: status.title
                }))
            ];
        },

        formattedStatus() {
            return [{
                    value: '',
                    text: 'Select a status',
                    disabled: true
                },
                ...this.statusOptions.map(status => ({
                    value: status.value,
                    text: status.text
                }))
            ];
        },

        formattedClients() {
            return [{
                    value: '',
                    text: 'Select a client',
                    disabled: true
                },
                ...this.clients.map(client => ({
                    value: client.user.id,
                    text: client.company_name
                }))
            ];
        },

        formattedEmployee() {
            return [{
                    value: '',
                    text: 'Select a Employee',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [{
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [{
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        }
    },

    watch: {
        selectedDepartment(newVal, oldVal) {
            this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
        },

        selectedDesignation(newVal, oldVal) {
            this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/finance/invoice/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("invoice/fetchInvoice", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.status,
                    client: this.client,
                    project: this.project,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/finance/invoice/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("invoice/fetchInvoice", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.status,
                    client: this.client,
                    project: this.project,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        },

        start_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");

                        this.$store.dispatch("invoice/fetchInvoice", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.status,
                            client: this.client,
                            project: this.project,
                            employee: this.selectedEmployee
                        }).then(() => {
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("invoice/fetchInvoice", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.status,
                            client: this.client,
                            project: this.project,
                            employee: this.selectedEmployee
                        }).then(() => {
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");

                        this.$store.dispatch("invoice/fetchInvoice", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.status,
                            client: this.client,
                            project: this.project,
                            employee: this.selectedEmployee
                        }).then(() => {
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("invoice/fetchInvoice", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            status: this.status,
                            client: this.client,
                            project: this.project,
                            employee: this.selectedEmployee
                        }).then(() => {
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        filter(newVal, oldVal) {
            if (!this.initialLoad) {

                this.$store.dispatch("invoice/fetchInvoice", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.status,
                    client: this.client,
                    project: this.project,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        status(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("invoice/fetchInvoice", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.status,
                    client: this.client,
                    project: this.project,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        client(newVal, oldVal) {
            if (!this.initialLoad) {

                this.$store.dispatch("invoice/fetchInvoice", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.status,
                    client: this.client,
                    project: this.project,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        project(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("invoice/fetchInvoice", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.status,
                    client: this.client,
                    project: this.project,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        selectedEmployee(newVal, oldVal) {
            if (!this.initialLoad) {

                this.$store.dispatch("invoice/fetchInvoice", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.status,
                    client: this.client,
                    project: this.project,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        }
    },

    mounted() {
        this.$store.dispatch("client/fetchClient_without_pg")
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("project/fetchProject")

        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {

                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitialEOD();
            } else {

                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitialEOD();
            }
        }
    },

    methods: {
        fetchInitialEOD() {
            this.$store.dispatch("invoice/fetchInvoice", {
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.status,
                    client: this.client,
                    project: this.project,
                    employee: this.selectedEmployee
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        },
        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        clearFilter() {
            this.filterShow = false,
                this.filter = null,
                this.start_date = null,
                this.end_date = null,
                this.status = '',
                this.client = '',
                this.project = '',
                this.selectedDepartment = '',
                this.selectedDesignation = '',
                this.selectedEmployee = ''
        },

        getStatus(status, id) {
            this.update_id = id
            this.selectedStatus = status
        },

        recordPayment(data) {
            this.record.invoice = data.item.id
            this.invoice_due = data.item.due_amount
        },

        resetModal() {
            this.record.paid_amount = ''
        },

        handleRecordOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleRecordSubmit()
        },

        handleRecordSubmit() {
            if (this.record.paid_amount > this.invoice_due) {
                Swal.fire({
                    title: "Error!",
                    text: 'Request amount is more than due amount!',
                    icon: "error"
                });
            } else {
                this.$store.dispatch("invoice/recordPayment", {
                    record: this.record,
                    currentPage: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.status,
                    client: this.client,
                    project: this.project,
                    employee: this.selectedEmployee
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.record.paid_amount = '',
                            this.record.invoice = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-record')
                })
            }
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteInvoice(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("invoice/deleteInvoice", {
                id: this.delete_value,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.status,
                client: this.client,
                project: this.project,
                employee: this.selectedEmployee
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("invoice/updateInvoiceStatus", {
                id: this.update_id,
                status_update: this.selectedStatus,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.status,
                client: this.client,
                project: this.project,
                employee: this.selectedEmployee
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.update_id = null
                    this.selectedStatus = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        }
    }
};
</script>
