import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'QUOTATION_UPDATE',
    smsDelete : 'QUOTATION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        quotation: [],
        quotation_count:null,
        quotation_detail: {},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_QUOTATION (state, {results, pg_count}) {
            state.quotation = results;
            state.quotation_count = pg_count;
        },

        SET_QUOTATION_DETAIL (state, project) {
            state.quotation_detail = project;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateQuotation ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try {

                payload.quotation.details=JSON.stringify(payload.quotation.details)
                console.log(payload.quotation.details)

                let data = new FormData()
                let payloadData = buildFormData(data, payload.quotation);

                let response = await axios.patch(V1API.edit_quotation + payload.id, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchQuotation')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteQuotation ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_quotation + payload.id, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchQuotation', {currentPage:payload.currentPage, perPage:payload.perPage, start_date:payload.start_date, end_date:payload.end_date, search_term:payload.search_term, vendor:payload.vendor, project:payload.project, employee:payload.employee});

            return response
        },

        async fetchQuotation ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.project) params.append('project', payload.project);
                if (payload.client) params.append('client', payload.client);
                if (payload.employee) params.append('employee', payload.employee);
               
                let url = V1API.get_quotation + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results, pg_count} = result.data
                commit('SET_QUOTATION', {results, pg_count});
                return result

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchQuotationDetail ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_quotation_details + payload, config);
                let response = result.data
                commit('SET_QUOTATION_DETAIL', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addQuotation ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{



                payload.details=JSON.stringify(payload.details)
                console.log(payload.details)


                let data2= new FormData()
                let payloadData = buildFormData(data2, payload);
                // let magic = encode(payloadData)

                let response = await axios.post(V1API.create_quotation, payloadData, config).then(result=>{
                    // let data = decode(result.data)
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchQuotation')
                return response

            }catch (e) {
                console.log(e)
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async addQuotationAttachment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };
            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.attachment);
        
                let response = await axios.post(V1API.quotation_add_attachment, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch("fetchQuotationDetail",payload.quotation_id)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteAttachmentFromQuotation ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.quotation_delete_attachment + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchQuotationDetail',payload.quotation_id)
            
            return response
        },
    },

    getters: {}
}
