<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Payments" title="Bill Payments" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 20px">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                        <feather type="filter"></feather>
                                        <h7>Filter</h7>
                                    </div>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="statusSelected || start_date || end_date || selectedDepartment ||selectedDesignation || requestBy || approvedBy">
                                        <feather type="x"></feather>
                                        <h7>Clear All</h7>
                                    </div>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment && selectedDesignation">
                                        <b-form-select v-model="requestBy" :options="formattedRequestedBy"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment && selectedDesignation">
                                        <b-form-select v-model="approvedBy" :options="formattedApprovedBy"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive datatable-vue" v-if="!submitLoading && !submitError">
                            <b-table show-empty stacked="md" :items="bill_payments_pg" :fields="tablefields" :per-page="perPage" style="margin-top: 22px;">
                                <template #cell(index)="data">
                                    {{ (currentPage - 1) * perPage + data.index + 1 }}
                                </template>

                                <template #cell(bill)="data">
                                    <span v-if="data.item.bill">
                                        <router-link :to="`/finance-and-accounts/finance/bill/${data.item.bill.id}`" class="link-style">
                                            {{ data.item.bill.bill_id}}
                                        </router-link>
                                    </span>
                                </template>

                                <template #cell(requested_by)="data">
                                    <span v-if="data.item.requested_by">
                                        <!-- <router-link :to="`/hr/organization/employee/details/${data.item.requested_by.id}`"> -->
                                        {{ data.item.requested_by.first_name}} {{ data.item.requested_by.last_name}}
                                        <!-- </router-link> -->
                                    </span>
                                </template>

                                <template #cell(approved_by)="data">
                                    <span v-if="data.item.approved_by">
                                        <!-- <router-link :to="`/hr/organization/employee/details/${data.item.approved_by.id}`"> -->
                                        {{ data.item.approved_by.first_name }} {{ data.item.approved_by.last_name }}
                                        <!-- </router-link> -->
                                    </span>
                                </template>

                                <!-- <template #cell(refer_to)="data">
                                    <span>
                                        <div v-if="data.item.refer_to !== null && data.item.refer_to.length > 0">
                                            <div v-for="user in data.item.refer_to" :key="user.id">
                                                <p>{{ user.first_name }}</p>
                                            </div>
                                        </div>
                                    </span>
                                </template> -->

                                <template #cell(status)="row">
                                    <span v-if="row.value == 'APPROVED'">
                                        <b-badge pill variant="success">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.item, row.item.id)" v-if="row.value == 'PENDING'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="primary" title="Update Status">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.item, row.item.id)" v-if="row.value == 'REJECTED'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="danger" title="Update Status">{{ row.value }}</b-badge>
                                    </span>
                                </template>

                                <template #cell(action)="data">
                                    <span>
                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRequest(data.item.id)" title="Delete"></feather>
                                    </span>
                                </template>
                            </b-table>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Status Update Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Payment Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Status Approved Modal -->
    <!-- <b-modal id="modal-center-approved" centered hide-header @ok="handlePayementSubmit">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to approve this payment?</div>
    </b-modal> -->

    <!-- Bill Request Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleRequestDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this payment?</div>
    </b-modal>

    <!-- Payment Approve -->
    <b-modal id="modal-prevent-status" ref="modal" size="xl" title="Add Transaction" hide-footer>
        <paymentApproveVue :amount="this.approved_status" :paymentType="'BILL'" :currentPage="currentPage" :perPage="perPage" :start_date="start_date" :end_date="end_date" :statusSelected="statusSelected" :filter="filter" :requestBy="requestBy" :approvedBy="approvedBy" />
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import {
    myHost
} from '../../../../_helpers/host';
import paymentApproveVue from './payment-approve.vue';
import moment from 'moment';

export default {
    components: {
        Datepicker,
        Multiselect,
        paymentApproveVue
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'bill',
                    label: 'Bill',
                    sortable: true
                },
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true
                },
                {
                    key: 'paid_amount',
                    label: 'Paid Amount',
                    sortable: true
                },
                {
                    key: 'requested_by',
                    label: 'Requested By',
                    sortable: true
                },
                {
                    key: 'approved_by',
                    label: 'Approved By',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            approved_status: {
                update_id: '',
                amount: null,
            },

            statuses: ['PENDING', 'APPROVED', 'REJECTED'],
            selectedStatus: '',

            filter: null,
            statuses_list: [{
                    id: 1,
                    name: 'Pending',
                    value: 'PENDING'
                },
                {
                    id: 2,
                    name: 'Approved',
                    value: 'APPROVED'
                },
                {
                    id: 3,
                    name: 'Rejected',
                    value: 'REJECTED',
                }
            ],
            initialLoad: true,
            isModifyingDate: false,
            filterShow: false,
            start_date: null,
            end_date: null,
            statusSelected: '',
            selectedDepartment: '',
            selectedDesignation: '',
            requestBy: '',
            approvedBy: '',
            temp_request_id: '',

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState('payments', ["bill_payments_pg", "bill_payments_count", "submitError", "submitSuccess", "submitLoading"]),

        ...mapState({
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation
        }),

        formattedOptions() {
            return [{
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses_list.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },

        formattedRequestedBy() {
            return [{
                    value: '',
                    text: 'Select Requested By',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedApprovedBy() {
            return [{
                    value: '',
                    text: 'Select Approved By',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [{
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [{
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        }
    },

    watch: {
        selectedDepartment(newVal, oldVal) {
            this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
        },

        selectedDesignation(newVal, oldVal) {
            this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
        },

        filter(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy,
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.bill_payments_count;
                    this.initialLoad = false;

                });
            }

        },

        statusSelected(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy,
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.bill_payments_count;
                    this.initialLoad = false;
                });
            }

        },

        requestBy(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy,
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.bill_payments_count;
                    this.initialLoad = false;
                });
            }

        },

        approvedBy(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy,
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.bill_payments_count;
                    this.initialLoad = false;
                });
            }

        },

        start_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter,
                            raised_by: this.requestBy,
                            approved_by: this.approvedBy,
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.bill_payments_count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter,
                            raised_by: this.requestBy,
                            approved_by: this.approvedBy,
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.bill_payments_count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter,
                            raised_by: this.requestBy,
                            approved_by: this.approvedBy,
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.bill_payments_count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter,
                            raised_by: this.requestBy,
                            approved_by: this.approvedBy,
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.bill_payments_count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/payments/bill/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy,
                }).then(() => {
                    this.totalRows = this.bill_payments_count;
                    this.initialLoad = false;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/payments/bill/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy,
                }).then(() => {
                    this.totalRows = this.bill_payments_count;
                    this.initialLoad = false;
                });
            }
        }
    },

    mounted() {
        this.$store.dispatch("department/fetchDepartments");

        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {
               
                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitial();
            } else {
                
                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitial();
            }
        }
    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("payments/fetchBillPaymentsWithPG", {
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy,
            }).then(() => {
                this.totalRows = this.bill_payments_count;
                this.initialLoad = false;
            });
        },
        clearFilter() {
            this.filterShow = false,
                this.filter = null,
                this.statusSelected = '',
                this.start_date = null,
                this.end_date = null,
                this.selectedDepartment = '',
                this.selectedDesignation = '',
                this.requestBy = '',
                this.approvedBy = ''
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        deleteRequest(id) {
            this.temp_request_id = id
        },

        handleRequestDeleteOk() {
            this.$store.dispatch("payments/deleteBillPayments", {
                id: this.temp_request_id,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                raised_by: this.requestBy,
                approved_by: this.approvedBy
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.temp_request_id = ''
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center')
            })
        },

        getStatus(status, id) {
            this.approved_status.amount = status.paid_amount
            this.approved_status.update_id = id
            this.selectedStatus = status.status
        },

        resetModal() {
            this.selectedStatus = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            if (this.selectedStatus === 'APPROVED') {
                this.$nextTick(() => {
                    this.$bvModal.show('modal-prevent-status')
                })
            } else {
                this.$store.dispatch("payments/updateBillPaymentStatus", {
                    id: this.approved_status.update_id,
                    status_for_change: this.selectedStatus,
                    currentPage: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.update_id = null
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            }
        },

        // handlePayementSubmit() {
        //     this.$store.dispatch("payments/updateBillPaymentStatus", {
        //         id: this.update_id,
        //         status: 'APPROVED'
        //     }).then(response => {
        //         if (response.code == 200) {
        //             Swal.fire({
        //                 title: "Success!",
        //                 text: response.response,
        //                 icon: "success"
        //             });
        //             this.update_id = null
        //         } else {
        //             Swal.fire({
        //                 title: "Error!",
        //                 text: response.response,
        //                 icon: "error"
        //             });
        //         }
        //     })

        //     this.$nextTick(() => {
        //         this.$bvModal.hide('modal-prevent-closing')
        //     })
        // }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
