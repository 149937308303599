<template>
<div>
    <Breadcrumbs main="Procurement" title="RFP Responses" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h5>Response</h5>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div style="display: flex; flex-direction: column;">
                                        <b-row>
                                            <b-col xl="6">
                                                <b-input-group class="datatable-btn">
                                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                                    </b-input-group-append>
                                                </b-input-group>

                                            </b-col>

                                            <b-col xl="6">
                                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <div class="row">

                                            <div class="col-md-8">
                                                <!-- <div v-for="(item, index) in filteredResponses" :key="index">

                                                    <div class="accordion" role="tablist">
                                                        <b-card no-body class="mb-1">
                                                            <b-card-header header-tag="header" class="p-1" role="tab">
                                                                <b-button block v-b-toggle="'accordion-' + index" variant="primary">
                                                                    <div style="display: flex; flex-direction: column; gap: 5px;">

                                                                        <h7>{{ item.submitted_form.form_name }}</h7>
                                                                        <h7>Submitted By: {{ item.submitted_by.username }}</h7>

                                                                    </div>
                                                                </b-button>
                                                            </b-card-header>
                                                            <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
                                                                <b-card-body>
                                                                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                        <div>
                                                                            <div v-for="(info, index) in item.form_data" :key="index">
                                                                                <div style="display: flex; gap: 16px; margin-bottom: 5px;">
                                                                                    <span>{{ info.data_label }}:</span>
                                                                                    <h6 v-if="info.data_type == 'filefield'">
                                                                                        <a :href="host + info.data_value" target="_blank" class="text-info">{{ host + info.data_value }}</a>
                                                                                    </h6>
                                                                                    <h6 v-else>{{ info.data_value }}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div style="display: flex; flex-direction: row; gap: 10px;">
                                                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(item.id)" title="Delete"></feather>
                                                                            <feather type="tag" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-add @click="add(item)" title="Add label"></feather>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>

                                                    </div>

                                                </div> -->

                                                <div class="table-responsive datatable-vue">
                                                    <b-table show-empty stacked="md" :items="responses" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                        <template #cell(index)="data">
                                                            {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                        </template>

                                                        <template #cell(submitted_by)="data">
                                                            <router-link :to="`/procurement/rfp/submission/${data.item.id}`">
                                                                <span>{{ data.item.submitted_by.username }}</span>
                                                            </router-link>
                                                            
                                                        </template>

                                                        <template #cell(label)="data">

                                                            <div v-if="data.item.label !== null && data.item.label.length > 0" @click="add(data.item)" v-b-modal.modal-center-add>
                                                                <div v-for="label in data.item.label" :key="label.id">

                                                                    <b-badge pill variant="success">{{ label.name }}</b-badge>
                                                                </div>
                                                            </div>
                                                            <div v-else v-b-modal.modal-center-add @click="add(data.item)">
                                                                <b-badge pill variant="secondary">Add Label</b-badge>
                                                            </div>

                                                        </template>

                                                        <template #cell(action)="data">
                                                            <div style="display: flex; flex-direction: row; gap: 10px;">
                                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.id)" title="Delete"></feather>
                                                                <feather type="tag" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-add @click="add(data.item)" title="Add label"></feather>
                                                            </div>
                                                        </template>

                                                    </b-table>
                                                </div>

                                                <b-col class="my-1 p-0">
                                                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                </b-col>

                                            </div>

                                            <div class="col-md-4">
                                                <div class="label-small">
                                                    <h6>Labels:</h6>

                                                    <div style="display: flex; gap: 10px;">
                                                        <input class="form-control" type="text" placeholder="Enter label" v-model="label">
                                                        <button class="btn btn-success" @click="addLabel()">Add</button>
                                                    </div>
                                                    <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); margin-top: 8px; gap: 8px;">
                                                        <div v-for="label in labels" :key="label.index" style="width: 100%;">
                                                            <span style="font-size: medium; width: 100%;">
                                                                <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="dark">
                                                                    <span style="width: 80%; padding: 4px; text-align: left;" @click="()=>{
                                                              filter=label.name
                                                             }"> {{ label.name }} </span>
                                                                    <span @click="deleteLabel(label.id)" style="width: 20%; padding: 4px;">🗙</span>
                                                                </b-badge>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this response?</div>
    </b-modal>

    <b-modal id="modal-center-add" centered title="Add Label" @ok="handleLabelOK">
        <div class="row">
            <div class="col-sm-12">

                <h6 v-if="addedLabels.length>0">Already Added</h6>
                <div class="row">
                    <div class="text-center col-sm-6 w-full" v-for="label in addedLabels" :key="label.id" v-b-tooltip.hover @click="deleteAssignedLabel(label.id)" title="Delete">
                        <div class="w-100 mb-2 badge-style">
                            <h7>{{ label.name}}</h7>
                            <b-icon icon="x" style="color: white;"></b-icon>
                        </div>
                    </div>
                </div>

                <div class="form-group mt-2">
                    <label>Label</label>
                    <select class="form-control digits" v-model="label_id">
                        <option v-for="(type, index) in labels" :key="index" :value="type.id">{{ type.name }}</option>
                    </select>
                </div>
            </div>
        </div>
    </b-modal>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    myHost
} from "../../../_helpers/host";
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data() {
        return {
            host: myHost.host,
            delete_value: '',

            response_id: '',
            label_id: null,
            addedLabels: [],
            selectedLabel: null,

            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'submitted_by',
                    label: 'Submitted By',
                    sortable: true
                },
                {
                    key: 'label',
                    label: 'Label',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                },

            ],
            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        }
    },

    computed: {
        ...mapState({
            responses: state => state.rfp.responses,
            labels: state => state.rfp.rfp_label_list,
        }),
        // filteredResponses() {
        //     if (!this.selectedLabel) {
        //         return this.responses;
        //     }
        //     return this.responses.filter(response =>
        //         response.label.some(label => label.name === this.selectedLabel)
        //     );
        // }
        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        this.$store.dispatch("rfp/fetchFormResponses", this.$route.params.id).then(() => {
            this.items = this.responses
            this.totalRows = this.items.length;
        });
        this.$store.dispatch("rfp/fetchRFPLabels")

    },

    watch: {
        responses(newValue, oldValue) {}
    },
    methods: {
        filter(label) {
            this.selectedLabel = label
        },
        deleteItem(id) {
            this.delete_value = id
        },
        add(data) {
            this.response_id = data.id
            this.addedLabels = data.label
        },

        handleDeleteOk() {
            this.$store.dispatch("rfp/deleterfpFormResonse", {
                response_id: this.delete_value,
                rfp_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        addLabel() {
            if (this.label !== '') {
                this.$store.dispatch("rfp/addRFPLabel", this.label).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.label = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
        },
        deleteLabel(id) {
            this.$store.dispatch("rfp/deleteRFPLabel", id).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
        },

        handleLabelOK() {
            this.$store.dispatch("rfp/addLabelToResponse", {
                response_id: this.response_id,
                label_id: this.label_id,
                form_response: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.label_id = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

        },

        deleteAssignedLabel(id) {
            this.$store.dispatch("rfp/deleteLabelFromResponse", {
                response_id: this.response_id,
                label_id: id,
                form_response: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.label_id = null
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center-add')
            })

        },

    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}
</style>
