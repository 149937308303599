<template>
    <div>
        <Breadcrumbs main="HR" submain="Leave Management" title="Leave Histories" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
    
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
    
                            <div class="table-responsive datatable-vue" v-if="!submitLoading && !submitError">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
    
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>
    
                                
                                    <template #cell(leave_type)="data">
                                        <div v-if="data.item.leave_type">
                                            <p>{{ data.item.leave_type.name }}</p>
                                        </div>
                                    </template>

                                    <template #cell(leave_days_taken)="data">
                                        <div v-if="data.item.leave_days_taken">
                                            <p>{{ data.item.leave_days_taken }} Days</p>
                                        </div>
                                    </template>
    
                                  
    
                              
    
    
    
                                    <template #cell(action)="data">
                                        <span>
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRequest(data.item.id)" title="Delete"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                            <div v-else>
                                <div class="loader-box">
                                    <div class="loader-10"></div>
                                </div>
                            </div>
    
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
    
        <!-- Status Approved Modal -->
        <!-- <b-modal id="modal-center-approved" centered hide-header @ok="handlePayementSubmit">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to approve this payment?</div>
        </b-modal> -->
    
        <!-- Leave Request Delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleRequestDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this leave history?</div>
        </b-modal>
    
     
    
    </div>
    </template>
    
    <script>
    import {
        mapState
    } from "vuex";
    import Swal from 'sweetalert2/dist/sweetalert2.js'
    import 'sweetalert2/src/sweetalert2.scss'
    import Multiselect from 'vue-multiselect';
    import Datepicker from 'vuejs-datepicker';
    import {
        myHost
    } from '../../../../_helpers/host';

    
    export default {
        components: {
            Datepicker,
            Multiselect,
           
        },
    
        data() {
            return {
                host: myHost.host,
                items: [],
                tablefields: [{
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'leave_type',
                        label: 'Leave Type',
                        sortable: true
                    },
                    {
                        key: 'start_date',
                        label: 'Start Date',
                        sortable: true
                    },
                    {
                        key: 'end_date',
                        label: 'End Date',
                        sortable: true
                    },
                    {
                        key: 'leave_days_taken',
                        label: 'Leave Taken',
                        sortable: true
                    },
                    
                    {
                        key: 'action',
                        label: 'Action',
                        sortable: false
                    }
                ],
    
                statuses: ['PENDING', 'APPROVED', 'REJECTED'],
                selectedStatus: '',
    
                approved_status: {
                    update_id: '',
                    amount: null,
                },
    
                filter: null,
                temp_request_id: '',
    
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 100]
            };
        },
    
        computed: {
            ...mapState('leave', ["leave_histories","submitError","submitSuccess","submitLoading"]),
    
            sortOptions() {
                // Create an options list from our fields
                return this.tablefields
                    .filter(f => f.sortable)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.key
                        };
                    });
            }
        },
    
        created() {
            this.$store.dispatch('leave/fetchLeaveHistory',this.$route.params.id).then(() => {
                this.items = this.leave_histories
                this.totalRows = this.items.length;
            })
        },
    
        watch: {
            leave_histories(oldValue, newValue) {
                this.items = this.leave_histories
            }
        },
    
        mounted() {
            this.totalRows = this.items.length;
        },
    
        methods: {
            deleteRequest(id) {
                this.temp_request_id = id
            },
    
            handleRequestDeleteOk() {
                this.$store.dispatch("leave/deleteLeaveHistory", {delete:this.temp_request_id, profile:this.$route.params.id}).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.temp_request_id = ''
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-center')
                })
            },
    
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
    
            getStatus(status, id) {
                this.approved_status.amount = status.paid_amount
                this.approved_status.update_id = id
                this.selectedStatus = status.status
            },
    
            resetModal() {
                this.selectedStatus = ''
            },
    
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
    
    
        }
    };
    </script>
    
    <style scoped>
    .badge-style {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        background-color: #2c323f;
        padding: 0.375rem 1.75rem;
        color: white;
    
    }
    
    .badge-style:hover {
        background-color: #ff595d;
    }
    
    .chatbox {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px 10px;
    }
    
    .chat {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }
    
    .avatar img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        border: solid 2px white;
    }
    
    .message {
        flex-grow: 1;
    }
    
    .msg {
        background: #fff0f0;
        width: 100%;
        /* Ensures message takes full width */
        padding: 10px;
        border-radius: 10px;
        position: relative;
        font-size: 13px;
        word-break: break-all;
    }
    
    .task-board-comments {
        height: 450px;
        overflow-y: auto;
    }
    
    /* height */
    ::-webkit-scrollbar {
        height: 6px;
        width: 5px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    </style>
    