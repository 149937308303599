<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Budget" title="Request For Budget" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                              <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <input class="form-control" type="text" placeholder="Enter title" style="height: 42px;" v-model="budget.title">
                                    </div>
                                </div>
                              

                                <div class="col-sm-4" v-if="this.$route.params.project !== 'new'">
                                    <div class="form-group">
                                        <label>Project</label>
                                        <select class="form-control digits" v-model="budget.project" style="height: 42px;" disabled>
                                            <option v-for="(project, index) in project" :key="index" :value="project.id" >{{project.title}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-4" v-else>
                                    <div class="form-group">
                                        <label>Project</label>
                                        <select class="form-control digits" v-model="budget.project" style="height: 42px;">
                                            <option v-for="(project, index) in project" :key="index" :value="project.id">{{project.title}}
                                            </option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Budget Category</label>
                                        <select class="form-control digits" v-model="budget.budget_category" style="height: 42px;">
                                            <option v-for="(budget_category, index) in estimateBudgets" :key="index" :value="budget_category.id">
                                                {{ budget_category.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Requested Amount</label>
                                        <input class="form-control" type="number" placeholder="Enter requested amount" v-model="budget.budget_amount" style="height: 42px;">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Refer To</label>
                                        <multiselect v-model="budget.refering_to" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="get_user_list.length" track-by="id" :options="get_user_list" :multiple="true" :taggable="true"></multiselect>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Budget Details</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter budget detail..." rows="4" v-model="budget.detail_note"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                        <!-- <router-link to="/budget/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
export default {
    components: {
        Multiselect
    },

    data() {
        return {
            budget: {
                title:'',
                project: '',
                budget_category: '',
                budget_amount: '',
                refering_to: [],
                detail_note: '',
            },
            refer_list_id: [],

            employees: [{
                    code: 1,
                    name: 'Alabama'
                },
                {
                    code: 2,
                    name: 'Wyoming'
                },
                {
                    code: 3,
                    name: 'Coming'
                },
                {
                    code: 4,
                    name: 'Hanry Die'
                },
                {
                    code: 5,
                    name: 'John Doe'
                }
            ],
            categories: ['Digital Marketing', 'Front-end', 'Back-end'],

        };
    },
    computed: {
        ...mapState('budget', ["estimateBudgets", "budgetError", "budgetLoading", "submitError", "submitSuccess", "submitLoading"]),
        ...mapState('project', ["project", "projectError", "projectLoading"]),
        ...mapState('asset', ['get_user_list']),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },
    created() {
        this.$store.dispatch('budget/fetchBudget').then(result => {

        })
        this.$store.dispatch('project/fetchProject').then(result => {

        })
        this.$store.dispatch('asset/fetchUserList').then(result => {

        })
    },

    mounted() {
        if (this.$route.params.project == 'new') {
            this.budget.project = ''
        } else {
            this.budget.project = this.$route.params.project
        }
    },
    watch: {
        estimateBudgets(oldValue, newValue) {

        }

        // this.options = this.accountCategories

    },

    methods: {
        onSubmit(event) {
            event.preventDefault()

            this.budget.refering_to.map(item => {
                console.log(item)
                this.refer_list_id.push(

                    item.id)
            })

            this.budget.refering_to = this.refer_list_id

            this.$store.dispatch("budget/requestBudget", this.budget).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = [],
                        this.member = [],
                        setTimeout(() => {
                            this.$router.push('/finance-and-accounts/budget/budget-request/list')
                        }, 3000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            })
        }

    }
};
</script>
