<template>
<div>
    <form>
        <div class="row">
            <div class="col-sm">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Amount</label>
                            <b-form-input id="name-input" type="number" v-model="transaction.amount" placeholder="Amount" readonly></b-form-input>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <h6>Debit Account</h6>

                        <div class="row" v-for="(debit, index) in transaction.debit_instances" :key="index">
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Category</label>
                                    <b-form-select v-model="debit.category" @change="updateGroup(debit.category, true)">
                                        <b-form-select-option v-for="option in accountCategories" :value="option.id">{{ option.title }}</b-form-select-option>
                                    </b-form-select>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Group</label>
                                    <input class='form-control' type="text" list="debit_groups" v-model="debit.group" @change="updateLedgers(debit.group, true)" />
                                    <!-- <input class='form-contro' type="text" list="debit_ledgers" v-model="debit.ledger_name"/> -->
                                    <datalist id="debit_groups">
                                        <option v-for="option in debit_groups" :value="option.title">{{ option.title }}</option>
                                    </datalist>
                                    <!-- <b-form-select v-model="debit.group" @change="updateLedgers(debit.group, true)"> -->
                                    <!-- <b-form-select-option v-for="option in debit_groups" :value="option.id">{{ option.title }}</b-form-select-option> -->
                                    <!-- </b-form-select> -->
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Ledger</label>
                                    <input class='form-control' type="text" list="debit_ledgers" v-model="debit.ledger" />
                                    <!-- <input class='form-contro' type="text" list="debit_ledgers" v-model="debit.ledger_name"/> -->
                                    <datalist id="debit_ledgers">
                                        <option v-for="option in debit_ledgers" :value="option.title">{{ option.title }}</option>
                                    </datalist>
                                    <!-- <b-form-select v-model="debit.ledger_id"> -->
                                    <!-- <b-form-select-option v-for="option in debit_ledgers" name="hello" :value="option.id">{{ option.title }}</b-form-select-option> -->
                                    <!-- </b-form-select> -->
                                </div>
                            </div>

                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label>Amount</label>
                                    <b-form-input id="name-input" type="number" v-model="debit.amount" placeholder="Enter debit amount"></b-form-input>
                                </div>
                            </div>

                            <div class="col-sm-1" style="margin-top: 35px;">
                                <!-- Add Svg Icon -->
                                <svg @click="addField(debit.id, transaction.debit_instances, true)" width="24" height="24" style="cursor: pointer;">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                </svg>

                                <!-- Remove Svg Icon -->
                                <svg v-if="transaction.debit_instances.length > 1 && !debit.id" @click="removeField(index, transaction.debit_instances)" width="24" height="24" style="cursor: pointer;">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <h6>Credit Account</h6>

                        <div class="row" v-for="(credit, index) in transaction.credit_instances" :key="index">
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Category</label>
                                    <b-form-select v-model="credit.category" @change="updateGroup(credit.category)">
                                        <b-form-select-option v-for="option in accountCategories" :value="option.id">{{ option.title }}</b-form-select-option>
                                    </b-form-select>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Group</label>
                                    <input class='form-control' type="text" list="credit_groups" v-model="credit.group" @change="updateLedgers(credit.group, false)" />
                                    <!-- <input class='form-contro' type="text" list="debit_ledgers" v-model="debit.ledger_name"/> -->
                                    <datalist id="credit_groups">
                                        <option v-for="option in credit_groups" :value="option.title">{{ option.title }}</option>
                                    </datalist>
                                    <!-- <b-form-select v-model="credit.group" @change="updateLedgers(credit.group, false)"> -->
                                    <!-- <b-form-select-option v-for="option in credit_groups" :value="option.id">{{ option.title }}</b-form-select-option> -->
                                    <!-- </b-form-select> -->
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Ledger</label>
                                    <input class='form-control' type="text" list="credit_ledgers" v-model="credit.ledger" />
                                    <datalist id="credit_ledgers">
                                        <option v-for="option in credit_ledgers" :value="option.title">{{ option.title }}</option>
                                    </datalist>
                                    <!-- <b-form-select v-model="credit.ledger_id"> -->
                                    <!-- <b-form-select-option v-for="option in credit_ledgers" :value="option.id">{{ option.title }}</b-form-select-option> -->
                                    <!-- </b-form-select> -->
                                </div>
                            </div>

                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label>Amount</label>
                                    <b-form-input id="name-input" type="number" v-model="credit.amount" placeholder="Enter credit amount"></b-form-input>
                                </div>
                            </div>

                            <div class="col-sm-1" style="margin-top: 35px;">
                                <!-- Add Svg Icon -->
                                <svg @click="addField(credit.id, transaction.credit_instances, false)" width="24" height="24" style="cursor: pointer;">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                </svg>

                                <!-- Remove Svg Icon -->
                                <svg v-if="transaction.credit_instances.length > 1 && !credit.id" @click="removeField(index, transaction.credit_instances)" width="24" height="24" style="cursor: pointer;">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Note</label>
                            <b-form-textarea id="textarea-auto-height" v-model="transaction.note" rows="3" max-rows="8" placeholder="Enter note..."></b-form-textarea>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row justify-content-end" style="gap: 10px; ">
                    <div>
                        <b-button variant="primary" @click="skipTransaction()">Skip</b-button>
                    </div>
                    <div>
                        <b-button variant="primary" @click="handlePaymentSubmit">Submit</b-button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!-- Status Approved Modal -->
    <b-modal id="modal-center-approved" centered hide-header @ok="handlePayementSubmit">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to approve this payment?</div>
    </b-modal>
</div>
<!-- Container-fluid Ends-->
</template>

<script>
import { mapState } from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    props: {
        amount: {
            type: [Object],
            required: true
        },
        paymentType: {
            type: String,
            required: true
        },
        perPage: {
            type: Number,
            default: 25
        },
        currentPage: {
            type: Number,
            default: 1
        },
        start_date: {
            type: String,
            default: null
        },
        end_date: {
            type: String,
            default: null
        },
        statusSelected: {
            type: String,
            default: ''
        },
        filter: {
            type: String,
            default: ''
        },
        requestBy: {
            type: String,
            default: ''
        },
        approvedBy: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            items: [],

            credit_groups: [],
            credit_ledgers: [],
            debit_groups: [],
            debit_ledgers: [],

            transaction: {
                date: '',
                debit_instances: [{
                    category: null,
                    group: null,
                    ledger: null,
                    amount: null,
                    is_debit: true
                }],
                credit_instances: [{
                    category: null,
                    group: null,
                    ledger: null,
                    amount: null,
                    is_debit: false
                }],
                amount: 0,
                note: ''
            },
            debitamount: 0,
            creditamount: 0
        };
    },

    computed: {
        ...mapState('account', ["accountTransactions", "accountTransactionsLoading", "accountTransactionsError", "accountCategories", "accountGroups", "accountLedgers", "accountLedgerLoading", "accountLedgerError", "accountGroupLoading", "accountGroupError", "accountCategoryLoading", "accountCategoryError", "submitError", "submitSuccess", "submitLoading"]),

        totalDebitAmount() {
            return this.transaction.debit_instances.reduce((total, debit) => {
                return total + (parseFloat(debit.amount) || 0);
            }, 0);
        },

        totalCreditAmount() {
            return this.transaction.credit_instances.reduce((total, credit) => {
                return total + (parseFloat(credit.amount) || 0);
            }, 0);
        }
    },

    created() {
        this.$store.dispatch('account/fetchAccountTransactions').then(result => {
            this.items = this.accountTransactions
        })
        this.$store.dispatch('account/fetchAccountCategory')
        this.$store.dispatch('account/fetchAccountGroup').then(result => {
            this.debit_groups = this.accountGroups
            this.credit_groups = this.accountGroups
        })
        this.$store.dispatch('account/fetchAccountLedger').then(result => {
            this.debit_ledgers = this.accountLedgers
            this.credit_ledgers = this.accountLedgers
        })
    },

    watch: {
        totalCreditAmount(newValue) {
            this.creditamount = newValue;
            this.updateTransactionAmount();
        },

        totalDebitAmount(newValue) {
            this.debitamount = newValue;
            this.updateTransactionAmount();
        },

        accountCategories(oldValue, newValue) {
            // this.options = this.accountCategories
        },

        accountTransactions(oldValue, newValue) {
            this.items = this.accountTransactions
        },

        accountLedgers(oldValue, newValue) {
            // this.options = this.accountCategories
        },

        accountGroups(oldValue, newValue) {
            // this.items = this.accountGroups
        }
    },

    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },

    methods: {
        updateTransactionAmount() {
            if (this.debitamount === this.creditamount) {
                this.transaction.amount = this.debitamount;
            } else {
                this.transaction.amount = 0;
            }
        },

        updateGroup(id, is_debit) {
            this.$store.dispatch('account/fetchAccountGroupByCategory', id).then(result => {
                if (is_debit) {
                    this.debit_groups = this.accountGroups
                } else {
                    this.credit_groups = this.accountGroups
                }
            })
        },

        updateLedgers(id, is_debit) {
            this.$store.dispatch('account/fetchAccountLedgerByGroup', id).then(result => {
                if (is_debit) {
                    this.debit_ledgers = this.accountLedgers
                } else {
                    this.credit_ledgers = this.accountLedgers
                }
            })
        },

        addField(value, fieldType, is_debit) {
            fieldType.push({
                is_debit: is_debit
            });
        },

        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        skipTransaction() {
            this.$nextTick(() => {
                this.$bvModal.show('modal-center-approved')
            })
        },

        // handlePaymentOk(bvModalEvent) {
        //     bvModalEvent.preventDefault()
        //     this.handlePaymentSubmit()
        // },

        handlePaymentSubmit() {
            if (this.debitamount != 0 && this.creditamount != 0) {
                if (this.debitamount === this.creditamount) {
                    this.$store.dispatch("account/recordTransaction", this.transaction).then(response => {
                        if (response.code == 200) {
                            Swal.fire({
                                title: "Success!",
                                text: response.response,
                                icon: "success"
                            });

                            this.transaction = {
                                date: '',
                                debit_instances: [{
                                    category: null,
                                    group: null,
                                    ledger_id: null,
                                    amount: null,
                                    is_debit: true
                                }],
                                credit_instances: [{
                                    category: null,
                                    group: null,
                                    ledger_id: null,
                                    amount: null,
                                    is_debit: false
                                }],
                                amount: 0,
                                note: ''
                            }

                            this.$nextTick(() => {
                                this.$bvModal.show('modal-center-approved')
                            })
                        } else {
                            Swal.fire({
                                title: "Error!",
                                text: response.response,
                                icon: "error"
                            });
                        }
                    })
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: 'Debit amount must be equal to credit amount.',
                        icon: "error"
                    });
                }
            } else {
                Swal.fire({
                    title: "Error!",
                    text: 'Please enter valid amount',
                    icon: "error"
                });
            }
        },

        handlePayementSubmit() {
            if (this.paymentType === 'INVOICE') {
                this.$store.dispatch("payments/updateInvoicePaymentStatus", {
                    id: this.amount.update_id,
                    status_for_change: 'APPROVED',
                    detail_id: this.amount.detail_id,
                    currentPage: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }

            if (this.paymentType === 'BILL') {
                this.$store.dispatch("payments/updateBillPaymentStatus", {
                    id: this.amount.update_id,
                    status_for_change: 'APPROVED',
                    detail_id: this.amount.detail_id,
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }

            if (this.paymentType === 'REQUISITION') {
                this.$store.dispatch("payments/updateRequisitionPaymentStatus", {
                    id: this.amount.update_id,
                    status_for_change: 'APPROVED',
                    detail_id: this.amount.detail_id,
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.requestBy,
                    approved_by: this.approvedBy
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-status')
            })
        }
    }
};
</script>
