<template>
<div>
    <Breadcrumbs main="Finance" title="Bill" />
    <div>
        <div class="col-md-8 d-flex justify-content-between my-4">
            <div style="display: flex; gap: 10px;">
                <b-button variant="primary" @click="editBill(billData.id)">Edit</b-button>
                <b-button variant="primary" @click="showPDF">Preview</b-button>
                <b-button variant="primary" @click="generatePDF">Download</b-button>
            </div>
            <div>
                <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(billData)" title="Reply"></feather>
            </div>
        </div>
        <div class="d-flex flex-row mb-4" style="gap: 10px;">

            <div class="d-flex flex-row">
                <h6 class=" align-content-center mr-2">Letter Head</h6>
                <div class="">
                    <div class="media-body text-right icon-state switch-outline">
                        <label class="switch">
                            <input type="checkbox" :checked="showLetterHead" @click="toggleLetterHead"><span class="switch-state bg-primary"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row">
                <h6 class=" align-content-center mr-2">Logo</h6>
                <div class="">
                    <div class="media-body text-right icon-state switch-outline">
                        <label class="switch">
                            <input type="checkbox" :checked="showLogo" @click="toggleLogo"><span class="switch-state bg-primary"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row">
                <h6 class=" align-content-center mr-2">Signature</h6>
                <div class="">
                    <div class="media-body text-right icon-state switch-outline">
                        <label class="switch">
                            <input type="checkbox" :checked="showSignature" @click="toggleSignature"><span class="switch-state bg-primary"></span>
                        </label>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-xl-8">
                <div v-if="!loading && !error">
                    <div :id="showPage ? '' : 'print'" ref="printDiv">
                        <div class="content-container">
                            <div class="invoice-header mt-5">
                                <!-- Company info -->
                                <div style="font-size: small; display: flex; flex-direction: column; gap: 12px;">

                                    <div>
                                        <h6>Bill from:</h6>
                                        <h5>{{ setting.title }}</h5>
                                        <div style="display: flex; flex-direction: column; gap: 2px;">
                                            <div style="display: flex; align-items: center; font-weight: 500;">
                                                <span>
                                                    {{ setting.email }}
                                                </span>
                                            </div>

                                            <div style="display: flex; align-items: center; font-weight: 500;">
                                                <span>
                                                    {{ setting.account_details.website }}
                                                </span>
                                            </div>

                                            <div style="display: flex; align-items: center; font-weight: 500;">
                                                <span>
                                                    {{ setting.phone }}
                                                </span>
                                            </div>

                                            <div style="display: flex; align-items: center; font-weight: 500;">
                                                <span>
                                                    {{ setting.address }}
                                                </span>
                                            </div>

                                            <div v-if="setting.account_details.bin" style="display: flex; flex-direction: row; gap: 4px;">
                                                <span style="font-weight: bold;">BIN:</span>
                                                <span style="font-weight: 400;">{{ setting.account_details.bin }}</span>
                                            </div>

                                            <div v-if="setting.account_details.tin" style="display: flex; flex-direction: row; gap: 4px;">
                                                <span style="font-weight: bold;">TIN:</span>
                                                <span style="font-weight: 400;">{{ setting.account_details.tin }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Quotation Info -->
                                <div class="invoice-info" style="font-size: small;">
                                    <h5>
                                        Bill #{{ billData.bill_id }}
                                    </h5>
                                    <p style="font-weight: 500;">
                                        Bill Date:
                                        {{ parseDate(billData.bill_date) }}
                                    </p>
                                    <p style="font-weight: 500; margin-top: -12px;">
                                        Due date: {{ parseDate(billData.due_date) }}
                                    </p>
                                </div>
                            </div>

                            <div style="width: 100%; display: flex; justify-content: space-between;">
                                <!-- Client Info -->
                                <div class="client-info" style="font-size: small;">
                                    <h6>Bill to:</h6>
                                    <h5 v-if="billData.created_by">
                                        {{ billData.created_by.username }}
                                    </h5>
                                    <a :href="`mailto:${billData.created_by.email}`">
                                        {{ billData.created_by.email }}
                                    </a>
                                    <p style="width: 75%; max-width: 65ch;">
                                        {{ billData.billing_address }}
                                    </p>
                                </div>

                                <!-- Payment Info -->
                                <div class="invoice-info" style="font-size: small;">
                                    <h6>Payment Info:</h6>
                                    <div style="display: flex; gap: 20px;">
                                        <span>Total Due:</span>
                                        <h6 style="color: brown;">
                                            <span>
                                                {{ billData.subtotal }}
                                            </span>
                                            {{ billData.currency }}
                                        </h6>
                                    </div>
                                    <div style="display: flex; gap: 20px;">
                                        <span>Billing Address:</span>
                                        <p>
                                            {{ billData.billing_address }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="client-section">

                                <div style="display: flex; align-items: flex-start;">
                                    <div style="display: flex; flex-direction: row; gap: 4px;" v-if="billData.project.title">
                                        <span class="font-weight-bold" style="font-size: 15px;">Project: </span>
                                        <span style="font-size: 15px;">{{ billData.project.title }}</span>
                                    </div>
                                    <div style="display: flex; flex-direction: row; gap: 4px;" v-else>
                                        <span class="font-weight-bold" style="font-size: 15px;">Project: </span>
                                        <span style="font-size: 15px;">N/A</span>
                                    </div>

                                </div>
                            </div>

                            <!-- Item table -->
                            <div class="table-container" v-if="billData.details!=null">
                                <div class="table-responsive datatable-vue">
                                    <b-table show-empty :items="billData.details" :fields="fields">
                                        <template #cell(name)="row">
                                            <h6 style="min-width: 150px;" v-if="row.item.checkLabel">{{ row.item.name }}</h6>
                                            <p style="min-width: 150px;" v-else>{{ row.item.name }}</p>
                                        </template>

                                        <template #cell(rate)="row">
                                            <div v-if="row.item.rate!== null">
                                                <p v-if="row.item.rate == 0"></p>
                                                <p v-else>{{ row.item.rate }}</p>
                                            </div>
                                        </template>

                                        <template #cell(amount)="row">
                                            <div v-if="row.item.amount!== null">
                                                <p v-if="row.item.amount == 0"></p>
                                                <p v-else>{{ row.item.amount }}</p>
                                            </div>
                                        </template>

                                        <template #cell(description)="row">
                                            <div v-if="row.item.description !== null">
                                                <p style="width: 400px; word-break: break-all;">{{ truncateText(row.item.description, 45) }}</p>
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </div>

                            <div style="width: 100%; display: flex; justify-content: end;">
                                <!-- Payment Info -->
                                <div class="" style="font-size: small;">
                                    <div class="total-item" style="min-width: 25vh; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;">
                                        <h6 style="font-weight: 500;">Subtotal:</h6>
                                        <h6 style="text-align: right;">
                                            <span style="font-weight: 500;">
                                                {{ billData.total }}
                                            </span>
                                            {{ billData.currency }}
                                        </h6>
                                    </div>

                                    <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;" v-if="billData.vat">
                                        <h6 style="font-weight: 500;">Vat ({{ billData.vat ? billData.vat : 0 }}%):</h6>
                                        <h6 style="text-align: right;">
                                            {{vatAmount }}
                                        </h6>
                                    </div>
                                    <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;" v-if="billData.tax">
                                        <h6 style="font-weight: 500;">Tax ({{ billData.tax ? billData.tax : 0 }}%):</h6>
                                        <h6 style="text-align: right;">
                                            {{TaxAmount }}
                                        </h6>
                                    </div>
                                    <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;" v-if="billData.discount">
                                        <h6 style="font-weight: 500;">Discount ({{ billData.discount ? billData.discount : 0 }}%)</h6>
                                        <h6 style="text-align: right;">
                                            {{DiscountAmount }}
                                        </h6>
                                    </div>

                                    <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;">
                                        <h6 style="font-weight: 500;">Total:</h6>
                                        <h6 style="text-align: right; color: brown;">
                                            <span style="font-weight: 500;">
                                                {{ formatNumber(billData.subtotal) }}
                                            </span>
                                            {{ billData.currency }}
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="other-info-container mt-4" v-if="billData.remarks">
                                <h6 style="padding-left: 10px; padding-right: 10px;">Remarks:</h6>
                                <p style="padding: 8px; font-size: small;">
                                    {{ billData.remarks }}
                                </p>
                            </div>

                            <div style="display: flex; justify-content: flex-start; margin-top: 10px">
                                <div style="display: flex; flex-direction: column; align-items: flex-start;">
                                    <img style="height: auto; width: auto; max-height: 100px; max-width: 100px;" :src="host+setting.authorized_signature" alt="" v-if="showSignature == true" />

                                    <p style="border-style: solid; border-bottom: 40px; border-left: 0; border-right: 0; border-width: 1px; border-color: #d1d5db; padding: 2px 8px 4px; margin-top: 12px; font-size: small; align-self: flex-start;">
                                        Authorized signature
                                    </p>

                                </div>
                            </div>

                            <!-- <div style="width: 100%; display: flex; justify-content: space-between; margin-top: 32px; background-color: #e5e7eb; font-size: small; padding-left: 24px; padding-right: 24px;">
                            <p style="padding-top: 18px;">{{ setting.address }}</p>
                            <p style="padding-top: 18px;">
                                © 2024 SMICEE | Developed by
                                <a target="_blank" class="text-blue-500" href="https://bitflex.xyz/">
                                <span style="color: blue;">BitFlex</span>
                                </a>
                            </p>
                        </div> -->
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="loader-box">
                        <div class="loader-10"></div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4">
                <px-card>
                    <div class="content-container-attahment w-100 p-3" v-if="!loading && !error">
                        <div>
                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                <h6>Supporting Documents</h6>
                                <feather type="plus-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-scrollable-add-document v-b-tooltip.hover title="Add Documents"></feather>
                            </div>

                            <b-table show-empty sticky-header :items="billData.bill_attach" :fields="Attachment" head-variant="light">
                                <template #cell(index)="data">
                                   {{ data.index + 1 }}
                                </template>

                                <template #cell(title)="data">
                                    <div v-if="data.item.attach_title">
                                        <div class="d-flex flex-column">
                                            <a :href="host+data.item.bill_attachment" target="_blank" style="word-break: break-all;">{{ truncateText(data.item.attach_title,50) }}</a>
                                            <span style="font-size: medium;" v-if="data.item.is_vendor == true">
                                                <b-badge pill variant="primary">
                                                    Vendor
                                                </b-badge>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-else class="d-flex flex-col">
                                        <div class="d-flex flex-column">
                                            <a :href="host+data.item.bill_attachment" target="_blank" style="word-break: break-all; width: 100px;">--</a>
                                            <span style="font-size: medium;" v-if="data.item.is_vendor == true">
                                                <b-badge pill variant="primary">
                                                    Vendor
                                                </b-badge>
                                            </span>
                                        </div>
                                    </div>
                                </template>

                                <template #cell(action)="data">
                                    <div style="display: flex; flex-direction: row; gap:10px">
                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <div v-else>
                        <div class="loader-box">
                            <div class="loader-10"></div>
                        </div>
                    </div>
                </px-card>

                <px-card>
                    <div class="content-container-attahment w-100 p-3" v-if="!loading && !error">
                        <div>
                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                <h6>Bank Details</h6>
                            </div>

                            <b-table show-empty sticky-header :items="bank_accounts" :fields="banks" head-variant="light">
                                <template #cell(index)="data">
                                   {{ data.index + 1 }}
                                </template>

                                <template #cell(account_number)="data">
                                    <router-link :to="'/finance-and-accounts/banking-info/details/'+ data.item.id" class="link-style"><span>{{ data.item.account_number }}</span></router-link>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <div v-else>
                        <div class="loader-box">
                            <div class="loader-10"></div>
                        </div>
                    </div>
                </px-card>

                <px-card>
                    <div class="content-container-attahment w-100 p-3" v-if="!loading && !error">
                        <div>
                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                <h6>Payments</h6>
                            </div>

                            <b-table show-empty sticky-header :items="paymentList" :fields="payments" head-variant="light">
                                <template #cell(index)="data">
                                   {{ data.index + 1 }}
                                </template>

                                <template #cell(status)="row">
                                    <span v-if="row.value == 'APPROVED'">
                                        <b-badge variant="success">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.item, row.item.id)" v-if="row.value == 'PENDING'">
                                        <b-badge variant="primary" v-b-modal.modal-prevent-closing>{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.item, row.item.id)" v-if="row.value == 'REJECTED'">
                                        <b-badge variant="danger" v-b-modal.modal-prevent-closing>{{ row.value }}</b-badge>
                                    </span>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <div v-else>
                        <div class="loader-box">
                            <div class="loader-10"></div>
                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>

    <!-- Project Attachment Create -->
    <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
        <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload project file</label>
                        <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>

    <!-- Payment Status Change -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Payment Status" @ok="handlePaymentStatusOk">
        <form ref="form" @submit.stop.prevent="handlePayementStatusSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Payment approved -->
    <!-- <b-modal id="modal-center-approved" centered hide-header @ok="handlePayementSubmit">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to approved this payment?</div>
    </b-modal> -->

    <b-modal id="modal-prevent-status" ref="modal" size="xl" title="Add Transaction" hide-footer>
        <paymentApproveVue :amount="this.approved_status" :paymentType="'BILL'" />
    </b-modal>

    <!-- Reply Modal -->
    <b-modal id="modal-scrollable" ref="modal" title="Bill Reply" hide-footer>
        <div v-if="bill_replies_list.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in bill_replies_list" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name" v-if="reply.replier_photo">
                        </div>
                        <div class="message">
                            <span class="name pl-1">{{ reply.replied_by.username }}</span>
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleReplySubmit()">
                <button @click="handleReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
    myHost
} from "../../../_helpers/host";
import Pxcard from '../../../components/Pxcard.vue';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import paymentApproveVue from '../accounts/payments/payment-approve.vue'
export default {
    components: {
        Pxcard,
        paymentApproveVue,
    },
    props: ["id", "download"],

    data() {
        return {
            showPage: true,
            showLogo: true,
            host: myHost.host,
            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                }
            ],
            add_attachment: {
                bill_attachment: null,
                bill_id: ''
            },
            approved_status: {
                detail_id: null,
                update_id: '',
                amount: null,
            },

            fields: [{
                    key: 'name',
                    label: 'Item',
                    sortable: false
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: false
                },

                {
                    key: 'item_quantity',
                    label: 'Quantity',
                    sortable: false
                },
                {
                    key: 'rate',
                    label: 'Unit Price',
                    sortable: false
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    sortable: false
                }
            ],
            payments: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'requested_by.username',
                    label: 'Requested By',
                    sortable: true
                },
                {
                    key: 'paid_amount',
                    label: 'Request Amount',
                    sortable: true
                },

                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                }
            ],
            banks: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'bank_name',
                    label: 'Bank',
                    sortable: true
                },
                {
                    key: 'account_number',
                    label: 'Account No',
                    sortable: true
                }
            ],
            my_reply: {
                id: '',
                reply: '',
            },

            downloadPdf: false,
            bill: '',
            statuses: ['APPROVED', 'PENDING', 'REJECTED'],
            selectedStatus: '',
            showSignature: false,
            showLetterHead: true,
        }
    },

    methods: {
        generatePDF() {
            this.showPage = false;
            const element = this.$refs.printDiv;
            const pdf = new jsPDF('p', 'pt', 'letter');

            // Background and logo image settings
            const backgroundImageUrl = this.showLetterHead ? this.host + this.setting.letterpad : null;
            const logoImageUrl = this.showLogo ? this.host + this.setting.logo : null;

            const backgroundImg = new Image();
            const logoImg = new Image();

            const loadImage = (img, src) => {
                return new Promise((resolve, reject) => {
                    if (!src) {
                        resolve();
                    } else {
                        img.crossOrigin = 'anonymous'; // Ensure CORS is properly set if needed
                        img.src = src;
                        img.onload = () => resolve(img);
                        img.onerror = reject;
                    }
                });
            };

            Promise.all([loadImage(backgroundImg, backgroundImageUrl), loadImage(logoImg, logoImageUrl)]).then((images) => {
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();

                // Content padding and margin
                const contentPaddingTop = 0; // Adjust the top padding as needed
                const contentPaddingBottom = 0; // Adjust the bottom padding as needed
                const contentMargin = 0; // Margin between content and background image

                let positionY = contentPaddingTop; // Start position at the top padding
                let contentHeight = element.offsetHeight;

                const addContent = () => {
                    pdf.html(element, {
                        x: contentMargin,
                        y: positionY + contentMargin,
                        html2canvas: {
                            scale: 0.6,
                            useCORS: true // Use CORS for capturing images
                        },
                        callback: () => {
                            const filename = `${this.billData.bill_id}.pdf`;
                            pdf.save(filename);

                            this.showPage = true;
                        },
                        margin: [80, 0, 80, 0],
                    });
                };

                const addImages = () => {
                    if (this.showLetterHead) {
                        pdf.addImage(backgroundImg, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    }
                    if (this.showLogo) {
                        const logoWidth = logoImg.width; // Use the natural width of the logo image
                        const logoHeight = 30; // Use the natural height of the logo image
                        const logoMargin = 50; // Right margin for the logo
                        const logoX = pdfWidth - logoWidth - logoMargin;
                        const logoY = 18; // Margin from top
                        pdf.addImage(logoImg, 'JPEG', logoX, logoY, logoWidth, logoHeight);
                    }
                };

                addContent();
                addImages();

                let pageCount = Math.ceil(contentHeight / 794);
                for (let i = 1; i < pageCount; i++) {
                    pdf.addPage();
                    addImages();
                }
            }).catch((error) => {
                console.error('Error loading images:', error);
            });
        },
        showPDF() {
            this.showPage = false;
            const element = this.$refs.printDiv;
            const pdf = new jsPDF('p', 'pt', 'letter');

            // Background and logo image settings
            const backgroundImageUrl = this.showLetterHead ? this.host + this.setting.letterpad : null;
            const logoImageUrl = this.showLogo ? this.host + this.setting.logo : null;

            const backgroundImg = new Image();
            const logoImg = new Image();

            const loadImage = (img, src) => {
                return new Promise((resolve, reject) => {
                    if (!src) {
                        resolve();
                    } else {
                        img.crossOrigin = 'anonymous'; // Ensure CORS is properly set if needed
                        img.src = src;
                        img.onload = () => resolve(img);
                        img.onerror = reject;
                    }
                });
            };

            Promise.all([loadImage(backgroundImg, backgroundImageUrl), loadImage(logoImg, logoImageUrl)]).then((images) => {
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();

                // Content padding and margin
                const contentPaddingTop = 0; // Adjust the top padding as needed
                const contentPaddingBottom = 0; // Adjust the bottom padding as needed
                const contentMargin = 0; // Margin between content and background image

                let positionY = contentPaddingTop; // Start position at the top padding
                let contentHeight = element.offsetHeight;

                const addContent = () => {
                    pdf.html(element, {
                        x: contentMargin,
                        y: positionY + contentMargin,
                        html2canvas: {
                            scale: 0.6,
                            useCORS: true // Use CORS for capturing images
                        },
                        callback: () => {
                            // const filename = `${this.invoiceData.invoice_id}.pdf`;

                            // Convert the PDF to a Blob and create an object URL
                            const pdfBlob = pdf.output('blob');
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Open the PDF in a new tab
                            window.open(pdfUrl);

                            this.showPage = true;
                        },
                        margin: [80, 0, 80, 0],
                    });
                };

                const addImages = () => {
                    if (this.showLetterHead) {
                        pdf.addImage(backgroundImg, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    }
                    if (this.showLogo) {
                        const logoWidth = logoImg.width; // Use the natural width of the logo image
                        const logoHeight = 30; // Use the natural height of the logo image
                        const logoMargin = 50; // Right margin for the logo
                        const logoX = pdfWidth - logoWidth - logoMargin;
                        const logoY = 18; // Margin from top
                        pdf.addImage(logoImg, 'JPEG', logoX, logoY, logoWidth, logoHeight);
                    }
                };

                addContent();
                addImages();

                let pageCount = Math.ceil(contentHeight / 794);
                for (let i = 1; i < pageCount; i++) {
                    pdf.addPage();
                    addImages();
                }
            }).catch((error) => {
                console.error('Error loading images:', error);
            });
        },
        toggleLogo() {
            this.showLogo = !this.showLogo;
        },
        formatNumber(number) {
            return Number(number.toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits: 2
            });
        },
        toggleLetterHead() {
            this.showLetterHead = !this.showLetterHead;
        },

        toggleSignature() {
            this.showSignature = !this.showSignature;
        },

        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            })
        },

        Reply(type) {
            console.log(type)
            this.my_reply.id = type.id
            this.$store.dispatch("bill/fetchBillReplies", this.$route.params.id)
        },

        handleReplySubmit() {
            this.$store.dispatch("bill/addBillReply", {
                bill: this.my_reply.id,
                reply: this.my_reply.reply,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        // getStatus(status, id) {
        //     this.bill = id
        //     this.selectedStatus = status
        // },

        getStatus(status, id) {
            this.approved_status.amount = status.paid_amount
            this.approved_status.update_id = id
            this.approved_status.detail_id = status.bill.id
            this.selectedStatus = status.status
        },

        handlePaymentStatusOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handlePayementStatusSubmit()
        },

        handlePayementStatusSubmit() {
            if (this.selectedStatus === 'APPROVED') {

                this.$nextTick(() => {
                    this.$bvModal.show('modal-prevent-status')
                })

            } else {
                this.$store.dispatch("payments/updateBillPaymentStatus", {
                    id: this.approved_status.update_id,
                    status: this.selectedStatus,
                    detail_id:this.approved_status.detail_id,
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.update_id = null

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            }
        },

        // handlePayementSubmit() {
        //     this.$store.dispatch("bill/updatePaymentStatus", {
        //         detail_id: this.$route.params.id,
        //         id: this.bill,
        //         status: this.selectedStatus
        //     }).then(response => {
        //         if (response.code == 200) {
        //             Swal.fire({
        //                 title: "Success!",
        //                 text: response.response,
        //                 icon: "success"
        //             });

        //             this.payment = null

        //         } else {
        //             Swal.fire({
        //                 title: "Error!",
        //                 text: response.response,
        //                 icon: "error"
        //             });
        //         }
        //     })

        //     this.$nextTick(() => {
        //         this.$bvModal.hide('modal-prevent-closing-status')
        //     })
        // },

        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },

        printPdf(section) {
            this.downloadPdf = true;

            const printContents = document.getElementById(section).innerHTML;
            const originalContents = document.body.innerHTML;
            const wrappedPrintContents = `<div class="page">${printContents}</div>`;

            document.body.innerHTML = wrappedPrintContents;

            window.print();

            document.body.innerHTML = originalContents;
            location.reload();
        },

        parseDate(date) {
            return new Date(Date.parse(date)).toLocaleDateString("en-US", {
                year: "numeric",
                day: "numeric",
                month: "short",
            });
        },

        editBill(id) {
            this.$router.push({
                path: `/finance-and-accounts/finance/bill/edit/${id}`
            });
        },

        handleAttachOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleAttahmentSubmit()
        },

        handleAttahmentSubmit() {
            this.add_attachment.bill_id = this.$route.params.id

            this.$store.dispatch("bill/addBillAttachment", {
                attachment: this.add_attachment,
                bill_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.add_attachment.bill_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable-add-document')
            })
        },

        deleteAttach(id) {
            this.$store.dispatch("bill/deleteAttachmentFromBill", {
                id: id,
                bill_id: this.$route.params.id,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        uploadAttachFile(e) {
            this.add_attachment.bill_attachment = e.target.files[0]
        }
    },

    computed: {
        ...mapState({
            billData: state => state.bill.bill_detail,
            setting: state => state.organization.organization_setting,
            paymentList: state => state.bill.billPaymentList,
            bill_replies_list: state => state.bill.bill_replies,
            bank_accounts: state => state.bank.active_accounts,
            loading: state => state.bill.submitLoading,
            error: state => state.bill.submitError
        }),

        additionalStyle() {
            return this.showLetterHead ? {
                'background-image': `url(${this.host}${this.setting.letterpad})`,
                'background-size': '100% 100%',
                'background-position': 'center center',
                'background-repeat': 'no-repeat',
            } : {};
        },
        vatAmount() {
            if (this.billData.vat !== 0) {
                let total = this.billData.total + this.billData.vat * (this.billData.total / 100)
                let gtotal = total - this.billData.total

                return Math.round((gtotal + Number.EPSILON) * 100) / 100;
            }
            return 0;
        },

        TaxAmount() {
            if (this.billData.tax !== 0) {
                let total = this.billData.total + this.billData.tax * (this.billData.total / 100)
                let gtotal = total - this.billData.total

                return Math.round((gtotal + Number.EPSILON) * 100) / 100;
            }
            return 0;
        },
        DiscountAmount() {
            if (this.billData.discount !== 0) {
                let total = this.billData.total + this.billData.discount * (this.billData.total / 100)
                let gtotal = total - this.billData.total

                return Math.round((gtotal + Number.EPSILON) * 100) / 100;
            }
            return 0;
        },
    },

    mounted() {
        this.$store.dispatch("bill/fetchBillDetail", this.$route.params.id).then(() => {
            this.$store.dispatch("bill/fetchBillPaymentByBill", this.billData.id)
        })
        this.$store.dispatch("organization/fetchOrganizationSettings")
        this.$store.dispatch("bank/fetchActiveBankAccounts")
    }
};
</script>

<style scoped>
.content-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px 60px;
}

.imgSection {
    position: absolute;
    top: 32px;
    right: 58px;
}

.content-container-attahment {
    background-color: white;
    padding: 10px;
}

.invoice-header,
.client-section {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 48px;
}

.company-info,
.client-info,
.payment-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: start;
}

.total-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.invoice-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: end;
}

.logo-img {
    object-fit: cover;
    object-position: center;
}

.table-container {
    width: 100%;
    font-size: small;
    color: black;
}

.total-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
    margin-bottom: 32px;
}

.other-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* @media print {
        html,
        body {
            width: 210mm;
            height: 297mm;
        }

        .content-container {
            margin: 0;
            width: initial;
            min-height: initial;
        }
    } */

.invoice-container {
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

@media print {
    .invoice-container {
        display: none;
        visibility: hidden;
    }

    .signature-btn {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 639px) {
    .content-container {
        background-color: white;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 20px 20px;
    }

    .invoice-header {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 20px;
    }

    .invoice-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: end;
    }

    .client-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 20px;
        margin-bottom: 20px;
    }

    .total-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-self: flex-end;
        margin-top: 20px;
        margin-bottom: 32px;
    }
}

/* Reply */
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#print {
    width: 1020px;
    padding: 0in 0in;
    box-sizing: border-box;

}
</style>
