import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        company: [],
        client: [],
        client_without_pg: [],
        client_count:null,
        clien_details:{},
        company_details:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,
        SET_COMPANY (state, company) {
            state.company = company;
        },

        SET_CLIENT (state, {results, pg_count}) {
            state.client = results;
            state.client_count = pg_count;
        },

        SET_CLIENT_WITHOUT_PG(state, results) {
            state.client_without_pg = results;
        },

        SET_CLIENT_DETAILS(state, details){
            state.clien_details = details;
        },

        SET_COMPANY_DETAILS(state, details){
            state.company_details = details;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateProject ({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.projectUpdate,
                async () => {

                    let magic=encode({name:payload.name})
                    response= await axios.post(V1API.area_edit+'/'+payload.area_id,magic.encodedPayload,magic.config).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchCompany');

                }
            )
            return response
        },

        async deleteClient ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_client + payload.item, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchClient',{perPage:payload.perPage,currentPage:payload.page,search_term:payload.search_term,status:payload.status});
            
            return response
        },

        async fetchCompany ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_company, config);
                let response = result.data

                commit('SET_COMPANY', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
           
        },

        async fetchClient ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.status) params.append('status', payload.status);
            
           
                let url = V1API.get_client + '?' + params.toString();
                let result = await axios.get(url, config);
                const {results, pg_count} = result.data
                commit('SET_CLIENT', {results, pg_count});
                return result
                
            
            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
           
        },

        async fetchClient_without_pg ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_client_without_pg, config);
                let response = result.data.data

                commit('SET_CLIENT_WITHOUT_PG', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
           
        },

        async updateClient ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
               
                async () => {
                    response= await axios.patch(V1API.update_client+payload.id,payload.client_edit,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchClient',{perPage:payload.perPage,currentPage:payload.page,search_term:payload.search_term,status:payload.status});

                }
            )
            return response
        },

        async fetchClientDetails ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.client_details +payload, config);
                let response = result.data

                commit('SET_CLIENT_DETAILS', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchClientByUserId ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.client_by_user_id + payload, config);
                let response = result.data

                commit('SET_CLIENT_DETAILS', response.data); 
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }

            return response
        },

        async addCompany ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_company, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchCompany')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchCompanyDetails ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.company_details +payload, config);
                let response = result.data

                commit('SET_COMPANY_DETAILS', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }

            return response
        },

        async addClient ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_client, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchClient_without_pg')
                // dispatch ('fetchClient')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },

    getters: {}
}
