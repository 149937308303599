<template>
  <div>
    <Breadcrumbs main="Report" title="Today's Delivery Report"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">

      <div class="row">
        <div class="col-xl-12">
          <div class="card calender-form">
            <div class="card-body">

              <form @submit.prevent="" method="post" class="form theme-form">
                <div class="row">
<!--                  <div class="col-4">-->
<!--                    <label class="f-w-600">Start Date:</label>-->
<!--                    <div class="form-group">-->
<!--                      <datepicker input-class="datepicker-here form-control digits" :format="format"></datepicker>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <label class="f-w-600">End Date:</label>-->
<!--                    <div class="form-group">-->
<!--                      <datepicker input-class="datepicker-here form-control digits" :format="format"></datepicker>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label  class="f-w-600">Select Status:</label>
                      <b-form-select v-model="numberSelectedFlatInput" class="btn-square"
                                     :options="numberOptions"></b-form-select>

                    </div>
                  </div>
                  <div class="col-md-4 mt-1">

                    <div class="form-group mt-4">
                      <div>
                        <button class="btn btn-primary">Filter</button>
                        <button class="btn btn-secondary ml-2 ">Download</button>
                      </div>


                    </div>
                  </div>

                </div>
              </form>




              <b-row>
                <b-col xl="6">

                  <b-input-group class="datatable-btn">
                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>

                </b-col>

                <b-col xl="6">
                  <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    show-empty
                    stacked="md"
                    :items="items"
                    :fields="tablefields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                  <template #cell(old_balance)="row">
                    {{ row.value }} BDT
                  </template>
                  <template #cell(cr_dr_amount)="row">
                    {{ row.value }} BDT
                  </template>
                  <template #cell(new_balance)="row">
                    {{ row.value }} BDT
                  </template>
                </b-table>
              </div>

              <b-col md="6" class="my-1 p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="my-0"
                ></b-pagination>
              </b-col>


            </div>
          </div>
        </div>


      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    Datepicker
  },
  data() {

    return {
      format: 'MM/dd/yyyy',
      tablefields: [
        {key: 'index', label: '#', sortable: true,},
        {key: 'user', label: 'User', sortable: true,},
        {key: 'mobile', label: 'Mobile', sortable: true,},
        {key: 'sent_time', label: 'Sent Time', sortable: true,},
        {key: 'sender_id', label: 'Sender ID', sortable: true,},
        {key: 'cost', label: 'Cost', sortable: true,},
        {key: 'sms_text', label: 'SMS Text', sortable: true,},
        {key: 'status', label: 'Status', sortable: true,},
      ],

      items: [
        {
          user: 'Rafat Hossain',
          mobile: 'Credit',
          sent_time: '4.40 ',
          sender_id: '1.00',
          cost: '5.40 ',
          sms_text: 'আপনার মতামতের জন্য ধন্যবাদ',
          status: 'Delivered'
        },


      ],
      numberSelectedFlatInput: 1,
      numberOptions: [
        {value: "Pending", text: "Pending"},
        {value: "Delivered", text: "Delivered"},
        {value:"Failed", text: "Failed"},
        {value: "Blocked", text: "Blocked"},
      ],

      filter: null,

      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>
