import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";

const keyList = {
    smsUpdate : 'EMPLOYEE_UPDATE',
    smsDelete : 'EMPLOYEE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        employee_list: [],
        employee_list_pg:[],
        employee_list_pg_count:null,
        employee_detail: {},
        employee_asset:[],
        employee_aseet_count:null,

        employee_project:[],
        employee_project_count:null,

        employee_ticket:[],
        employee_ticket_count:null,

        employee_leave:[],
        employee_leave_count:null,

        employee_eod:[],
        employee_eod_count:null,
        my_detail:{},
        
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,
        SET_EMPLOYEE(state, employee) {
            state.employee_list = employee;
        },

        SET_EMPLOYEE_withpg(state,  {results,pg_count}) {
            state.employee_list_pg = results;
            state.employee_list_pg_count = pg_count;
        },

        SET_EMPLOYEE_DETAIL (state, detail) {
            state.employee_detail = detail;
        },

        SET_EMPLOYEE_ASSET(state, {data,count}) {
            state.employee_asset = data;
            state.employee_aseet_count = count;
        },

        SET_EMPLOYEE_PROJECT(state, {data,count}) {
            state.employee_project = data;
            state.employee_project_count = count
        },

        SET_EMPLOYEE_TICKET(state, {data,count}) {
            state.employee_ticket = data;
            state.employee_ticket_count = count;
        },

        SET_EMPLOYEE_LEAVE(state, {data,count}) {
            state.employee_leave = data;
            state.employee_leave_count = count;
        },

        SET_EMPLOYEE_EOD(state, {data,count}) {
            state.employee_eod = data;
            state.employee_eod_count = count;
        },

        SET_MY_DETAIL(state, detail) {
            state.my_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async fetchEmployeeList ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_all_employee, config);
                let response = result.data.data
                commit('SET_EMPLOYEE', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        
        async fetchEmployeeList_withpg ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.department) params.append('department', payload.department);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.designation) params.append('designation', payload.designation);
                if (payload.employee) params.append('employee', payload.employee);
                
                let url = V1API.get_all_employee_withpg + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results, pg_count} = result.data
                commit('SET_EMPLOYEE_withpg',  {results, pg_count});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchMyDetails({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.my_employee_details, config);
                let response = result.data
                commit('SET_MY_DETAIL', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchEmployeeAsset ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_employee_asset + payload, config);
                const {data,count} = result.data
                commit('SET_EMPLOYEE_ASSET', {data,count});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchEmployeeProject ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.project_list_by_employee + payload, config);
                const {data,count} = result.data
                commit('SET_EMPLOYEE_PROJECT', {data,count});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchEmployeeTicket ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_ticket_list_employee + payload, config);
                const {data,count} = result.data
                commit('SET_EMPLOYEE_TICKET', {data,count});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
           
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchEmployeeLeave ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_leave_employee + payload, config);
                const {data,count} = result.data
                commit('SET_EMPLOYEE_LEAVE', {data,count});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
           
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchEmployeeEOD ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_eod_list_by_employee + payload, config);
                const {data,count} = result.data
                commit('SET_EMPLOYEE_EOD', {data,count});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchEmployeeDetail ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.employee_details+payload, config);
                let response = result.data
                commit('SET_EMPLOYEE_DETAIL', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }

            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addEmployee ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            try{
                let response = await axios.post(V1API.create_employee, payload, config).then(result=>{
                    let data = result.data
                    return data
                });
                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchEmployeeList')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateEmployee ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            await performNetworkCall(
                commit,
                keyList.smsUpdate,

                async () => {
                    response= await axios.patch(V1API.update_employee_information + payload.id,payload.info ,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })
                    dispatch('fetchEmployeeList');
                    dispatch('fetchEmployeeDetail',payload.id);
                }
            )
            return response
        },

        async deleteEmployee ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.update_employee_delete +'/'+ payload.item, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchEmployeeList_withpg',{perPage:payload.perPage, currentPage:payload.page, start_date:payload.start_date, end_date:payload.end_date, search_term:payload.search_term, employee:payload.employee, department:payload.department, designation:payload.designation});
            return response
        },

        async addEmployeeAttachment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.attachment);
             
                let response = await axios.post(V1API.employee_add_attachment, payloadData, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchMyDetails')
                return response
            }catch (e) { 
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteAttachmentFromEmployee ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.employee_delete_attachment + payload.id, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch ('fetchMyDetails')
            return response
        }
    },

    getters: {}
}
