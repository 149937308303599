<template>

    <div >
        <Breadcrumbs main="HR" title="Policy" />

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header" style="width: 100%">
                            <div style="max-width: fit-content;">
                                <router-link to="/hr/policy/create">
                                    <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                        <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                            <i class="fa fa-light fa-plus" style="margin-right: 10px;"></i>
                                            <h7>Add New Policy</h7>
                                        </div>
                                    </button>
                                </router-link>
                            </div>
                            <!-- <router-link to="/hr/policy/create" style="display: flex; gap: 12px; align-items: center; color: white;">
                            <button class="btn btn-primary" type="submit">
                                <i class="fa fa-light fa-plus" style="margin-right: 10px;"></i>
                                <h7>Add New Policy</h7>
                            </button>
                        </router-link> -->
                        </div>

                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div v-if="!loading">
                                <div class="table-responsive datatable-vue">
                                    <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ (currentPage - 1) * perPage + data.index + 1 }}
                                        </template>

                                        <template #cell(title)="data">
                                            <span style="width:60%">
                                                <router-link :to="`/hr/policy/add-form/${data.item.id}`" class="link-style">
                                                    <span>{{ data.item.title }}</span>
                                                </router-link>
                                            </span>
                                        </template>

                                        <template #cell(response)="data">
                                            <span v-if="data.item.form_generated == true">
                                                <router-link :to="`/hr/policy/responses/${data.item.generated_form}`">
                                                    <b-button variant="primary" style="width: 100px;">View</b-button>
                                                </router-link>
                                            </span>
                                        </template>

                                        <!-- <template #cell(form_submit)="data">
                                    <span v-if="data.item.form_generated == true">
                                        <router-link :to="`/hr/policy/view-form/${data.item.id}`">
                                            <b-button variant="secondary" style="width: 125px;">View Form</b-button>
                                        </router-link>
                                    </span>

                                </template> -->

                                        <template #cell(form_generated)="data">

                                            <div style="display: flex; flex-direction: row; gap: 10px;">
                                                <div>
                                                    <span v-if="data.item.form_generated == true">
                                                        <router-link :to="`/hr/policy/edit-form/${data.item.id}`">
                                                            <feather type="edit-3" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Form Edit"></feather>
                                                        </router-link>
                                                    </span>
                                                    <span v-else>
                                                        <router-link :to="`/hr/policy/add-form/${data.item.id}`">
                                                            <feather type="plus-circle" size="20px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Add"></feather>
                                                        </router-link>
                                                    </span>
                                                </div>
                                                <div>
                                                    <span v-if="data.item.form_generated == true">
                                                        <router-link :to="`/hr/policy/responses/${data.item.generated_form}`">

                                                            <feather type="message-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Response"></feather>

                                                        </router-link>
                                                    </span>
                                                </div>
                                            </div>

                                        </template>

                                        <template #cell(action)="data">
                                            <span style="display: flex; gap: 12px;">
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.id)" title="Delete"></feather>
                                                <router-link :to="`/hr/policy/edit/${data.item.id}`">
                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                </router-link>
                                            </span>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                            <div v-else>
                                <div class="loader-box">
                                    <div class="loader-10"></div>
                                </div>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this policy?</div>
        </b-modal>
    </div>
    


</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true,
                },

                {
                    key: 'form_generated',
                    label: 'Form',
                    sortable: true,

                },

                {
                    key: 'action',
                    label: 'Action',
                    sortable: true,
                },
            ],

            filter: null,
            delete_value: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
        };
    },

    computed: {
        ...mapState({
            items: state => state.policy.policy_pg,
            count: state => state.policy.policy_pg_count,
            loading: state => state.policy.submitLoading,
            error: state => state.policy.submitError,

        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    watch: {
        currentPage(newCurrentPage) {
            this.$router.replace({
                path: '/hr/policy',
                query: {
                    page: newCurrentPage,
                    perPage: this.perPage
                },
            });
            this.currentPage = newCurrentPage
            this.$store.dispatch("policy/fetchPolicy_with_pg", {
                perPage: this.perPage,
                currentPage: newCurrentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        },
        perPage(newperPage) {
            this.$router.replace({
                path: '/hr/policy',
                query: {
                    page: this.currentPage,
                    perPage: newperPage
                },
            });
            this.perPage = newperPage
            this.$store.dispatch("policy/fetchPolicy_with_pg", {
                perPage: newperPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        },
    },

    mounted() {
        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage,
                },
            });

            this.$store.dispatch("policy/fetchPolicy_with_pg", {
                perPage: this.perPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                },
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("policy/fetchPolicy_with_pg", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,

            }).then(() => {
                this.totalRows = this.count;
            });

        }

    },

    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteItem(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("policy/deletePolicy", {
                item: this.delete_value,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
