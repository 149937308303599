<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Group" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Group</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 20px;">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                        <feather type="filter"></feather>
                                        <h7>Filter</h7>
                                    </div>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="categorySelected">
                                        <feather type="x"></feather>
                                        <h7>Clear All</h7>
                                    </div>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <b-form-select v-model="categorySelected" :options="formattedCategories"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!accountGroupLoading && !accountGroupError">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(pre_defined)="data">
                                        {{data.item.pre_defined.title}}
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.id)" title="Delete"></feather>

                                            <feather @click="editGroup(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Create Group -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Create Group" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Pre Defined" label-for="name-input" invalid-feedback="Pre Defined is required">
                <b-form-select v-model="group.pre_defined">
                    <b-form-select-option v-for="option in options" :value="option.id">{{ option.title }}</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <b-form-group label="Title" label-for="name-input" invalid-feedback="Title is required">
                <b-form-input id="name-input" v-model="group.title" placeholder="Enter group title"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Edit Group -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Group" @show="resetModal" @hidden="resetModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Pre Defined" label-for="name-input" invalid-feedback="Pre Defined is required">
                <b-form-select v-model="edit_group.pre_defined">
                    <b-form-select-option v-for="option in options" :value="option.id">{{ option.title }}</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <b-form-group label="Title" label-for="name-input" invalid-feedback="Title is required">
                <b-form-input id="name-input" v-model="edit_group.title" placeholder="Enter group title"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Group',
                    sortable: true
                },
                {
                    key: 'pre_defined',
                    label: 'Pre Defined Categories',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            items: [],

            group: {
                title: '',
                pre_defined: ''
            },

            edit_group: {
                title: '',
                pre_defined: ''
            },

            options: [],
            filterShow: false,
            categorySelected: '',
            filter: null,
            initialLoad: true,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState('account', ["accountCategories", "accountGroupsPG", "accountGroups_count", "accountGroupLoading", "accountGroupError", "accountCategoryLoading", "accountCategoryError", "submitError", "submitSuccess", "submitLoading"]),

        formattedCategories() {
            return [{
                    value: '',
                    text: 'Select Category',
                    disabled: true
                },
                ...this.options.map(status => ({
                    value: status.id,
                    text: status.title
                }))
            ];
        }
    },

    created() {
        this.$store.dispatch('account/fetchAccountCategory')

        this.options = this.accountCategories
    },

    watch: {
        accountCategories(oldValue, newValue) {
            this.options = this.accountCategories
        },

        accountGroupsPG(oldValue, newValue) {
            this.items = this.accountGroupsPG
            this.totalRows = this.accountGroups_count;
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/account/group',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("account/fetchAccountGroupWithPagination", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    search_term: this.filter,
                    category: this.categorySelected
                }).then(() => {
                    this.totalRows = this.accountGroups_count;
                });
            }

        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/account/group',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("account/fetchAccountGroupWithPagination", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    search_term: this.filter,
                    category: this.categorySelected
                }).then(() => {
                    this.totalRows = this.accountGroups_count;
                });
            }

        },

        filter(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("account/fetchAccountGroupWithPagination", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.filter,
                    category: this.categorySelected
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.accountGroups_count;
                });
            }

        },

        categorySelected(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("account/fetchAccountGroupWithPagination", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.filter,
                    category: this.categorySelected
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.accountGroups_count;
                });
            }

        }
    },

    mounted() {
        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {

                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitial();
            } else {

                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitial();
            }
        }
    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("account/fetchAccountGroupWithPagination", {
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    search_term: this.filter,
                    category: this.categorySelected
            }).then(() => {
                this.totalRows = this.accountGroups_count;
                this.initialLoad = false;
            });
        },
        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        clearFilter() {
            this.filterShow = false,
                this.filter = null,
                this.categorySelected = ''
        },

        resetModal() {
            this.group.title = ''
            this.group.pre_defined = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleSubmit() {
            if (this.group.title && this.group.pre_defined) {
                this.$store.dispatch("account/addAccountGroup", {
                    group: this.group,
                    currentPage: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                    search_term: this.filter,
                    category: this.categorySelected
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.group.title = ''
                        this.group.pre_defined = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editGroup(item) {
            console.log(item)
            this.edit_group.id = item.id
            this.edit_group.title = item.title
            this.edit_group.pre_defined = item.pre_defined.id
        },

        // resetEditModal() {
        //     this.edit_item.name = ""
        //     this.edit_item.id = null
        //     this.edit_item.rate = null
        //     this.edit_item.description = ""
        // },

        handleEditSubmit() {
            this.$store.dispatch("account/updateAccountGroup", {
                group: this.edit_group,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                category: this.categorySelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_group.id = ""
                    this.edit_group.title = ''
                    this.edit_group.pre_defined = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        deleteItem(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("account/deleteAccountGroup", {
                id: this.delete_value,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                category: this.categorySelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
