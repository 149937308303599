<template>
    <div>
        <Breadcrumbs main="CRM" title="Client Create" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Name (Contact Person)</label>
                                            <input class="form-control" type="text" placeholder="Enter contact person's name *" v-model="client.first_name">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Company <span><button class="btn-success ml-3" v-b-modal.modal-prevent-closing>Add New</button></span></label> 
                                            <select class="form-control digits" v-model="client.company" @change="getCompany">
                                                <option v-for="(company, index) in companies" :key="index" :value="company.id">{{ company.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Designation</label>
                                            <input class="form-control" type="text" placeholder="Enter designation *" v-model="client.designation">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Phone Number</label>
                                            <input class="form-control" type="text" placeholder="Enter phone number *" v-model="client.phone">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input class="form-control" type="text" placeholder="Enter email address *" v-model="client.email">
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Address</label>
                                            <input class="form-control" type="text" placeholder="Enter client address " v-model="client.address">
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Client Status</label>
                                            <select class="form-control digits" v-model="client.status">
                                                <option v-for="(status, index) in statuses" :key="index" :value="status.value">{{ status.title }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>



                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mb-0">
                                            <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                            <router-link to="/crm/client/list">
                                                <button class="btn btn-danger" href="#">Go Back</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Add Company"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    label="Company Name"
                    label-for="name-input"
                    invalid-feedback="Company Name is required"
                    :state="nameState"
                >
                    <b-form-input
                        id="name-input"
                        v-model="company.name"
                        :state="nameState"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Email"
                    label-for="name-input"
                    invalid-feedback="Email address is required"
                    :state="nameState"
                    >
                    <b-form-input
                        id="name-input"
                        v-model="company.email"
                        :state="nameState"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Website"
                    label-for="name-input"
                    invalid-feedback="Website is required"

                >
                    <b-form-input
                        id="name-input"
                        v-model="company.website"
                        
                        
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Address"
                    label-for="name-input"
                    invalid-feedback="Company address is required"
                    :state="nameState"
                    >
                    <b-form-input
                        id="name-input"
                        v-model="company.address"
                        :state="nameState"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="BIN"
                    label-for="name-input"
                    invalid-feedback="BIN is required"
                  
                >
                    <b-form-input
                        id="name-input"
                        v-model="company.bin"
                      
                       
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="TIN"
                    label-for="name-input"
                    invalid-feedback="TIN is required"
                   
                >
                    <b-form-input
                        id="name-input"
                        v-model="company.tin"
                     
                       
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data(){
        return{
            client: {
                first_name: '',
                company: null,
                designation: '',
                phone: '',
                email: '',
                status: true,
                address:null,
            },

            company: {
                name: '',
                website: '',
                email: '',
                address: '',
                bin: '',
                tin: ''
            },

            nameState: null,

            statuses: [
                {
                    title: 'Active',
                    value: true
                }, 
                {
                    title: 'Inactive',
                    value: false
                }
            ]
        };
    },

    computed: {
        ...mapState({
            companies: state => state.client.company
        })
    },

    mounted() {
        this.$store.dispatch("client/fetchCompany")
    },

    methods: {
        getCompany() {
            this.$store.dispatch("client/fetchCompanyDetails", this.client.company).then(result => {
                this.client.address =  result.data.address
            })
        },

        onSubmit() {
            this.$store.dispatch("client/addClient", this.client).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.client.first_name = '',
                    this.client.company = null,
                    this.client.designation = '',
                    this.client.phone = '',
                    this.client.email = '',
                    this.client.status = true
                    this.client.address = null

                    setTimeout(() => {
                        this.$router.push('/crm/client/list') 
                    }, 2000)
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        
        resetModal() {
            this.company.name = ''
            this.company.email = ''
            this.company.website = ''
            this.company.address = ''
            this.company.bin = ''
            this.company.tin = ''
            this.nameState = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            if (!this.checkFormValidity()) {
                return
            }

            this.$store.dispatch("client/addCompany", this.company).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.client.company = response.data.id
                    this.client.address = response.data.address 

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        }
    }
};
</script>
