/* eslint-disable */
import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import router from './router';
import Breadcrumbs from './components/bread_crumbs';
import { store } from './store';
import Vue2Filters from 'vue2-filters';
import VueSweetalert2 from 'vue-sweetalert2';
import VueFormWizard from 'vue-form-wizard';
import VueTour from 'vue-tour';
import Notifications from 'vue-notification';
import { Vue2Dragula } from 'vue2-dragula';
import Toasted from 'vue-toasted';
import SmartTable from 'vuejs-smart-table';
import * as VueGoogleMaps from 'vue2-google-maps';
import { VueMasonryPlugin } from 'vue-masonry';
import VueFeather from 'vue-feather';
import VueApexCharts from 'vue-apexcharts';
import FunctionalCalendar from 'vue-functional-calendar';
import vueKanban from 'vue-kanban';
import ckeditor from "@ckeditor/ckeditor5-vue2";


import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

import PxCard  from './components/Pxcard.vue';
Vue.component(PxCard.name, PxCard);

import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;

// Multi Language Add
import en from './locales/en.json';
import es from './locales/es.json';
import { defaultLocale, localeOptions } from './constants/config';
import VueI18n from 'vue-i18n';

// Import Theme scss
import './assets/scss/app.scss';

// config for firebase

import firebase from '../firebase-config';


// console.log(firebase.database());
// import firebase from 'firebase/app';
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import config from './config.json';
// const firebase_app = initializeApp(config);
// const analytics = getAnalytics(firebase_app);

import axios from "axios";
Icon.Default.mergeOptions({
  iconRetinaUrl: require('./assets/images/vue2leaflet/marker-icon.png'),
  iconUrl: require('./assets/images/vue2leaflet/marker-icon.png')
});






// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('../public/firebase-messaging-sw')
//   .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//   })
//   .catch((err) => {
//       console.error('Service Worker registration failed:', err);
//   });
// }


Vue.use(VueFeather);

Vue.use(Toasted,{
  iconPack: 'fontawesome'
});
Vue.use(Vue2Dragula);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCdXpLSJ3Ibdu-Phs9QOvpqb9d1DtPf7wQ',
    libraries: 'places',
  },
});

Vue.prototype.$http= axios;

Vue.use(Notifications);
Vue.use(Vue2Filters);
Vue.use(VueSweetalert2);
Vue.use(VueFormWizard);
Vue.use(VueTour);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
Vue.use(SmartTable);
Vue.use(ckeditor);
Vue.use(require('vue-chartist'));
Vue.use(require('vue-moment'));
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.use(VueMasonryPlugin);
Vue.component('apexchart', VueApexCharts);
Vue.use(FunctionalCalendar, {
  dayNames: ['M', 'T', 'W', 'T', 'F', 'S', 'S']
});
Vue.use(vueKanban);
Vue.use(VueI18n);




// setup fake backend
import { configureFakeBackend } from './_helpers';
configureFakeBackend();

const messages = { en: en, es: es };
const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: true
});




Vue.config.productionTip = false;
// firebase.initializeApp(config.firebase);

// export const db = firebase.database();

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
