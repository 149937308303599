<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Quotation" />
    <div>
        <div class="invoice-container">
            <div style="display: flex; gap: 10px;">
                <b-button variant="primary" @click="editQuotation(quotationData.id)">Edit</b-button>
                <b-button variant="primary" @click="showPDF">Preview</b-button>
                <b-button variant="primary" @click="generatePDF">Download</b-button>

            </div>
            <div class="d-flex flex-row mt-4" style="gap: 10px;">

                <div class="d-flex flex-row">
                    <h6 class=" align-content-center mr-2">Letter Head</h6>
                    <div class="">
                        <div class="media-body text-right icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" :checked="showLetterHead" @click="toggleLetterHead"><span class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row">
                    <h6 class=" align-content-center mr-2">Logo</h6>
                    <div class="">
                        <div class="media-body text-right icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" :checked="showLogo" @click="toggleLogo"><span class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row">
                    <h6 class=" align-content-center mr-2">Signature</h6>
                    <div class="">
                        <div class="media-body text-right icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" :checked="showSignature" @click="toggleSignature"><span class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-xl-8">
                <div v-if="!loading && !error">
                    <div :id="showPage ? '' : 'print'" ref="printDiv">
                        <div class="content-container">
                            <!-- <div style="display: flex; align-items: center;" class="imgSection" v-if="showLogo">
                                                <img style="height: 64px;" :src="host + setting.logo" alt="" />
                                            </div> -->
                            <div class="invoice-header mt-4">
                                <!-- Company info -->
                                <div style="font-size: small; display: flex; flex-direction: column; gap: 12px;">
                                    <!-- <div style="display: flex; align-items: center;" class="imgSection" v-if="showLogo">
                                                <img style="height: 64px;" :src="host + setting.logo" alt="" />
                                            </div> -->

                                    <div>
                                        <h6>Quotation from:</h6>
                                        <h5>{{ setting.title }}</h5>
                                        <div style="display: flex; flex-direction: column; gap: 2px;">
                                            <div style="display: flex; align-items: center; font-weight: 500;">
                                                <span>
                                                    {{ setting.email }}
                                                </span>
                                            </div>

                                            <div style="display: flex; align-items: center; font-weight: 500;">
                                                <span>
                                                    {{ setting.account_details.website }}
                                                </span>
                                            </div>

                                            <div style="display: flex; align-items: center; font-weight: 500;">
                                                <span>
                                                    {{ setting.phone }}
                                                </span>
                                            </div>

                                            <div style="display: flex; align-items: center; font-weight: 500;">
                                                <span>
                                                    {{ setting.address }}
                                                </span>
                                            </div>

                                            <div v-if="setting.account_details.bin" style="display: flex; flex-direction: row; gap: 4px;">
                                                <span style="font-weight: bold;">BIN:</span>
                                                <span style="font-weight: 400;">{{ setting.account_details.bin }}</span>
                                            </div>

                                            <div v-if="setting.account_details.tin" style="display: flex; flex-direction: row; gap: 4px;">
                                                <span style="font-weight: bold;">TIN:</span>
                                                <span style="font-weight: 400;">{{ setting.account_details.tin }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Quotation Info -->
                                <div class="invoice-info" style="font-size: small;">
                                    <h5>
                                        Quotation #{{ quotationData.quotation_id }}
                                    </h5>
                                    <p style="font-weight: 500;">
                                        Quotation Date:
                                        {{ parseDate(quotationData.quotation_date) }}
                                    </p>
                                    <p style="font-weight: 500; margin-top: -12px;">
                                        Due date: {{ parseDate(quotationData.due_date) }}
                                    </p>
                                </div>
                            </div>

                            <div style="display: flex; justify-content: space-between;">
                                <!-- Client Info -->
                                <div class="client-info" style="font-size: small;">
                                    <h6>Quotation to:</h6>
                                    <h5 v-if="quotationData.client.additional_info">
                                        {{ quotationData.client.additional_info.company.name }}
                                    </h5>
                                    <a v-if="quotationData.client.additional_info" :href="`mailto:${quotationData.client.additional_info.company.email}`">
                                        {{ quotationData.client.additional_info.company.email }}
                                    </a>
                                    <span v-if="quotationData.client_address" style="width: 75%; max-width: 65ch; margin-top: 4px; margin-bottom: 4px;">
                                        {{ quotationData.client_address }}
                                    </span>
                                    <span v-if="quotationData.billing_address" style="width: 75%; max-width: 65ch; display: flex; flex-direction: column;">
                                        <span style="font-weight: bold;">Billing Address:</span>
                                        <span>{{ quotationData.billing_address }}</span>
                                    </span>
                                    <span v-if="quotationData.client.additional_info" style="width: 75%; max-width: 65ch; display: flex; flex-direction: row; gap: 4px; margin-top: 4px; margin-bottom: 4px;">
                                        <span style="font-weight: bold;">BIN:</span>
                                        <span>{{ quotationData.client.additional_info.company.bin }}</span>
                                    </span>
                                    <span v-if="quotationData.client.additional_info" style="width: 75%; max-width: 65ch; display: flex; flex-direction: row; gap: 4px;">
                                        <span style="font-weight: bold;">TIN:</span>
                                        <span>{{ quotationData.client.additional_info.company.tin }}</span>
                                    </span>
                                </div>

                                <!-- Payment Info -->
                                <div class="invoice-info" style="font-size: small;">
                                    <div style="display: flex; align-items: flex-start;">
                                        <div style="display: flex; flex-direction: row; gap: 4px;" v-if="quotationData.project.title">
                                            <span class="font-weight-bold" style="font-size: 15px;">Project: </span>
                                            <span style="font-size: 15px;">{{ quotationData.project.title }}</span>
                                        </div>
                                        <div style="display: flex; flex-direction: row; gap: 4px;" v-else>
                                            <span class="font-weight-bold" style="font-size: 15px;">Project: </span>
                                            <span style="font-size: 15px;">N/A</span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <!-- <DataTable :value="JSON.parse(quotationData.details)" responsiveLayout="scroll"></DataTable> -->
                            <!-- Item table -->

                            <div class="table-container mt-4" v-if="quotationData.details!=null">
                                <div class="table-responsive datatable-vue">
                                    <b-table show-empty :items="parsedDetails" :fields="fields">
                                        <template #cell(name)="row">
                                            <h6 style="min-width: 150px;" v-if="row.item.checkLabel">{{ row.item.name }}</h6>
                                            <p style="min-width: 150px;" v-else>{{ row.item.name }}</p>
                                        </template>

                                        <template #cell(rate)="row">
                                            <div v-if="row.item.rate!== null">
                                                <p v-if="row.item.rate == 0"></p>
                                                <p v-else>{{ row.item.rate }}</p>
                                            </div>
                                        </template>

                                        <template #cell(amount)="row">
                                            <div v-if="row.item.amount!== null">
                                                <p v-if="row.item.amount == 0"></p>
                                                <p v-else>{{ row.item.amount }}</p>
                                            </div>
                                        </template>

                                        <template #cell(description)="row">
                                            <div v-if="row.item.description !== null">
                                                <p style="width: 400px; word-break: break-all;">{{ truncateText(row.item.description, 45) }}</p>
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </div>

                            <div style="width: 100%; display: flex; justify-content: end;">
                                <!-- Bank Info -->
                                <!-- <div class="client-info" style="font-size: small;">
                                        <h6>Account Details</h6>
                                        <span style="width: 75%; min-width: 65ch; font-weight: 400; font-size: 14px;">
                                            Account Name: {{ setting.account_details.account_name }}
                                        </span>
                                        <span style="width: 75%; max-width: 65ch; font-weight: 400; font-size: 14px;">
                                            Account Number: {{ setting.account_details.account_number }}
                                        </span>
                                        <span style="width: 75%; max-width: 65ch; font-weight: 400; font-size: 14px;">
                                            Bank Name: {{ setting.account_details.bank_name }}
                                        </span>
                                        <span style="width: 75%; max-width: 65ch; font-weight: 400; font-size: 14px;">
                                            Branch: {{ setting.account_details.branch_name }}
                                        </span>
                                    </div> -->

                                <!-- Payment Info -->
                                <div style="font-size:small;">
                                    <div class="total-item" style="min-width: 25vh; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;">
                                        <h6 style="font-weight: 500;">Subtotal:</h6>
                                        <h6 style="text-align: right;">
                                            <span style="font-weight: 500;">
                                                {{ quotationData.total }}
                                            </span>
                                            {{ quotationData.currency }}
                                        </h6>
                                    </div>
                                    <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;" v-if="quotationData.vat">
                                        <h6 style="font-weight: 500;">Vat ({{ quotationData.vat ? quotationData.vat : 0 }}%):</h6>
                                        <h6 style="text-align: right;">
                                            {{vatAmount }}
                                        </h6>
                                    </div>
                                    <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;" v-if="quotationData.tax">
                                        <h6 style="font-weight: 500;">Tax ({{ quotationData.tax ? quotationData.tax : 0 }}%):</h6>
                                        <h6 style="text-align: right;">
                                            {{TaxAmount }}
                                        </h6>
                                    </div>
                                    <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;" v-if="quotationData.discount">
                                        <h6 style="font-weight: 500;">Discount ({{ quotationData.discount ? quotationData.discount : 0 }}%)</h6>
                                        <h6 style="text-align: right;">
                                            {{DiscountAmount }}
                                        </h6>
                                    </div>
                                    <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;">
                                        <h6 style="font-weight: 500;">Total:</h6>
                                        <h6 style="text-align: right; color: brown;">
                                            <span style="font-weight: 500;">
                                                {{ formatNumber(quotationData.subtotal) }}
                                            </span>
                                            {{ quotationData.currency }}
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="other-info-container mt-2" v-if="quotationData.remarks">
                                <h6 style="padding-left: 10px; padding-right: 10px;">Remarks:</h6>
                                <p style="padding: 8px; font-size: small;">
                                    {{ quotationData.remarks }}
                                </p>
                            </div>

                            <div style="display: flex; justify-content: flex-start;">
                                <div style="display: flex; flex-direction: column; align-items: flex-start;">
                                    <img style="height: auto; width: auto; max-height: 100px; max-width: 100px;" :src="host+setting.authorized_signature" alt="" v-if="showSignature == true" />

                                    <p style="border-style: solid; border-bottom: 40px; border-left: 0; border-right: 0; border-width: 1px; border-color: #d1d5db; padding: 2px 8px 4px; margin-top: 12px; font-size: small; align-self: flex-start;">
                                        Authorized signature
                                    </p>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div v-else>
                    <div class="loader-box">
                        <div class="loader-10"></div>
                    </div>
                </div>

            </div>

            <div class="col-xl-4">
                <div class="content-container-attahment w-100">
                    <div class="p-2" v-if="!loading && !error">
                        <div class="d-flex justify-content-between">
                            <h6 class="mb-2">Supporting Documents</h6>
                            <feather type="plus-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-scrollable-add-document v-b-tooltip.hover title="Add Documents"> </feather>
                        </div>

                        <b-table show-empty sticky-header :items="quotationData.quotation_attach" :fields="Attachment" head-variant="light">
                            <template #cell(index)="data">
                               {{ data.index + 1 }}
                            </template>

                            <template #cell(title)="data">
                                <a :href="host+data.item.quotation_attachment" target="_blank"> {{ truncateText(data.item.attach_title, 100) }}</a>
                            </template>
                            <template #cell(action)="data">
                                <div style="display: flex; flex-direction: row; gap:10px">
                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                </div>
                            </template>
                        </b-table>
                    </div>

                    <div class="p-2" v-else>
                        <div class="loader-box">
                            <div class="loader-10"></div>
                        </div>
                    </div>
                </div>

                <div class="mt-4 content-container-attahment w-100">
                    <div class="p-2" v-if="!loading && !error">
                        <div class="d-flex justify-content-between">
                            <h6 class="mb-2">Bank Details</h6>
                        </div>

                        <b-table show-empty sticky-header :items="bank_accounts" :fields="banks" head-variant="light">
                            <template #cell(index)="data">
                               {{ data.index + 1 }}
                            </template>

                            <template #cell(account_number)="data">
                                <router-link :to="'/finance-and-accounts/banking-info/details/'+ data.item.id" class="link-style"><span>{{ data.item.account_number }}</span></router-link>
                            </template>
                        </b-table>
                    </div>
                    <div class="p-2" v-else>
                        <div class="loader-box">
                            <div class="loader-10"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Project Attachment Create -->
    <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
        <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload Documents</label>
                        <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import {
    myHost
} from "../../../_helpers/host";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
export default {
    props: ["id", "download"],

    data() {
        return {
            showPage: true,
            showSignature: false,
            showLogo: true,
            host: myHost.host,
            add_attachment: {
                quotation_attachment: null,
                quotation_id: ''
            },
            fields: [{
                    key: 'name',
                    label: 'Item',
                    sortable: false
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: false
                },
                {
                    key: 'item_quantity',
                    label: 'Quantity',
                    sortable: false
                },
                {
                    key: 'rate',
                    label: 'Unit Price',
                    sortable: false
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    sortable: false
                }
            ],
            parsedDetails: [],
            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                }
            ],
            banks: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'bank_name',
                    label: 'Bank',
                    sortable: true
                },
                {
                    key: 'account_number',
                    label: 'Account No',
                    sortable: true
                }
            ],

            downloadPdf: false,
            showSignature: false,
            showLetterHead: true,
        };
    },

    methods: {
        toggleSignature() {
            this.showSignature = !this.showSignature;
        },
        formatNumber(number) {
            return Number(number.toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits: 2
            });
        },
        toggleLetterHead() {
            this.showLetterHead = !this.showLetterHead;
        },
        toggleLogo() {
            this.showLogo = !this.showLogo;
        },

        DisplaySignature() {
            this.showSignature = true;
        },

        RemoveSignature() {
            this.showSignature = false;
        },

        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },

        parseDetails() {
            try {
                this.parsedDetails = JSON.parse(this.quotationData.details);
            } catch (error) {
                // console.error('Error parsing details:', error);
            }
        },

        printPdf(section) {
            this.downloadPdf = true;

            const printContents = document.getElementById(section).innerHTML;
            const originalContents = document.body.innerHTML;
            const wrappedPrintContents = `<div class="page">${printContents}</div>`;

            document.body.innerHTML = wrappedPrintContents;

            window.print();

            document.body.innerHTML = originalContents;
            location.reload();
        },

        parseDate(date) {
            return new Date(Date.parse(date)).toLocaleDateString("en-US", {
                year: "numeric",
                day: "numeric",
                month: "short",
            });
        },

        editQuotation(id) {
            this.$router.push({
                path: `/finance-and-accounts/finance/quotation/edit/${id}`
            });
        },

        handleAttachOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleAttahmentSubmit()
        },

        handleAttahmentSubmit() {
            this.add_attachment.quotation_id = this.$route.params.id
            this.$store.dispatch("quotation/addQuotationAttachment", {
                attachment: this.add_attachment,
                quotation_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.add_attachment.invoice_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable-add-document')
            })
        },

        deleteAttach(id) {
            this.$store.dispatch("quotation/deleteAttachmentFromQuotation", {
                id: id,
                quotation_id: this.$route.params.id,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        uploadAttachFile(e) {
            this.add_attachment.quotation_attachment = e.target.files[0]
        },
        generatePDF() {
            this.showPage = false;
            const element = this.$refs.printDiv;
            const pdf = new jsPDF('p', 'pt', 'letter');

            // Background and logo image settings
            const backgroundImageUrl = this.showLetterHead ? this.host + this.setting.letterpad : null;
            const logoImageUrl = this.showLogo ? this.host + this.setting.logo : null;

            const backgroundImg = new Image();
            const logoImg = new Image();

            const loadImage = (img, src) => {
                return new Promise((resolve, reject) => {
                    if (!src) {
                        resolve();
                    } else {
                        img.crossOrigin = 'anonymous'; // Ensure CORS is properly set if needed
                        img.src = src;
                        img.onload = () => resolve(img);
                        img.onerror = reject;
                    }
                });
            };

            Promise.all([loadImage(backgroundImg, backgroundImageUrl), loadImage(logoImg, logoImageUrl)]).then((images) => {
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();

                // Content padding and margin
                const contentPaddingTop = 0; // Adjust the top padding as needed
                const contentPaddingBottom = 0; // Adjust the bottom padding as needed
                const contentMargin = 0; // Margin between content and background image

                let positionY = contentPaddingTop; // Start position at the top padding
                let contentHeight = element.offsetHeight;

                const addContent = () => {
                    pdf.html(element, {
                        x: contentMargin,
                        y: positionY + contentMargin,
                        html2canvas: {
                            scale: 0.6,
                            useCORS: true // Use CORS for capturing images
                        },
                        callback: () => {
                            const filename = `${this.quotationData.quotation_id}.pdf`;
                            pdf.save(filename);
                            this.showPage = true;
                        },
                        margin: [80, 0, 80, 0],
                    });
                };

                const addImages = () => {
                    if (this.showLetterHead) {
                        pdf.addImage(backgroundImg, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    }
                    if (this.showLogo) {
                        const logoWidth = logoImg.width; // Use the natural width of the logo image
                        const logoHeight = 30; // Use the natural height of the logo image
                        const logoMargin = 50; // Right margin for the logo
                        const logoX = pdfWidth - logoWidth - logoMargin;
                        const logoY = 18; // Margin from top
                        pdf.addImage(logoImg, 'JPEG', logoX, logoY, logoWidth, logoHeight);
                    }
                };

                addContent();
                addImages();

                let pageCount = Math.ceil(contentHeight / 794);
                for (let i = 1; i < pageCount; i++) {
                    pdf.addPage();
                    addImages();
                }
            }).catch((error) => {
                console.error('Error loading images:', error);
            });
        },

 

        showPDF() {
            this.showPage = false;
            const element = this.$refs.printDiv;
            const pdf = new jsPDF('p', 'pt', 'letter');

            // Background and logo image settings
            const backgroundImageUrl = this.showLetterHead ? this.host + this.setting.letterpad : null;
            const logoImageUrl = this.showLogo ? this.host + this.setting.logo : null;

            const backgroundImg = new Image();
            const logoImg = new Image();

            const loadImage = (img, src) => {
                return new Promise((resolve, reject) => {
                    if (!src) {
                        resolve();
                    } else {
                        img.crossOrigin = 'anonymous'; // Ensure CORS is properly set if needed
                        img.src = src;
                        img.onload = () => resolve(img);
                        img.onerror = reject;
                    }
                });
            };

            Promise.all([loadImage(backgroundImg, backgroundImageUrl), loadImage(logoImg, logoImageUrl)]).then((images) => {
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();

                // Content padding and margin
                const contentPaddingTop = 0; // Adjust the top padding as needed
                const contentPaddingBottom = 0; // Adjust the bottom padding as needed
                const contentMargin = 0; // Margin between content and background image

                let positionY = contentPaddingTop; // Start position at the top padding
                let contentHeight = element.offsetHeight;

                const addContent = () => {
                    pdf.html(element, {
                        x: contentMargin,
                        y: positionY + contentMargin,
                        html2canvas: {
                            scale: 0.6,
                            useCORS: true // Use CORS for capturing images
                        },
                        callback: () => {
                            // const filename = `${this.invoiceData.invoice_id}.pdf`;

                            // Convert the PDF to a Blob and create an object URL
                            const pdfBlob = pdf.output('blob');
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Open the PDF in a new tab
                            window.open(pdfUrl);

                            this.showPage = true;
                        },
                        margin: [80, 0, 80, 0],
                    });
                };

                const addImages = () => {
                    if (this.showLetterHead) {
                        pdf.addImage(backgroundImg, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    }
                    if (this.showLogo) {
                        const logoWidth = logoImg.width; // Use the natural width of the logo image
                        const logoHeight = 30; // Use the natural height of the logo image
                        const logoMargin = 50; // Right margin for the logo
                        const logoX = pdfWidth - logoWidth - logoMargin;
                        const logoY = 18; // Margin from top
                        pdf.addImage(logoImg, 'JPEG', logoX, logoY, logoWidth, logoHeight);
                    }
                };

                addContent();
                addImages();

                let pageCount = Math.ceil(contentHeight / 794);
                for (let i = 1; i < pageCount; i++) {
                    pdf.addPage();
                    addImages();
                }
            }).catch((error) => {
                console.error('Error loading images:', error);
            });
        },

    },

    computed: {
        ...mapState({
            quotationData: (state) => state.quotation.quotation_detail,
            setting: state => state.organization.organization_setting,
            bank_accounts: state => state.bank.active_accounts,

            loading: state => state.quotation.submitLoading,
            error: state => state.quotation.submitError

        }),

        additionalStyle() {
            return this.showLetterHead ? {
                'background-image': `url(${this.host}${this.setting.letterpad})`,
                'background-size': '100% 100%',
                'background-position': 'center center',
                'background-repeat': 'no-repeat',
            } : {};
        },
        vatAmount() {
            if (this.quotationData.vat !== 0) {
                let total = this.quotationData.total + this.quotationData.vat * (this.quotationData.total / 100)
                let gtotal = total - this.quotationData.total

                return Math.round((gtotal + Number.EPSILON) * 100) / 100;
            }
            return 0;
        },

        TaxAmount() {
            if (this.quotationData.tax !== 0) {
                let total = this.quotationData.total + this.quotationData.tax * (this.quotationData.total / 100)
                let gtotal = total - this.quotationData.total

                return Math.round((gtotal + Number.EPSILON) * 100) / 100;
            }
            return 0;
        },
        DiscountAmount() {
            if (this.quotationData.discount !== 0) {
                let total = this.quotationData.total + this.quotationData.discount * (this.quotationData.total / 100)
                let gtotal = total - this.quotationData.total

                return Math.round((gtotal + Number.EPSILON) * 100) / 100;
            }
            return 0;
        },
    },

    mounted() {
        this.$store.dispatch("quotation/fetchQuotationDetail", this.$route.params.id);
        this.item_details = (JSON.stringify(this.quotationData.details))
        this.parseDetails();
        this.$store.dispatch("organization/fetchOrganizationSettings")
        this.$store.dispatch("bank/fetchActiveBankAccounts")
    },

    watch: {
        'quotationData.details': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.parseDetails();
            }
        }
    }
};
</script>

<style scoped>
.content-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px 60px;
}

.imgSection {
    position: absolute;
    top: 32px;
    right: 58px;
}

.content-container-attahment {
    background-color: white;
    padding: 10px;
}

.invoice-header,
.client-section {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 48px;
}

.company-info,
.client-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: start;
}

.payment-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    align-items: flex-end;
}

.total-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.invoice-info {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: end;
}

.logo-img {
    object-fit: cover;
    object-position: center;
}

.table-container {
    width: 100%;
    font-size: small;
    color: black;
}

.total-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
    margin-bottom: 32px;
}

.other-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* @media print {
                html,
                body {
                    width: 210mm;
                    height: 297mm;
                }

                .content-container {
                    margin: 0;
                    width: initial;
                    min-height: initial;
                }
            } */

.invoice-container {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 32px;
}

@media print {
    .invoice-container {
        display: none;
        visibility: hidden;
    }

    .signature-btn {
        display: none;
        visibility: hidden;
    }

    body {
        font-size: 12pt;
        line-height: 1.5;

    }

}

@media screen and (max-width: 639px) {

    .content-container {
        background-color: white;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 20px 20px;
    }

    .invoice-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 20px;
    }

    .invoice-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: center;

    }

    .payment-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;
    }

    .client-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 20px;
        margin-bottom: 20px;
    }

    .total-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-self: flex-end;
        margin-top: 20px;
        margin-bottom: 32px;
    }
}

#print {
    width: 1020px;
    padding: 0in 0in;
    box-sizing: border-box;

}
</style>
