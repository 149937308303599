import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PUNCH_UPDATE',
    smsDelete : 'PUNCH_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
      
        time_sheet_latest:{},
        time_sheet_summary_count:null,
        time_sheet_summary:[],
        work_sheet_summary:[],
        detail_time_sheet_summary:[],
        user_info:{},
        user_name:'',

        submitLoading: false,
        submitError: false,
        submitSuccess:false,

        ...store
    },

    mutations : {
        ...mutations,
        
        SET_LATEST_TIME_SHEET(state, timeSheet) {
            state.time_sheet_latest = timeSheet;
        },

        SET_TIME_SHEET_SUMMARY(state, {results,pg_count}) {
            state.time_sheet_summary = results;
            state.time_sheet_summary_count = pg_count;
        },

        SET_WORK_TIME_SHEET_SUMMARY(state, {data,user_info}) {
            state.work_sheet_summary = data;
            state.user_info = user_info
        },

        SET_DETAIL_TIME_SHEET_SUMMARY(state, {data,user_info}) {
            state.detail_time_sheet_summary = data;
            state.user_info = user_info
        },
        
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        } 
    },

    actions: {
        async addAttende ({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.employee_punch, {location:payload}, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchLatestTimeSheet')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchLatestTimeSheet ({ commit,dispatch }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.latest_timesheet, config);
                let response = result.data
                commit('SET_LATEST_TIME_SHEET', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
           finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchTimeSheetSummary ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.status) params.append('status_param', payload.status);
                if (payload.department) params.append('department', payload.department);
                if (payload.designation) params.append('designation', payload.designation);
                if (payload.employee) params.append('employee', payload.employee);
                
                let url = V1API.time_sheet_summary + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results,pg_count} = result.data

                commit('SET_TIME_SHEET_SUMMARY', {results,pg_count});
                return result

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchWorkTimeSheetByID ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.work_history_time_sheet_by_id+payload, config);
                const {data,user_info} = result.data

                commit('SET_WORK_TIME_SHEET_SUMMARY', {data,user_info});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchDetailsTimeSheetByDate_ID ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.details_time_sheet_by_date_and_id+payload.id+'/'+payload.date, config);
                const {data,user_info} = result.data
                commit('SET_DETAIL_TIME_SHEET_SUMMARY', {data,user_info});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },

    getters: {}
}
