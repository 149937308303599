<template>
<div>
    <Breadcrumbs main="My Workspace" title="Grievance" />

    <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Grievance Subject</label>
                                            <input class="form-control" type="text" placeholder="Enter grievance subject" v-model="grievance.subject">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Date</label>
                                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="grievance.date"></datepicker>
                                        </div>
                                    </div>
                                </div>

                                

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Status</label>
                                            <select class="form-control digits" v-model="grievance.status" style="height: 42px;">
                                                <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Refer To</label>
                                            <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" 
                                            label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Grievance Details</label> 
                                            <b-form-textarea
                                                id="textarea-auto-height"
                                                placeholder="Enter asset details..."
                                                v-model="grievance.details"
                                                rows="4"
                                                max-rows="13"
                                            ></b-form-textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mb-0">
                                            <button @click="onSubmit()" class="btn btn-success mr-3" :disabled="isDisabled">Submit</button>
                                           
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>


</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from 'vue-multiselect';
import {
    mapState
} from 'vuex';
import moment from "moment";
export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            isDisabled:false,
            assignedMembers: [],
            grievance: {
                subject: '',
                details: '',
                date: null,
                status: '',
                refering_to:[],
               
            },
            statuses: ['OPEN', 'CLOSE'],

            employees: ['Samiur Rahman', 'Mustakim Hasan', 'Siam Mahmud'],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState({     
            user_list: state => state.employee.employee_list,
        }),
    },

    mounted() {
    
        this.$store.dispatch("employee/fetchEmployeeList");

    },

    methods: {
        onSubmit(){
            this.isDisabled = true
            if(this.grievance.date !== null) {
                this.grievance.date = moment(String(this.grievance.date)).format("YYYY-MM-DD");
            }

            const idArray = this.assignedMembers.map(item => this.grievance.refering_to.push(
                item.id
            ));
          
            this.$store.dispatch("grievance/addGrievance", this.grievance).then(response => {
                console.log(response)
                if(response.code == 200) {
                    
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.isDisabled = false,
                    this.grievance.subject = '',
                    this.grievance.details = '',
                    this.grievance.date = null,
                    this.grievance.status = '',
                    this.assignedMembers = [],
                    this.grievance.refering_to = [],
                  
                    setTimeout(() => {
                            this.$router.push('/my-workspace/grievance') 
                    }, 3000)
                   
                    
                } else {
                    this.isDisabled = false
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
        


   
    }
};
</script>
