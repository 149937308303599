import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";

const keyList = {
    smsUpdate: 'PAYMENT_UPDATE',
    smsDelete: 'PAYMENT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state: {
        requisition_payments: [],
        requisition_payments_pg: [],
        requisition_payments_count: null,

        bill_payments: [],
        bill_payments_pg: [],
        bill_payments_count: null,
        
        invoice_payments: [],
        invoice_payments_pg: [],
        invoice_payments_count: null,

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations: {
        ...mutations,

        SET_REQUISITION_PAYMENT_LIST(state, data) {
            state.requisition_payments = data;
        },

        SET_REQUISITION_PAYMENT_LIST_WITHPG(state, {results, pg_count}){
            state.requisition_payments_pg = results;
            state.requisition_payments_count = pg_count;
        },

        SET_BILL_PAYMENT_LIST(state, data) {
            state.bill_payments = data;
        },

        SET_BILL_PAYMENT_LIST_WITH_PG(state, {results, pg_count}) {
            state.bill_payments_pg = results;
            state.bill_payments_count = pg_count;
        },

        SET_INVOICE_PAYMENT_LIST(state, data) {
            state.invoice_payments = data;
        },

        SET_INVOICE_PAYMENT_LIST_WITH_PG(state, {results, pg_count}) {
            state.invoice_payments_pg = results;
            state.invoice_payments_count = pg_count;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        // REQUISITION PAYMENT
        async fetchRequisitionPayments ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            try {
                let result = await axios.get(V1API.requisition_payments, config);
                const data = result.data.data
                commit('SET_REQUISITION_PAYMENT_LIST', data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchRequisitionPaymentsWithPG ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            try {
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.raised_by) params.append('raised_by', payload.raised_by);
                if(payload.approved_by) params.append('approved_by',payload.approved_by);
           
                let url = V1API.requisition_payments_withpg + '?' + params.toString();
                let result = await axios.get(url, config);
                const {results, pg_count} = result.data
                commit('SET_REQUISITION_PAYMENT_LIST_WITHPG', {results, pg_count});
                return result
            
            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        
        async updateRequisitionPaymentStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            try {
                let response = await axios.get(V1API.requisition_payemnt_list_status_change+ payload.id + "/" + payload.status_for_change, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch ('fetchRequisitionPaymentsWithPG', {currentPage:payload.currentPage, perPage:payload.perPage, status:payload.status, raised_by:payload.raised_by, approved_by:payload.approved_by, search_term:payload.search_term})

                if(payload.detail_id){
                    dispatch("requisition/fetchRequisitionPaymentByRequisition", payload.detail_id, { root: true })
                }
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteRequisitionPayments ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            let response = await axios.delete(V1API.delete_requisition_payments + payload.id, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch ('fetchRequisitionPaymentsWithPG', {currentPage:payload.currentPage, perPage:payload.perPage, status:payload.status, raised_by:payload.raised_by, approved_by:payload.approved_by, search_term:payload.search_term})

            return response
        },

        // BILL PAYMENT
        async fetchBillPayments ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            try {
                let result = await axios.get(V1API.bill_payments, config);
                const data = result.data.data
                commit('SET_BILL_PAYMENT_LIST', data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        // BILL PAYMENT WITH PAGINATION
        async fetchBillPaymentsWithPG ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            try {
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.raised_by) params.append('raised_by', payload.raised_by);
                if(payload.approved_by) params.append('approved_by',payload.approved_by);
           
                let url = V1API.bill_payments_pg + '?' + params.toString();
                let result = await axios.get(url, config);
                const {results, pg_count} = result.data
                commit('SET_BILL_PAYMENT_LIST_WITH_PG', {results, pg_count});
                return result;

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateBillPaymentStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            try {
                let response = await axios.get(V1API.bill_payment_list_status_change + payload.id + "/" + payload.status_for_change, config).then(result => {
                    let data = result.data
                    return data
                })
                dispatch('fetchBillPaymentsWithPG', {currentPage:payload.currentPage, perPage:payload.perPage, status:payload.status, raised_by:payload.raised_by, approved_by:payload.approved_by, search_term:payload.search_term})

                if(payload.detail_id){
                    dispatch("bill/fetchBillPaymentByBill", payload.detail_id, { root: true })
                }
              
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteBillPayments ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            let response = await axios.delete(V1API.delete_bill_payments + payload.id, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchBillPaymentsWithPG', {currentPage:payload.currentPage, perPage:payload.perPage, status:payload.status, raised_by:payload.raised_by, approved_by:payload.approved_by, search_term:payload.search_term});

            return response
        },

        // INVOICE PAYMENT
        async fetchInvoicePayments ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            try {
                let result = await axios.get(V1API.invocie_payments, config); 
                const data = result.data.data
                commit('SET_INVOICE_PAYMENT_LIST', data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        // INVOICE PAYMENT WITH PAGINATION
        async fetchInvoicePaymentsWithPG ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            try {
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.raised_by) params.append('raised_by', payload.raised_by);
                if(payload.approved_by) params.append('approved_by',payload.approved_by);
           
                let url = V1API.invocie_payments_pg + '?' + params.toString();
                let result = await axios.get(url, config);
                const {results, pg_count} = result.data
                commit('SET_INVOICE_PAYMENT_LIST_WITH_PG', {results, pg_count});
                return result;

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateInvoicePaymentStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            try {
                let response = await axios.get(V1API.invoice_payment_list_status_change + payload.id + "/" + payload.status_for_change, config).then(result => {
                    let data = result.data
                    return data
                })
                dispatch('fetchInvoicePaymentsWithPG', {currentPage:payload.count, perPage:payload.perPage, status:payload.status, raised_by:payload.raised_by, approved_by:payload.approved_by, search_term:payload.search_term})

                if(payload.detail_id){
                    dispatch("invoice/fetchInvoicePaymentByInvoice", payload.detail_id, { root: true })
                }

                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteInvoicePayments ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            };

            let response = await axios.delete(V1API.delete_invoice_payments + payload.id, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchInvoicePaymentsWithPG', {currentPage:payload.currentPage, perPage:payload.perPage, status:payload.status, raised_by:payload.raised_by, approved_by:payload.approved_by, search_term:payload.search_term});

            return response
        }
    },

    getters: {}
}
