import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'ACCOUNT_UPDATE',
    smsDelete : 'ACCOUNT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        accountCategories: [],
        accountCategories_withpg: [],
        accountCategories_count: null,
        accountGroups: [],
        accountGroupsPG: [],
        accountGroups_count: null,
        accountLedgers: [],
        accountLedgersPG: [],
        accountLedgers_count: null,
        ledgerDetails: {},
        balanceDetails: {},
        balanceSheetElements:[],
        noteDetails: {},
        incomeStatementCalculationDetails: {},
        accountTransactions: [],
        accountTransactionsPG: [],
        accountTransactions_count: null,
        journalTransactions: [],
        trialBalance: [],
        trialBalanceSummary: {},
        incomeStructure: {},
        accountNotes: [],
        accountNotesPG: [],
        accountNotes_count: false,

        accountLabels: [],
        accountLabelsPG: [],
        accountLabels_count: false,

        accountTransactionsByLabel: [],
        accountTransactionsPGByLabel: [],
        accountTransactionsPGByLabelDetails: {},
        accountTransactionsByLabel_count: null,


        incomeStatement: [],

        balanceSheet: [],
        balanceSheetDetails:{},

        accountCategoryLoading: false,
        accountCategoryError: false,

        accountGroupLoading:false,
        accountGroupError:false,

        accountTrialLoading: false,
        accountTrialError: false,

        balanceSheetLoading: false,
        balanceSheetError: false,

        incomeStatementLoading: false,
        incomeStatementError: false,

        incomeStructureLoading: false,
        incomeStructureError: false,

        accountLedgerLoading: false,
        accountLedgerError: false,

        accountLabelsLoading: false,
        accountLabelsError: false,

        accountTransactionsByLabelLoading: false,
        accountTransactionsPGByLabelError:false,


        accountNoteLoading: false,
        accounNoteError: false,

        accountTransactionsLoading: false,
        accountTransactionsError: false,

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,

        SET_ACCOUNT_CATEGORY(state, item) {
            state.accountCategories = item;
        },

        SET_ACCOUNT_CATEGORY_WITHPG(state, {results, pg_count}){
            state.accountCategories_withpg = results
            state.accountCategories_count = pg_count
        },

        SET_ACCOUNT_TRANSACTIONS(state, item) {
            state.accountTransactions = item;
        },

        SET_ACCOUNT_TRANSACTIONS_WITH_PG(state, {results, pg_count}) {
            state.accountTransactionsPG = results;
            state.accountTransactions_count = pg_count;
        },

        SET_LEDGER_BALANCE(state, item) {
            state.balanceDetails = item;
        },

        SET_JOURNAL_TRANSACTIONS(state, item) {
            console.log(item)
            state.journalTransactions = item;
        },

        SET_ACCOUNT_GROUP(state, item) {
            state.accountGroups = item;
        },

        SET_ACCOUNT_GROUP_WITH_PG(state, {results, pg_count}) {
            state.accountGroupsPG = results;
            state.accountGroups_count = pg_count;
        },

        SET_ACCOUNT_LEDGER(state, item) {
            state.accountLedgers = item;
        },

        SET_ACCOUNT_LEDGER_WITH_PG(state, {results, pg_count}) {
            state.accountLedgersPG = results;
            state.accountLedgers_count = pg_count;
        },


        SET_ACCOUNT_LABELS(state, item) {
            state.accountLabels = item;
        },

        SET_ACCOUNT_LABELS_WITH_PG(state, {results, pg_count}) {
            state.accountLabelsPG = results;
            state.accountLabels_count = pg_count;
        },
        SET_ACCOUNT_TRANSACTIONS_BY_LABELS_WITH_PG(state, {results, details, pg_count}) {
            state.accountTransactionsPGByLabel = results;
            state.accountTransactionsPGByLabelDetails = details;
            state.accountTransactionsByLabel_count = pg_count;
        },

        SET_BALANCE_SHEET_ELEMENTS(state, item) {
            state.balanceSheetElements = item;
        },
        SET_ACCOUNT_LEDGER_DETAILS(state, item) {
            state.ledgerDetails = item;
        },

        SET_ACCOUNT_NOTE_DETAILS(state, item) {
            state.noteDetails = item;
        },

        ACCOUNT_CATEGORY_LOADING(state, loading) {
            state.accountCategoryLoading = loading;
        },

        ACCOUNT_CATEGORY_ERROR(state, error) {
            state.accountCategoryError = error;
        },

        SET_ACCOUNT_TRIAL_BALANCE(state, item) {
            state.trialBalance = item;
        },

        SET_ACCOUNT_TRIAL_BALANCE_SUMMARY(state, item) {
            state.trialBalanceSummary = item;
        },

        ACCOUNT_TRIAL_BALANCE_LOADING(state, loading) {
            state.accountTrialLoading = loading;
        },

        ACCOUNT_TRIAL_BALANCE_ERROR(state, error) {
            state.accountTrialError = error;
        },

        SET_ACCOUNT_NOTES(state, item) {
            state.accountNotes = item;
        },

        SET_ACCOUNT_NOTES_WITH_PG(state, {results, pg_count}) {
            state.accountNotesPG = results
            state.accountNotes_count = pg_count
        },

        ACCOUNT_NOTE_LOADING(state, loading) {
            state.accountNoteLoading = loading;
        },

        ACCOUNT_NOTE_ERROR(state, error) {
            state.accounNoteError = error;
        },

        SET_INCOME_STRUCTURE_ELEMENTS(state, item) {
            state.incomeStructure = item;
        },

        INCOME_STRUCTURE_ELEMENTS_LOADING(state, loading) {
            state.incomeStructureLoading = loading;
        },

        INCOME_STRUCTURE_ELEMENTS_ERROR(state, error) {
            state.incomeStructureError = error;
        },

        SET_INCOME_STATEMENT_ELEMENTS(state, item) {
            state.incomeStatement = item;
        },

        SET_INCOME_STATEMENT_CALCULATION(state, item) {
            state.incomeStatementCalculationDetails = item;
        },

        INCOME_STATEMENT_LOADING(state, loading) {
            state.incomeStatementLoading = loading;
        },

        INCOME_STATEMENT_ERROR(state, error) {
            state.incomeStatementError = error;
        },

        SET_BALANCE_SHEET(state, item) {
            state.balanceSheet = item;
        },

        SET_BALANCE_SHEET_CALCULATION(state, item) {
            state.balanceSheetDetails = item;
        },

        BALANCE_SHEET_LOADING(state, loading) {
            state.balanceSheetLoading = loading;
        },

        BALANCE_SHEET_ERROR(state, error) {
            state.balanceSheetError = error;
        },

        ACCOUNT_GROUP_LOADING(state, loading) {
            state.accountGroupLoading = loading;
        },

        ACCOUNT_GROUP_ERROR(state, error) {
            state.accountGroupError = error;
        },

        ACCOUNT_LEDGER_LOADING(state, loading) {
            state.accountLedgerLoading = loading;
        },

        ACCOUNT_LEDGER_ERROR(state, error) {
            state.accountLedgerError = error;
        },


        ACCOUNT_LABELS_LOADING(state, loading) {
            state.accountLabelsLoading = loading;
        },

        ACCOUNT_LABELS_ERROR(state, error) {
            state.accountLabelsError = error;
        },

        ACCOUNT_TRANSACTION_BY_LABELS_LOADING(state, loading) {
            state.accountTransactionsByLabelLoading = loading;
        },

        ACCOUNT_TRANSACTION_BY_LABELS_ERROR(state, error) {
            state.accountTransactionsPGByLabelError = error;
        },

        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateAccountCategory({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_account_category + "/" + payload.id, payload.category, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountCategoryWithPagination', {currentPage:payload.page,perPage:payload.perPage,search_term:payload.search_term})
            return response
        },
        async updateSerialCategory({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.edit_account_serial_category + "/" + payload.id+"/"+payload.serial, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountCategoryWithPagination', {currentPage:payload.page,perPage:payload.perPage,search_term:payload.search_term})
            return response
        },

        async deleteAccountCategory({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_account_category + "/" + payload.id, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountCategoryWithPagination', {currentPage:payload.page,perPage:payload.perPage,search_term:payload.search_term})

            return response
        },

        async fetchAccountCategory({commit}) {
            commit('ACCOUNT_CATEGORY_LOADING', true);
            commit('ACCOUNT_CATEGORY_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_category, config);
                let response = result.data

                commit('SET_ACCOUNT_CATEGORY', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_CATEGORY_ERROR', true);
            }
            finally {
                commit('ACCOUNT_CATEGORY_LOADING', false);
            }

        },

        async fetchAccountCategoryWithPagination({commit},payload) {
            commit('ACCOUNT_CATEGORY_LOADING', true);
            commit('ACCOUNT_CATEGORY_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let params = new URLSearchParams();

                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);


                let url = V1API.get_account_category_pagination + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results,pg_count} = result.data

                commit('SET_ACCOUNT_CATEGORY_WITHPG', {results,pg_count});
                return result

            } catch (error) {
                commit('ACCOUNT_CATEGORY_ERROR', true);
            }
            finally {
                commit('ACCOUNT_CATEGORY_LOADING', false);
            }

        },

        async fetchAccountTrialBalance({commit},payload) {
            commit('ACCOUNT_TRIAL_BALANCE_LOADING', true);
            commit('ACCOUNT_TRIAL_BALANCE_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();


                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('date_type', payload.status);


                let url = V1API.get_trial_balance + '?' + params.toString();
                let result = await axios.get(url, config);
                let response = result.data
                console.log(response.data)
                commit('SET_ACCOUNT_TRIAL_BALANCE', response.data);
                commit('SET_ACCOUNT_TRIAL_BALANCE_SUMMARY', response.summary);
                return response

            } catch (error) {
                commit('ACCOUNT_TRIAL_BALANCE_ERROR', true);
            }
            finally {
                commit('ACCOUNT_TRIAL_BALANCE_LOADING', false);
            }

        },

        async addAccountCategory({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.create_account_category, payload.category, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAccountCategoryWithPagination', {currentPage:payload.page,perPage:payload.perPage,search_term:payload.search_term})
                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateAccountGroup({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_account_group + "/" + payload.group.id, payload.group, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountGroupWithPagination', {perPage:payload.perPage, currentPage:payload.currentPage, search_term:payload.search_term, category:payload.category});
            return response
        },

        async deleteAccountGroup({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_account_group + "/" + payload.id, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountGroupWithPagination', {perPage:payload.perPage, currentPage:payload.currentPage, search_term:payload.search_term, category:payload.category});

            return response
        },

        async fetchAccountGroup({commit}) {
            commit('ACCOUNT_GROUP_LOADING', true);
            commit('ACCOUNT_GROUP_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_group, config);
                let response = result.data

                commit('SET_ACCOUNT_GROUP', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_GROUP_ERROR', true);
            } finally {
                commit('ACCOUNT_GROUP_LOADING', false);
            }
        },

        async fetchAccountGroupWithPagination({commit}, payload) {
            commit('ACCOUNT_GROUP_LOADING', true);
            commit('ACCOUNT_GROUP_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();

                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.category) params.append('pre_defined', payload.category);

                let url = V1API.get_account_group_pagination + '?' + params.toString();
                let result = await axios.get(url, config);
                const {results, pg_count} = result.data
                commit('SET_ACCOUNT_GROUP_WITH_PG', {results, pg_count});
                return result

            } catch (error) {
                commit('ACCOUNT_GROUP_ERROR', true);
            } finally {
                commit('ACCOUNT_GROUP_LOADING', false);
            }
        },

        async fetchAccountGroupByCategory({commit}, payload) {
            commit('ACCOUNT_GROUP_LOADING', true);
            commit('ACCOUNT_GROUP_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_group_by_category + "/" + payload, config);
                let response = result.data

                commit('SET_ACCOUNT_GROUP', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_GROUP_ERROR', true);
            }
            finally {
                commit('ACCOUNT_GROUP_LOADING', false);
            }
        },

        async addAccountGroup({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.create_account_group, payload.group, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAccountGroupWithPagination', {perPage:payload.perPage, currentPage:payload.currentPage, search_term:payload.search_term, category:payload.category})
                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateAccountLedger({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_account_ledger + "/" + payload.ledger.id, payload.ledger, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountLedgerWithPG', {currentPage: payload.currentPage, perPage:payload.perPage, search_term:payload.search_term, group:payload.group, income_statement:payload.income_statement, note:payload.note});
            return response
        },

        async deleteAccountLedger({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_account_ledger + "/" + payload.id, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountLedgerWithPG', {currentPage: payload.currentPage, perPage:payload.perPage, search_term:payload.search_term, group:payload.group, income_statement:payload.income_statement, note:payload.note});

            return response
        },

        async fetchAccountLedger({commit}) {
            commit('ACCOUNT_LEDGER_LOADING', true);
            commit('ACCOUNT_LEDGER_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_ledger, config);
                let response = result.data

                commit('SET_ACCOUNT_LEDGER', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_LEDGER_ERROR', true);
            } finally {
                commit('ACCOUNT_LEDGER_LOADING', false);
            }
        },

        async fetchAccountLedgerWithPG({commit}, payload) {
            commit('ACCOUNT_LEDGER_LOADING', true);
            commit('ACCOUNT_LEDGER_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();

                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.note) params.append('note', payload.note);
                if (payload.income_statement) params.append('income_statement', payload.income_statement);
                if (payload.group) params.append('group', payload.group);

                let url = V1API.get_account_ledger_pagination + '?' + params.toString();
                let result = await axios.get(url, config);
                const {results, pg_count} = result.data
                commit('SET_ACCOUNT_LEDGER_WITH_PG', {results, pg_count});
                return result

            } catch (error) {
                commit('ACCOUNT_LEDGER_ERROR', true);
            } finally {
                commit('ACCOUNT_LEDGER_LOADING', false);
            }
        },

        async fetchAccountLedgerDetails({commit},payload) {
            commit('ACCOUNT_LEDGER_LOADING', true);
            commit('ACCOUNT_LEDGER_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_ledger_details+"/"+payload, config);
                let response = result.data

                commit('SET_ACCOUNT_LEDGER_DETAILS', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_LEDGER_ERROR', true);
            }
            finally {
                commit('ACCOUNT_LEDGER_LOADING', false);
            }
        },
        async fetchAccountLedgerByGroup({commit}, payload) {
            commit('ACCOUNT_LEDGER_LOADING', true);
            commit('ACCOUNT_LEDGER_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                payload = {
                    group: payload
                }
                let result = await axios.post(V1API.get_account_ledger_by_group, payload, config);
                let response = result.data

                commit('SET_ACCOUNT_LEDGER', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_LEDGER_ERROR', true);
            }
            finally {
                commit('ACCOUNT_LEDGER_LOADING', false);
            }
        },

        async addAccountLedger({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.create_account_ledger, payload.ledger, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAccountLedgerWithPG', {currentPage: payload.currentPage, perPage:payload.perPage, search_term:payload.search_term, group:payload.group, income_statement:payload.income_statement, note:payload.note})
                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async recordTransaction({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.record_transaction, payload.transaction, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAccountTransactionsWithPG', {currentPage:payload.currentPage, perPage:payload.perPage, start_date:payload.start_date, end_date:payload.end_date, search_term:payload.search_term, status:payload.status})
                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchAccountTransactions({commit}) {
            commit('ACCOUNT_CATEGORY_LOADING', true);
            commit('ACCOUNT_CATEGORY_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_transactions, config);
                let response = result.data
                commit('SET_ACCOUNT_TRANSACTIONS', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_CATEGORY_ERROR', true);
            } finally {
                commit('ACCOUNT_CATEGORY_LOADING', false);
            }
        },

        async fetchAccountTransactionsWithPG({commit}, payload) {
            commit('ACCOUNT_CATEGORY_LOADING', true);
            commit('ACCOUNT_CATEGORY_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();

                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.status) params.append('date_type', payload.status);


                let url = V1API.get_transactions_pagination + '?' + params.toString();
                let result = await axios.get(url, config);
                const {results, pg_count} = result.data
                commit('SET_ACCOUNT_TRANSACTIONS_WITH_PG', {results, pg_count})
                return result

            } catch (error) {
                commit('ACCOUNT_CATEGORY_ERROR', true);
            } finally {
                commit('ACCOUNT_CATEGORY_LOADING', false);
            }
        },


        async updateAccountTransactions ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.update_transactions_note+payload.id,
                        {
                             note:payload.note,
                             entry_date:payload.entry_date,
                             transaction_label:payload.transaction_label,

                        },config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchAccountTransactionsWithPG', {currentPage:payload.currentPage, perPage:payload.perPage, start_date:payload.start_date, end_date:payload.end_date, search_term:payload.search_term, status:payload.status});
                }
            )
            return response
        },

        async fetchAccountTransactionsByLedger({commit}, payload) {
            commit('ACCOUNT_CATEGORY_LOADING', true);
            commit('ACCOUNT_CATEGORY_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_transactions_by_ledger + "/" + payload, config);
                let response = result.data

                commit('SET_ACCOUNT_TRANSACTIONS', response.data);
                let response_payload={
                    credit: response.credit,
                    debit: response.debit,
                    balance: response.balance
                }
                console.log(response_payload)
                commit('SET_LEDGER_BALANCE',response_payload);
                return response

            } catch (error) {
                commit('ACCOUNT_CATEGORY_ERROR', true);
            } finally {
                commit('ACCOUNT_CATEGORY_LOADING', false);
            }
        },

        async fetchAccountTransactionsByJid ({ commit },payload) {
            commit('ACCOUNT_CATEGORY_LOADING', true);
            commit('ACCOUNT_CATEGORY_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_transactions_by_jid+"/"+payload, config);
                let response = result.data
                commit('SET_JOURNAL_TRANSACTIONS', response.data);
                return response
            } catch(error) {
                console.log(error)
                commit('ACCOUNT_CATEGORY_ERROR', true);
            } finally {
                commit('ACCOUNT_CATEGORY_LOADING', false);
            }
        },

        async addLedgerToELement({dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.post(V1API.income_structure_add_ledger + "/" + payload.slug, payload, config).then(result => {
                let data = result.data
                return data
            })
            dispatch('fetchIncomeStructure');
            return response
        },

        async deleteLedgerToELement({ dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.post(V1API.income_structure_delete_ledger + "/" + payload.slug, payload, config).then(result => {
                let data = result.data
                return data
            })
            dispatch('fetchIncomeStructure');
            return response
        },

        async fetchIncomeStructure({commit}) {
            commit('INCOME_STRUCTURE_ELEMENTS_LOADING', true);
            commit('INCOME_STRUCTURE_ELEMENTS_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.income_structure, config);
                let response = result.data
                commit('SET_INCOME_STRUCTURE_ELEMENTS', response.data);
                return response

            } catch (error) {
                commit('INCOME_STRUCTURE_ELEMENTS_ERROR', true);
            }
            commit('INCOME_STRUCTURE_ELEMENTS_LOADING', false);
        },

        async fetchIncomeStructureList({commit}) {
            commit('INCOME_STRUCTURE_ELEMENTS_LOADING', true);
            commit('INCOME_STRUCTURE_ELEMENTS_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.income_structure_list, config);
                let response = result.data
                commit('SET_INCOME_STRUCTURE_ELEMENTS', response.data);
                return response

            } catch (error) {
                commit('INCOME_STRUCTURE_ELEMENTS_ERROR', true);
            }
            commit('INCOME_STRUCTURE_ELEMENTS_LOADING', false);
        },

        async fetchIncomeStatement({commit},payload) {
            commit('INCOME_STATEMENT_LOADING', true);
            commit('INCOME_STATEMENT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();


                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('date_type', payload.status);


                let url = V1API.income_statement_calculated + '?' + params.toString();
                let result = await axios.get(url, config);
                let response = result.data
                commit('SET_INCOME_STATEMENT_ELEMENTS', response.data);
                commit('SET_INCOME_STATEMENT_CALCULATION', response.calculations);
                return response



            } catch (error) {
                commit('INCOME_STATEMENT_ERROR', true);
            }
            finally {
                commit('INCOME_STATEMENT_LOADING', false);
            }
        },

        async fetchBalanceSheet({commit},payload) {
            commit('BALANCE_SHEET_LOADING', true);
            commit('BALANCE_SHEET_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();


                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('date_type', payload.status);


                let url = V1API.balance_sheet + '?' + params.toString();
                let result = await axios.get(url, config);
                let response = result.data
                commit('SET_BALANCE_SHEET', response.data);

                return response


            } catch (error) {
                commit('BALANCE_SHEET_ERROR', true);
            }
            finally {
                commit('BALANCE_SHEET_LOADING', false);
            }
        },

        async updateAccountNote({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_account_note + "/" + payload.id, payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountNote');
            return response
        },

        async deleteAccountNote({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_account_note + "/" + payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountNote');

            return response
        },

        async fetchAccountNote({commit}) {
            commit('ACCOUNT_NOTE_LOADING', true);
            commit('ACCOUNT_NOTE_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_note, config);
                let response = result.data
                commit('SET_ACCOUNT_NOTES', response.data);
                return result

            } catch (error) {
                commit('ACCOUNT_NOTE_ERROR', true);
            } finally {
                commit('ACCOUNT_NOTE_LOADING', false);
            }
        },

        async fetchAccountNoteWithPG({commit}, payload) {
            commit('ACCOUNT_NOTE_LOADING', true);
            commit('ACCOUNT_NOTE_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();

                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.balance_sheet) params.append('balance_sheet', payload.balance_sheet);

                let url = V1API.get_account_note_pagination + '?' + params.toString();
                let response = await axios.get(url, config);
                const {results, pg_count} = response.data
                commit('SET_ACCOUNT_NOTES_WITH_PG', {results, pg_count});
                return response

            } catch (error) {
                commit('ACCOUNT_NOTE_ERROR', true);
            } finally {
                commit('ACCOUNT_NOTE_LOADING', false);
            }
        },

        async fetchAccountNoteDetails({commit},payload) {
            commit('ACCOUNT_NOTE_LOADING', true);
            commit('ACCOUNT_NOTE_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_note_details+"/"+payload, config);
                let response = result.data

                commit('SET_ACCOUNT_NOTE_DETAILS', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_NOTE_ERROR', true);
            }
            finally {
                commit('ACCOUNT_NOTE_LOADING', false);
            }
        },

        async addAccountNote({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.create_account_note, payload, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAccountNote')
                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchBalanceSheetElements({commit}) {
            commit('ACCOUNT_NOTE_LOADING', true);
            commit('ACCOUNT_NOTE_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.balance_sheet_element_structure, config);
                let response = result.data

                commit("SET_BALANCE_SHEET_ELEMENTS", response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_NOTE_ERROR', true);
            }
            finally {
                commit('ACCOUNT_NOTE_LOADING', false);
            }
        },
        // New Label Part

        async updateTransactionLabel({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_account_label + "/" + payload.id, payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchTransactionLabelWithPagination', {perPage:payload.perPage, currentPage:payload.currentPage, search_term:payload.search_term});
            return response
        },

        async deleteTransactionLabel({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_account_label + "/" + payload.id, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchTransactionLabelWithPagination', {perPage:payload.perPage, currentPage:payload.currentPage, search_term:payload.search_term});

            return response
        },

        async fetchTransactionLabel({commit}) {
            commit('ACCOUNT_LABELS_LOADING', true);
            commit('ACCOUNT_LABELS_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_label, config);
                let response = result.data

                commit('SET_ACCOUNT_LABELS', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_LABELS_ERROR', true);
            } finally {
                commit('ACCOUNT_LABELS_LOADING', false);
            }
        },

        async fetchTransactionLabelWithPagination({commit}, payload) {
            commit('ACCOUNT_LABELS_LOADING', true);
            commit('ACCOUNT_LABELS_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();

                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);


                let url = V1API.get_account_label_pagination + '?' + params.toString();
                let result = await axios.get(url, config);
                const {results, pg_count} = result.data
                commit('SET_ACCOUNT_LABELS_WITH_PG', {results, pg_count});
                return result

            } catch (error) {
                commit('ACCOUNT_LABELS_ERROR', true);
            } finally {
                commit('ACCOUNT_LABELS_LOADING', false);
            }
        },
        async fetchTransactionsBylabelWithPagination({commit}, payload) {
            commit('ACCOUNT_LABELS_LOADING', true);
            commit('ACCOUNT_LABELS_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let params = new URLSearchParams();

                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.status) params.append('date_type', payload.status);


                let url = V1API.get_account_label_details_pagination +'/'+payload.label_id+ '?' + params.toString();
                let result = await axios.get(url, config);
                const {results, details, pg_count} = result.data
                commit('SET_ACCOUNT_TRANSACTIONS_BY_LABELS_WITH_PG', {results, details, pg_count});
                return result

            } catch (error) {
                commit('ACCOUNT_LABELS_ERROR', true);
            } finally {
                commit('ACCOUNT_LABELS_LOADING', false);
            }
        },
        async addTransactionLabel({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.create_account_label, payload, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchTransactionLabelWithPagination', {perPage:payload.perPage, currentPage:payload.currentPage, search_term:payload.search_term});

                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },


    },





    getters: {}
}
