<template>
<div>
    <Breadcrumbs main="HR" title="Employee" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="width: 100%">
                        <div style="max-width: fit-content;">
                            <router-link to="/hr/organization/employee/create">
                                <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                    <i class="fa fa-light fa-plus"></i>
                                    <h7>Add New Employee</h7>
                                </button>
                            </router-link>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 20px;">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                        <feather type="filter"></feather>
                                        <h7>Filter</h7>
                                    </div>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="selectedEmployee || selectedDepartment || selectedDesignation || start_date || end_date">
                                        <feather type="x"></feather>
                                        <h7>Clear All</h7>
                                    </div>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDesignation">
                                        <b-form-select v-model="selectedEmployee" :options="formattedEmployee"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="employee" :fields="tablefields" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(username)="data">
                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                            <router-link :to="`/hr/organization/employee/details/${data.item.id}`" class="link-style">{{data.item.user_name}}</router-link>
                                            <small>
                                                <span style="font-size: medium;">
                                                    <b-badge pill variant="dark">{{data.item.username}}</b-badge>
                                                </span>
                                            </small>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <div>
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteDesignation(data.item.id)" title="Delete"></feather>
                                            </div>

                                            <div @click="editEmployee(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>

                                            <div @click="EmergencyDetails(data.item)">
                                                <feather type="alert-octagon" size="20px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Emergency Details" v-b-modal.modal-prevent-closing-emergency></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this Employee?</div>
    </b-modal>

    <!-- Emergency Detail Modal -->
    <b-modal id="modal-prevent-closing-emergency" ref="modal" title="Emergency Contact Information" hide-footer>
        <form ref="form" @submit.stop.prevent="handleEditSubmit">

            <div class="row">
                <div class="col-sm-6">
                    <b-form-group label="Relation" label-for="name-input">
                        <b-form-input v-model="emergency_details.relation" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <b-form-group label="Name" label-for="name-input">
                        <b-form-input v-model="emergency_details.name" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <b-form-group label="Phone" label-for="name-input">
                        <b-form-input v-model="emergency_details.contact_number" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <b-form-group label="Email" label-for="name-input">
                        <b-form-input v-model="emergency_details.email" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <b-form-group label="Address" label-for="name-input">
                <b-form-textarea id="textarea-rows" placeholder="" rows="4" v-model="emergency_details.address" disabled></b-form-textarea>
            </b-form-group>

        </form>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';

export default {
    components: {
        Datepicker
    },

    data() {
        return {
            filterShow: false,
            filter: null,
            start_date: null,
            end_date: null,
            selectedDepartment: '',
            selectedDesignation: '',
            selectedEmployee: '',
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'username',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'additional_info.department.name',
                    label: 'Department',
                    sortable: true
                },
                {
                    key: 'additional_info.designation.title',
                    label: 'Designation',
                    sortable: true
                },
                {
                    key: 'additional_info.joining_date',
                    label: 'Joining Date',
                    sortable: true
                },
                {
                    key: 'additional_info.address',
                    label: 'Address',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            designation: {
                title: '',
                department: '',
            },
            edit_designation: {
                id: '',
                title: '',
                department: ''
            },
            emergency_details: {
                id: '',
                name: '',
                contact_number: '',
                email: '',
                relation: '',
                address: ''
            },
            delete_value: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            initialLoad: true,
            isModifyingDate: false,

        };
    },

    computed: {
        ...mapState({
            employee: state => state.employee.employee_list_pg,
            count: state => state.employee.employee_list_pg_count,
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation,
            loading: state => state.employee.submitLoading,
            error: state => state.employee.submitError,
        }),

        formattedEmployee() {
            return [{
                    value: '',
                    text: 'Select Employee',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [{
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [{
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        }
    },

    watch: {
        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/hr/organization/employee',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    },
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    employee: this.selectedEmployee,
                    department: this.selectedDepartment,
                    designation: this.selectedDesignation
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/hr/organization/employee',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    },
                });
                this.perPage = newperPage
                this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    employee: this.selectedEmployee,
                    department: this.selectedDepartment,
                    designation: this.selectedDesignation
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        start_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");

                        this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            employee: this.selectedEmployee,
                            department: this.selectedDepartment,
                            designation: this.selectedDesignation
                        }).then(() => {
                            this.totalRows = this.count;
                            this.initialLoad = false;
                        });
                    } else {
                        this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            employee: this.selectedEmployee,
                            department: this.selectedDepartment,
                            designation: this.selectedDesignation
                        }).then(() => {
                            this.totalRows = this.count;
                            this.initialLoad = false;
                        });
                    }
                }

            }

        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");

                        this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            employee: this.selectedEmployee,
                            department: this.selectedDepartment,
                            designation: this.selectedDesignation
                        }).then(() => {
                            this.totalRows = this.count;
                            this.initialLoad = false;
                        });
                    } else {
                        this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            search_term: this.filter,
                            employee: this.selectedEmployee,
                            department: this.selectedDepartment,
                            designation: this.selectedDesignation
                        }).then(() => {
                            this.totalRows = this.count;
                            this.initialLoad = false;
                        });
                    }

                }

            }

        },

        selectedDepartment(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
                this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    employee: this.selectedEmployee,
                    department: this.selectedDepartment,
                    designation: this.selectedDesignation
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        selectedDesignation(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
                this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    employee: this.selectedEmployee,
                    department: this.selectedDepartment,
                    designation: this.selectedDesignation
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        filter(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    employee: this.selectedEmployee,
                    department: this.selectedDepartment,
                    designation: this.selectedDesignation
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        selectedEmployee(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    employee: this.selectedEmployee,
                    department: this.selectedDepartment,
                    designation: this.selectedDesignation
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        }
    },

    mounted() {
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("designation/fetchDesignations");
        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {

                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitial();
            } else {

                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitial();
            }
        }

    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        },
        EmergencyDetails(type) {
            this.emergency_details.id = type.id
            this.emergency_details.name = type.additional_info.emergency_contact.emergency_name ? type.additional_info.emergency_contact.emergency_name : null
            this.emergency_details.contact_number = type.additional_info.emergency_contact.emergency_phone ? type.additional_info.emergency_contact.emergency_phone : null
            this.emergency_details.relation = type.additional_info.emergency_contact.emergency_relation ? type.additional_info.emergency_contact.emergency_relation : null
            this.emergency_details.email = type.additional_info.emergency_contact.emergency_email ? type.additional_info.emergency_contact.emergency_email : null
            this.emergency_details.address = type.additional_info.emergency_contact.emergency_address ? type.additional_info.emergency_contact.emergency_address : null
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        clearFilter() {
            this.filterShow = false,
                this.filter = null,
                this.start_date = null,
                this.end_date = null,
                this.selectedDepartment = '',
                this.selectedDesignation = '',
                this.selectedEmployee = ''
        },

        // resetModal() {
        //     this.designation.title = ''
        //     this.designation.department = ''
        // },

        // handleOk(bvModalEvent) {
        //     bvModalEvent.preventDefault()
        //     this.handleSubmit()

        // },

        // handleSubmit() {

        //     this.$store.dispatch("designation/addDesignation", this.designation).then(response => {

        //         if (response.code == 200) {
        //             Swal.fire({
        //                 title: "Success!",
        //                 text: response.response,
        //                 icon: "success"
        //             });

        //             this.designation.title = ''
        //             this.designation.department = ''

        //         } else {
        //             Swal.fire({
        //                 title: "Error!",
        //                 text: response.response,
        //                 icon: "error"
        //             });
        //         }
        //     })

        //     this.$nextTick(() => {
        //         this.$bvModal.hide('modal-prevent-closing')
        //     })
        // },

        editEmployee(type) {
            this.$router.push(`/hr/organization/employee/edit/${type.id}`)
        },

        deleteDesignation(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("employee/deleteEmployee", {
                item: this.delete_value,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
