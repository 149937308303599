<template>
    <div>
        <Breadcrumbs main="HR" title="Compliance Create" />

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">

                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>Title</label>
                                            <input class="form-control" type="text" placeholder="Enter compiance title" v-model="compliance.title">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>Compliance</label> 
                                            <!-- <b-form-textarea
                                                id="textarea-auto-height"
                                                rows="3"
                                                max-rows="8"
                                                placeholder="Enter compliance details"
                                                v-model="compliance.details"
                                            ></b-form-textarea> -->

                                            <vue-editor  v-model="compliance.details"></vue-editor>
                                            <!-- <ckeditor :editor="editor" placeholder="Enter compliance details..." v-model="compliance.details"></ckeditor> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mb-0">
                                            <button @click="onSubmit" class="btn btn-success mr-3" :disabled="isdisabled">Create</button>
                                            <router-link to="/hr/compliance">
                                                <button class="btn btn-danger" href="#">Go Back</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { VueEditor } from "vue2-editor";
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        VueEditor
    },

    data(){
        return {
            isdisabled:false,
            editor: ClassicEditor,
            compliance: {
                title: '',
                details: ''
            }
        };
    },

    methods: {
        onSubmit() {
            this.isdisabled = true
            this.$store.dispatch("compliance/addCompliance", this.compliance).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.isdisabled = false,
                    this.compliance.title = '',
                    this.compliance.details = ''
                    setTimeout(() => {
                        this.$router.push('/hr/compliance')
                    }, 2000)
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
