<template>
<div>
    <div v-if="!loading && !error">
        <Breadcrumbs main="My Workspace" title="EOD Details" />
        <!-- Employee Details  -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">
                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                    <div>
                                        <b-badge variant="primary" class="mb-4">
                                            <h7 style="text-align: center;font-size: 14px;">EOD Details</h7>
                                        </b-badge>
                                    </div>
                                    <div style="display: flex;">
                                        <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(details)" title="Reply"></feather>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label>Reported By</label>

                                            <input class="form-control" type="text" placeholder="Reported By" v-model="fullName" disabled>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label>EOD ID</label>
                                            <input class="form-control" type="text" placeholder="Enter grievance subject" v-model="details.eod_id" disabled>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label>Reported Date</label>
                                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="details.created_date" disabled></datepicker>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Report Details</label>
                                            <b-form-textarea id="textarea-auto-height" placeholder="Enter asset details..." v-model="details.report" rows="4" disabled></b-form-textarea>
                                        </div>
                                    </div>
                                </div>

                                <div style="display: flex; flex-direction: row; gap: 10px;">
                                    <h7>EOD Status:</h7>
                                    <div>
                                        <span v-if="details.status == 'PENDING'">
                                            <b-badge pill variant="warning">{{details.status }}</b-badge>
                                        </span>
                                        <span v-if="details.status == 'APPROVED'">
                                            <b-badge pill variant="success">{{ details.status }}</b-badge>
                                        </span>
                                        <span v-if="details.status == 'DISCUSSION'">
                                            <b-badge pill variant="primary">{{ details.status }}</b-badge>
                                        </span>
                                        <!-- @click="getStatus(details.status, details.id)" -->
                                        <!-- v-b-modal.modal-prevent-closing-status v-b-tooltip.hover title="Update Status" -->
                                    </div>
                                </div>

                                <!-- <h6 class="mt-4 mb-2">Already Refered</h6>
                            <div class="row">
                                <div class="text-center col-md-2 w-full" v-for="user in details.reported_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                                    <div class="w-100 mt-2 badge-style">
                                        <h7>{{ user.first_name}}</h7>
                                        <b-icon icon="x" style="color: white;"></b-icon>
                                    </div>
                                </div>
                            </div> -->

                                <h6 class="mt-4 mb-3" v-if="details.reported_to.length > 0">Already Refered</h6>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div v-for="user in details.reported_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete" style="width: fit-content;">
                                            <div class="mb-2 badge-style">
                                                <h7>{{ user.first_name}} {{ user.last_name}}</h7>
                                                <b-icon icon="x" style="color: white;"></b-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </px-card>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="mb-3">Completed Tasks</h6>
                            <div v-for="task in details.completed_tasks" :key="task.id" class="task-item mb-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <h6 class="mb-0">{{  details.completed_tasks.indexOf(task) + 1 }}. </h6>
                                        <h6 class="mb-0 ml-2">{{ task.title }}</h6>
                                    </div>
                                    <b-badge variant="success" v-if="task.complete == true">Completed</b-badge>
                                    <b-badge variant="danger" v-if="task.complete !== true" v-b-tooltip.hover :title="`Deadline: ${task.end_date}`">Incomplete</b-badge>
                                </div>
                                <div class="mt-2 ml-4" v-for="subtask in task.subtasks" :key="subtask.id">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-flex align-items-center">
                                            <feather type="corner-down-right" size="18px" style="color: #51BB25; cursor: pointer;" v-b-tooltip.hover :title="`Completed: ${subtask.completed_date}`"></feather>
                                            <p class="mb-0 ml-2">{{ subtask.title }}</p>
                                        </div>
                                        <!-- <b-badge variant="success" v-if="task.complete == true">Completed</b-badge>
                                    <b-badge variant="danger" v-if="task.complete !== true">Incomplete</b-badge> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="mb-3">Completed Tickets</h6>
                            <div v-for="ticket in details.completed_tickets" :key="ticket.id" class="task-item mb-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <h6 class="mb-0">{{ details.completed_tickets.indexOf(ticket) + 1 }}.</h6>
                                        <h6 class="mb-0 ml-2">{{ ticket.subject }}</h6>
                                    </div>
                                    <span v-if="ticket.priority == 'LOW'">
                                        <b-badge pill variant="info">{{ ticket.priority }}</b-badge>
                                    </span>
                                    <span v-if="ticket.priority == 'MEDIUM'">
                                        <b-badge pill variant="warning">{{ ticket.priority }}</b-badge>
                                    </span>
                                    <span v-if="ticket.priority == 'HIGH'">
                                        <b-badge pill variant="danger">{{ ticket.priority }}</b-badge>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 
            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="mb-3">Completed Tasks</h6>
                            <div v-for="task in details.completed_tasks" :key="task.id" class="my-2">
                                <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 12px; margin-bottom: 6px;">
                                    <div style="display: flex; gap: 8px;">
                                        <h6>{{ details.completed_tasks.indexOf(task) + 1 }}.</h6>
                                        <h6>{{ task.title }}</h6>
                                    </div>
                                    <b-badge variant="danger">{{ task.end_date }}</b-badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="mb-3">Completed Tickets</h6>
                            <div v-for="ticket in details.completed_tickets" :key="ticket.id">
                                <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 12px; margin-bottom: 6px;">
                                    <div style="display: flex; gap: 8px;">
                                        <h6>{{ details.completed_tickets.indexOf(ticket) + 1 }}.</h6>
                                        <h6>{{ ticket.subject }}</h6>
                                    </div>

                                    <span v-if="ticket.priority == 'LOW'">
                                        <b-badge pill variant="info">{{ ticket.priority }}</b-badge>
                                    </span>
                                    <span v-if="ticket.priority == 'MEDIUM'">
                                        <b-badge pill variant="warning">{{ ticket.priority }}</b-badge>
                                    </span>
                                    <span v-if="ticket.priority == 'HIGH'">
                                        <b-badge pill variant="danger">{{ ticket.priority }}</b-badge>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <!-- EOD Reply Modal -->
        <b-modal id="modal-scrollable" ref="modal" title="EOD Reply" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <h6>Already Refered</h6>

            <div class="row">
                <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                    <div class="w-100 mb-2 badge-style">
                        <h7>{{ user.first_name}}</h7>
                        <b-icon icon="x" style="color: white;"></b-icon>
                    </div>
                </div>
            </div>

            <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
            <div style="margin-top: 30px;" v-if="eod_reply.length>0">
                <h6>Replies</h6>
                <div class="task-board-comments">

                    <div v-for="reply in eod_reply" :key="reply.id" class="chatbox">
                        <div class="chat">
                            <div class="avatar">
                                <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                            </div>
                            <div class="message">
                                <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                                <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                                <p class="msg" v-html="convertUrls(reply.reply)"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="task-board-comment-box" style="margin-top: 10px;">
                <div style="display: flex; gap: 12px;">
                    <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleReplySubmit()">
                    <button @click="handleReplySubmit()" class="btn btn-primary">Send</button>
                </div>
            </div>
        </b-modal>

        <!--  Allocate Delete Modal -->
        <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refere?</div>
        </b-modal>

        <!-- Update Status -->
        <b-modal id="modal-prevent-closing-status" ref="modal" title="Update EOD Status" @show="resetModal" @hidden="resetModal" @ok="handleStatusOk">
            <form ref="form" @submit.stop.prevent="handleStatusSubmit">
                <b-form-group label="Status" label-for="name-input">
                    <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
                </b-form-group>
            </form>
        </b-modal>
    </div>
    <div v-else style="width: 100%;">
        <div class="container-fluid">
            <div class="row flex-md-row-reverse">
                <div class="loader-wrapper">
                    <div class="loader-index">
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from 'vue-multiselect';
import {
    mapState
} from 'vuex';
import moment from "moment";
import {
    myHost
} from '../../../../_helpers/host';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            update_eod_id: '',
            statuses: ['PENDING', 'APPROVED', 'DISCUSSION'],
            selectedStatus: null,
            assignedMembers: [],

            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',
        };
    },

    computed: {
        ...mapState({
            details: state => state.eod.eod_detail,
            loading: state => state.eod.eodLoading,
            error: state => state.eod.eodError,
            eod_reply: state => state.eod.eod_reply,
            user_list: state => state.employee.employee_list,

        }),
        fullName() {
            if (this.details.reported_by) {
                return this.details.reported_by.first_name + ' ' + this.details.reported_by.last_name;
            } else {
                return '';
            }
        },
    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("eod/fetchEODtDetail", this.$route.params.id)
    },
    watch: {
        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        },
    },

    methods: {
        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },
        getStatus(status, id) {
            this.update_eod_id = id

            this.selectedStatus = status
        },
        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.reported_to
            this.$store.dispatch("eod/fetchEODReply", this.my_reply.id)
        },
        deletetAllocation(id) {
            this.temp_id = id
        },
        handleAllocationDeleteOk() {
            this.$store.dispatch("eod/EODDeleteRefer", {
                id: this.$route.params.id,
                refer_id: this.temp_id,
                detail: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },
        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("eod/EODAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId,
                detail: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })

        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleReplySubmit()

        },

        handleReplySubmit() {

            this.$store.dispatch("eod/addEODReply", {
                eod: this.my_reply.id,
                reply: this.my_reply.reply
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.my_reply.id = '',
                        this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },
        handleStatusOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleStatusSubmit()
        },

        handleStatusSubmit() {
            this.$store.dispatch("eod/updateEODStatus", {
                id: this.update_eod_id,
                status: this.selectedStatus
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.selectedStatus = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-status')
            })
        },

    }
};
</script>

<style scoped>
.task-item {
    padding: 8px;
    border-bottom: 1px solid #e9ecef;
}

.task-item:last-child {
    border-bottom: none;
}

.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
}

/* .chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chatbox .chat {
    display: flex;
    gap: 15px;
}

.chatbox img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.chatbox .msg {
    background: #fff0f0;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
} */

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
