import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        label: [],
        lead: [],
        lead_count:null,
        projectLoading: false,
        projectError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_LABEL (state, label) {
            state.label = label;
        },

        SET_LEAD (state, {results,pg_count}) {
            state.lead = results;
            state.lead_count = pg_count;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateLead ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_lead + payload.item.id, payload.item, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch ('fetchLead',{perPage:payload.perPage,currentPage:payload.page,search_term:payload.search_term,contacted:payload.contacted,label:payload.label})
            return response
        },

        async deleteLead ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_lead + payload.item, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchLead',{perPage:payload.perPage,currentPage:payload.page,search_term:payload.search_term,contacted:payload.contacted,label:payload.label})
            
            return response
        },

        async fetchLead ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.contacted) params.append('contacted', payload.contacted);
                if (payload.label) params.append('label', payload.label);
                
               
           
                let url = V1API.get_lead + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results, pg_count} = result.data

                commit('SET_LEAD', {results, pg_count});
                return result
              

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
           
        },

        async addLead ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_lead, payload.item, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchLead',{perPage:payload.perPage,currentPage:payload.page,search_term:payload.search_term,contacted:payload.contacted,label:payload.label})
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addLabel ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_label, {
                    name: payload
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchLabel')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchLabel ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_label, config);
                let response = result.data

                commit('SET_LABEL', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addLabeltoLead ({ commit, dispatch }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.post(V1API.add_label_to_lead + payload.lead, {
                    label_id: payload.label_id
                }, config)

                let response = result.data

                if(payload.labelAdd_delete){
                    dispatch ('fetchLead',{perPage:payload.perPage,currentPage:payload.page,search_term:payload.search_term,contacted:payload.contacted,label:payload.label})
                }
               
               // dispatch ('fetchLabel')

                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
            
        },

        async deleteLabelfromLead ({ commit, dispatch }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.post(V1API.delete_label_from_lead + payload.leadId, {
                    label_id: payload.label_id
                }, config)

                let response = result.data

                if(payload.labelAdd_delete){
                    dispatch ('fetchLead',{perPage:payload.perPage,currentPage:payload.page,search_term:payload.search_term,contacted:payload.contacted,label:payload.label})
                }
                // dispatch ('fetchLabel')

                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
           
        },

        async deleteLabel ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_label + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            // dispatch ('fetchLead')
            dispatch ('fetchLabel')
            
            return response
        }
    },

    getters: {}
}
