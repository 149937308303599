<template>
<div>
    <Breadcrumbs main="Procurement" title="RFP Response Details" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;">
                            <div style="display: flex; align-items: center;">
                                <h5>RFP Details</h5>
                            </div>
                            <div>
                                <!-- <button class="btn btn-primary" type="submit">
                                        <router-link :to="'/procurement/rfp/edit/' + this.$route.params.id" style="display: flex; gap: 12px; align-items: center; color: white;">
                                            <i class="fa fa-light fa-pencil"></i>
                                            <h7>Edit RFP</h7>
                                        </router-link>
                                    </button> -->
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">

                                <div slot="with-padding">
                                    <div class="form theme-form">

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input class="form-control" type="text" placeholder="Enter rfp title" v-model="rfp.title" disabled>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Category</label>
                                                    <select class="form-control digits" v-model="rfp.rfp_category" disabled>
                                                        <option v-for="(item, index) in items" :key="index" :value="item.id">{{ item.title }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Status</label>
                                                    <select class="form-control digits" v-model="rfp.status" disabled>
                                                        <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Deadline</label>
                                                    <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="rfp.deadline" disabled></datepicker>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label>Short Description</label>
                                                    <!-- <textarea class="form-control" id="textarea-auto-height" placeholder="Enter short description..." rows="5" max-rows="8" v-model="rfp.details" disabled></textarea> -->

                                                    <!-- <ckeditor :editor="editor" placeholder="Enter short description..." v-model="rfp.details"></ckeditor> -->
                                                    <vue-editor  v-model="rfp.details"></vue-editor>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="">
                                    <div class="d-flex justify-content-between ">
                                        <h6 class="my-2">Supporting Documents</h6>

                                    </div>

                                    <b-table sticky-header :items="this.rfp.rfp_attachment" :fields="Attachment" head-variant="light">
                                        <template #cell(index)="data">
                                            {{ (currentPage - 1) * perPage + data.index + 1 }}
                                        </template>

                                        <template #cell(title)="data">
                                            <a :href="host+data.item.rfp_attachment" target="_blank">{{ data.item.rfp_attachment }}</a>
                                        </template>

                                    </b-table>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">

                                <div style="display: flex; align-items: center; justify-content: space-between;">
                                    <h5>{{ submission_details.submitted_form.form_name }}</h5>
                                    <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(submission_details)" title="Reply"></feather>
                                </div>

                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <div style="display: flex; flex-direction: column;">
                                                <div class="row">
                                                    <div class="col-md-12 grid-style">
                                                        <div v-for="(fo, index) in submission_details.form_data" :key="index">

                                                            <div>
                                                                <span>{{ fo.data_label }}</span>
                                                                <span>

                                                                    <!-- <b-form-input v-model="fo.data_value" type="text" disabled></b-form-input> -->

                                                                    <div v-if="fo.data_type === 'filefield'">
                                                                        <div @click="redirectToFullUrl(getFullPath(fo))">
                                                                            <b-form-input :value="getFullPath(fo)" type="text" class="clickable-input text-info" readonly></b-form-input>

                                                                        </div>
                                                                    </div>
                                                                    <div v-else-if="fo.data_type === 'textfield'">
                                                                        <div>
                                                                            <b-form-input v-model="fo.data_value" type="text" readonly></b-form-input>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else-if="fo.data_type === 'paragraph'">
                                                                        <div>
                                                                            <textarea class="form-control" id="textarea-auto-height" rows="3" max-rows="8" v-model="fo.data_value" readonly></textarea>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Reply Modal -->
        <b-modal id="modal-scrollable" ref="modal" title="Submission Reply" hide-footer>

            <div style="margin-top: 30px;" v-if="rfp_reply.length>0">
                <h6>Replies</h6>
                <div class="task-board-comments">
                    <div v-for="reply in rfp_reply" :key="reply.id" class="chatbox">
                        <div class="chat">
                            <div class="avatar">
                                <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name" v-if="reply.replier_photo">
                            </div>
                            <div class="message">
                                <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                                <p class="msg" v-html="convertUrls(reply.reply)"></p>
                                <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="task-board-comment-box" style="margin-top: 10px;">
                <div style="display: flex; gap: 12px;">
                    <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleReplySubmit()">
                    <button @click="handleReplySubmit()" class="btn btn-primary">Send</button>
                </div>
            </div>
        </b-modal>

    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    myHost
} from '../../../_helpers/host'
import { VueEditor } from "vue2-editor";

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        VueEditor
    },

    data() {
        return {
            editor: ClassicEditor,
            form_id: null,
            host: myHost.host,
            detail_id: '',
            rfp: {
                title: '',
                status: '',
                deadline: null,
                rfp_category: null,
                details: '',
                rfp_attachment: null
            },
            statuses: ['APPROVED', 'WORKING', 'COMPLETED'],

            form_data: {
                rfp: null,
                form_name: "",

                submission_form: [{
                    data_label: null,
                    data_type: null
                }]
            },

            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },

            ],

            options: [{
                    title: 'Text Field',
                    value: 'textfield'
                },
                {
                    title: 'File Field',
                    value: 'filefield'
                },
                {
                    title: 'Link Field',
                    value: 'textfield'
                },
                {
                    title: 'Paragraph',
                    value: 'paragraph'
                }
            ],

            my_reply: {
                id: '',
                reply: '',

            },
        }
    },

    computed: {
        ...mapState({
            detail: state => state.rfp.rfp_detail,

            submission_details: state => state.rfp.rfp_submission_response,

            rfp_reply: state => state.rfp.rfp_response_reply,

        }),

    },

    mounted() {

        this.$store.dispatch("rfp/fetchRFPSubmissionDetails", this.$route.params.id).then(() => {
            this.$store.dispatch("rfp/fetchRFPDetails", this.submission_details.submitted_form.rfp)
        });

    },

    watch: {
        detail(oldValue, newValue) {
            this.rfp.title = this.detail.title
            this.rfp.status = this.detail.status
            this.rfp.deadline = this.detail.deadline
            this.rfp.rfp_category = this.detail.rfp_category.id
            this.rfp.details = this.detail.details
            this.rfp.rfp_attachment = this.detail.rfp_attach
        },

    },

    methods: {
        getFullPath(fo) {
            return this.host + fo.data_value;
        },
        redirectToFullUrl(fullUrl) {
            window.open(fullUrl, '_blank'); 
           
        },
        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },
        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.$store.dispatch("rfp/fetchRFPResponseReply", this.my_reply.id)
        },

        uploadFile(e, form) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                form.data_value = e.target.result;
            };
        },

        onSubmit() {
            this.form_data.rfp = this.$route.params.id

            this.$store.dispatch("rfp/submitForm", {
                form_data: this.form_data.submission_form,
                form: this.form_id,
                rfp: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    setTimeout(() => this.$router.push('/procurement/rfp/list'), 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        handleReplySubmit() {
            this.$store.dispatch("rfp/addRFPResponseReply", {
                submission: this.my_reply.id,
                reply: this.my_reply.reply,

            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

        },
    }
};
</script>

<style scoped>
.clickable-input {
  border: none;
  outline: none;
  background-color: transparent;
  color: blue; 
  cursor: pointer;

}
.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
