<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Bills" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header" style="max-width: fit-content">
                            <router-link to="/finance-and-accounts/finance/bill/create/new">
                                <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
    
                                    <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                        <i class="fa fa-light fa-plus"></i>
                                        <h7>Add New Bill</h7>
                                    </div>
    
                                </button>
                            </router-link>
                        </div>
    
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
    
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
    
                                </b-col>
    
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
    
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>
    
                                    <template #cell(bill_id)="data">
                                        <span>
                                             <router-link :to="`/finance-and-accounts/finance/bill/${data.item.id}`" class="link-style">
                                            {{ data.item.bill_id }}
                                             </router-link>
                                        </span>
                                    </template>
    
                                    <template #cell(raised_by)="data">
                                        <span v-if="data.item.created_by.username">
                                           
                                                {{ data.item.created_by.username }}
                                            
                                        </span>
                                        <span v-else>
                                            <router-link :to="`/finance-and-accounts/finance/bill/${data.item.id}`" class="link-style">
                                                <p>--</p>
                                            </router-link>
    
                                        </span>
                                    </template>
    
                                    <template #cell(subtotal)="data">
                                        <!-- <span>
                                            {{ data.item.subtotal }} {{ data.item.currency }}
                                        </span> -->
    
                                        <div class="d-flex flex-column" style="width: 150px;">
                                            <span class="font-weight-bold">Total: {{ data.item.subtotal }}</span>
                                            <span><span class="text-success font-weight-bold">Paid:</span> {{ data.item.paid_amount }}</span>
                                            <span><span class="text-danger font-weight-bold">Due:</span> {{ data.item.due_amount }}</span>
                                        </div>
                                    </template>
    
                                    <template #cell(status)="row">
    
                                        <span v-if="row.value == 'APPROVED'">
                                            <b-badge variant="success">{{ row.value }}</b-badge>
                                        </span>
                                        <span v-if="row.value == 'PENDING'">
                                            <b-badge variant="primary">{{ row.value }}</b-badge>
                                        </span>
                                        <span v-if="row.value == 'DUE'">
                                            <b-badge variant="danger">{{ row.value }}</b-badge>
                                        </span>
                                    </template>
    
                                    <template #cell(record)="data">
    
                                       
                                        <div @click="recordPayment(data)">
                                            <b-badge variant="primary" v-if="data.item.due_amount > 0" v-b-modal.modal-record>Record Payment</b-badge>
                                        </div>
    
                                    </template>
    
                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-center @click="deleteBill(data.item.id)" v-b-tooltip.hover title="Delete"></feather>
                                            <router-link :to="`/finance-and-accounts/finance/bill/edit/${data.item.id}`">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                            </router-link>
                                            <!-- <feather type="sidebar" size="20px" style="color: #51bb25; cursor: pointer;" v-b-tooltip.hover title="Bill Payments"></feather> -->
                                        </span>
                                    </template>
                                </b-table>
                            </div>
    
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    
        <!-- Delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this bill?</div>
        </b-modal>
    
        <!-- Record Payment -->
        <b-modal id="modal-record" ref="modal" title="Record Payment" @show="resetModal" @hidden="resetModal" @ok="handleRecordOk">
            <form ref="form" @submit.stop.prevent="handleRecordSubmit">
                <b-form-group label="Amount" label-for="name-input" invalid-feedback="Item is required">
                    <b-form-input id="name-input" v-model="record.paid_amount" placeholder="Enter Amount"></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>
    </template>
    
    <script>
    import {
        mapState
    } from 'vuex';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    
    export default {
        data() {
            return {
                tablefields: [{
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'bill_id',
                        label: 'Bill ID',
                        sortable: true
                    },
                    {
                        key: 'raised_by',
                        label: 'Raised By',
                        sortable: true
                    },
                    {
                        key: 'email',
                        label: 'Email',
                        sortable: true
                    },
                    {
                        key: 'subtotal',
                        label: 'Amount',
                        sortable: true
                    },
                    {
                        key: 'bill_date',
                        label: 'Bill Date',
                        sortable: true
                    },
                    {
                        key: 'due_date',
                        label: 'Due Date',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true
                    },
                    
                ],
    
                record: {
                    paid_amount: '',
                    bill: '',
                },
                bill_due_amount:'',
    
                filter: null,
                delete_value: null,
    
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 100]
            };
        },
    
        computed: {
            ...mapState({
                items: state => state.vendor.bill_list
            }),
    
            sortOptions() {
                return this.tablefields
                    .filter(f => f.sortable)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.key
                        };
                    });
            }
        },
    
        mounted() {
            this.$store.dispatch("vendor/fetchVendorBill")
            this.totalRows = this.items.length;
        },
    
        methods: {
    
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
    
   
        }
    };
    </script>
    