<template>
<div>
    <Breadcrumbs main="Career" title="Applied Jobs" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header" style="max-width: fit-content">
                        <router-link to="/candidate/careers" style="display: flex; gap: 12px; align-items: center; color: white;">
                            <button class="btn btn-primary" type="submit">
                                <i class="fa fa-light fa-plus" style="margin-right: 10px;"></i>
                                <h7>Apply for Job</h7>
                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">

                        <b-row>
                            <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="applicant_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ (currentPage - 1) * perPage + data.index + 1 }}
                                </template>

                                <template #cell(form)="data">
                                    <span v-if="data.item.job.form_generated == true && data.item.job.submitted_by_current_user == false">
                                        <router-link :to="`/job/job-form/${data.item.job.id}`">
                                            <b-button variant="secondary" style="width: 125px;">View Form</b-button>
                                        </router-link>
                                    </span>
                                    <span v-else>
                                        <svg fill="#000000" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px">

                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M745.472 352.256c-8.192-4.096-20.48-4.096-28.672 0L462.848 614.4 344.064 491.52c-8.192-8.192-20.48-8.192-28.672 0s-8.192 20.48-4.096 28.672L446.464 655.36h4.096s4.096 0 4.096 4.096h16.384s4.096 0 4.096-4.096h4.096l270.335-274.432c0-8.192 0-20.48-4.096-28.672z" class="fill-success"></path>
                                                <path d="M512 1024C229.376 1024 0 794.624 0 512S229.376 0 512 0s512 229.376 512 512-229.376 512-512 512zm0-983.04C253.952 40.96 40.96 253.952 40.96 512S253.952 983.04 512 983.04 983.04 770.048 983.04 512 770.048 40.96 512 40.96z" class="fill-success"></path>
                                            </g>
                                        </svg>

                                    </span>
                                </template>
                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
import Multiselect from 'vue-multiselect';
import {
    myHost
} from '../../../_helpers/host';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'job.title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'job.position',
                    label: 'Position',
                    sortable: true
                },
                {
                    key: 'job.category.title',
                    label: 'Category',
                    sortable: true
                },
                {
                    key: 'job.vacancies',
                    label: 'Vacancy',
                    sortable: true
                },
                {
                    key: 'job.deadline',
                    label: 'Deadline',
                    sortable: true
                },

                {
                    key: 'form',
                    label: 'Form',
                    sortable: false
                }
            ],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],

        };
    },

    watch: {

    },

    computed: {
        ...mapState({
            applicant_list: state => state.job.user_applicant_list,
            // user_list: state => state.employee.employee_list,

        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        this.$store.dispatch("job/fetchUserApplicantList").then(() => {
            this.items = this.applicant_list
            this.totalRows = this.items.length;
        });
        // this.$store.dispatch("employee/fetchEmployeeList");
    },

    methods: {
        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;

}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
