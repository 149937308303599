import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate: 'PAYROLL_UPDATE',
    smsDelete: 'PAYROLL_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        payroll_items: [],
        inclusion_items: [],
        deduction_items: [],
        designation_payroll: [],
        payroll_by_designation: {},
        payroll_list: [],
        payroll_details: {},
        payroll_history: [],
        payroll_list_count:null,

        payrollLoading: false,
        payrollError: false,
        payrollDesignationLoading: false,
        payrollDesignationError: false,

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations: {
        ...mutations,

        SET_PAYROLL_ITEMS(state, items) {
            state.payroll_items = items
        },

        SET_DEDUCTIVE_PAYROLL_ITEMS(state, items) {
            state.deduction_items = items
        },

        SET_INCLUSIVE_PAYROLL_ITEMS(state, items) {
            state.inclusion_items = items
        },

        SET_DESIGNATION_PAYROLL(state, items) {
            state.designation_payroll = items
        },

        SET_PAYROLL_BY_DESIGNATION(state, items) {
            state.payroll_by_designation = items
        },

        SET_PAYROLL_LIST(state, {results, pg_count}) {
            state.payroll_list = results,
            state.payroll_list_count = pg_count
        },

        SET_PAYROLL_DETAILS(state, item) {
            state.payroll_details = item
        },

        SET_PAYROLL_HISTORY_LIST(state, item) {
            state.payroll_history = item
        },

        PAYROLL_LOADING (state, loading) {
            state.payrollLoading = loading;
        },

        PAYROLL_ERROR (state, hasError) {
            state.payrollError = hasError;
        },

        PAYROLL_DESIGNATION_LOADING (state, loading) {
            state.payrollDesignationLoading = loading;
        },

        PAYROLL_DESIGNATION_ERROR (state, hasError) {
            state.payrollDesignationError = hasError;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async fetchPayrollItems ({ commit }) {
            commit('PAYROLL_LOADING', true);
            commit('PAYROLL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_payroll_items, config);
                let items = result.data.data

                commit('SET_PAYROLL_ITEMS', items);

            } catch(error) {
                commit('PAYROLL_ERROR', true);
            }
            finally {
                commit('PAYROLL_LOADING', false);
            }
           
        },

        async fetchInclusivePayrollItems ({ commit }) {
            commit('PAYROLL_LOADING', true);
            commit('PAYROLL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_inclusive_payroll_items, config);
                let items = result.data.data

                commit('SET_INCLUSIVE_PAYROLL_ITEMS', items);

            } catch(error) {
                commit('PAYROLL_ERROR', true);
            }
            commit('PAYROLL_LOADING', false);
        },

        async fetchDeductivePayrollItems ({ commit }) {
            commit('PAYROLL_LOADING', true);
            commit('PAYROLL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_deductive_payroll_items, config);
                let items = result.data.data

                commit('SET_DEDUCTIVE_PAYROLL_ITEMS', items);

            } catch(error) {
                commit('PAYROLL_ERROR', true);
            }
            commit('PAYROLL_LOADING', false);
        },

        async addPayrollItems ({commit, dispatch}, payload) {
            commit('PAYROLL_LOADING', true);
            commit('PAYROLL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_payroll_item, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchPayrollItems')
                return response

            }catch (e) {
                commit('PAYROLL_ERROR', true);
            }finally {
                commit('PAYROLL_LOADING', false);
            }
        },

        async updatePayrollItems ({commit, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };

            await performNetworkCall(
                commit,
                keyList.smsUpdate,

                async () => {
                    response = await axios.patch(V1API.edit_payroll_item + payload.id, {
                        name: payload.name,
                        status: payload.status

                    }, config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchPayrollItems');

                }
            )
            return response
        },

        async deletePayrollItem ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_payroll_item + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchPayrollItems');

            return response
        },

        // Designation - Payroll
        async fetchDesignationPayroll ({ commit }) {
            commit('PAYROLL_LOADING', true);
            commit('PAYROLL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_designation_payroll, config);
                let items = result.data.data

                commit('SET_DESIGNATION_PAYROLL', items);

            } catch(error) {
                commit('PAYROLL_ERROR', true);
            }
            finally {
                commit('PAYROLL_LOADING', false);
            }
        },

        async addDesignationPayroll ({commit, dispatch}, payload) {
            commit('PAYROLL_LOADING', true);
            commit('PAYROLL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_designation_payroll, {
                    department: payload.designation_payroll.department,
                    designation: payload.designation_payroll.designation,
                    inclusion: payload.inclusions,
                    deduction: payload.deductions,
                    gross_salary: payload.gross_salary

                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchDesignationPayroll')
                return response

            }catch (e) {
                commit('PAYROLL_ERROR', true);
            }finally {
                commit('PAYROLL_LOADING', false);
            }
        },

        async updateDesignationPayroll ({commit, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };

            await performNetworkCall(
                commit,
                keyList.smsUpdate,

                async () => {
                    response = await axios.patch(V1API.edit_designation_payroll + payload.id, {
                        inclusion: payload.inclusions,
                        deduction: payload.deductions,
                        gross_salary: payload.gross_salary

                    }, config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchDesignationPayroll');

                }
            )
            return response
        },

        async deleteDesignationPayroll ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_designation_payroll + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchDesignationPayroll');

            return response
        },

        // Employee Payroll
        async fetchPayrollByDesignation ({ commit }, payload) {
            commit('PAYROLL_DESIGNATION_LOADING', true);
            commit('PAYROLL_DESIGNATION_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_payroll_by_designation + payload, config);
                let items = result.data.data

                commit('SET_PAYROLL_BY_DESIGNATION', items);
                return items

            } catch(error) {
                commit('PAYROLL_DESIGNATION_ERROR', true);
            }
            commit('PAYROLL_DESIGNATION_LOADING', false);
        },

        async addEmployeePayroll ({commit, dispatch}, payload) {
            commit('PAYROLL_LOADING', true);
            commit('PAYROLL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_employee_payroll, {
                    employee: payload.payroll.employee,
                    department: payload.payroll.department,
                    designation: payload.payroll.designation,
                    inclusion: payload.inclusions,
                    deduction: payload.deductions,
                    gross_salary: payload.gross_salary

                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchPayrollList', {perPage:payload.perPage, currentPage:payload.currentPage, search_term:payload.search_term, employee:payload.employee, department:payload.department, designation:payload.designation})
                return response

            }catch (e) {
                commit('PAYROLL_ERROR', true);
            }finally {
                commit('PAYROLL_LOADING', false);
            }
        },

        async editEmployeePayroll ({commit, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };

            await performNetworkCall(
                commit,
                keyList.smsUpdate,

                async () => {
                    response = await axios.patch(V1API.edit_employee_payroll + payload.id, {
                        inclusion: payload.inclusions,
                        deduction: payload.deductions,
                        gross_salary: payload.gross_salary

                    }, config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchEmployeePayrollDetails', payload.id);

                }
            )
            return response
        },

        async fetchPayrollList ({ commit }, payload) {
            commit('PAYROLL_LOADING', true);
            commit('PAYROLL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.department) params.append('department', payload.department);
                if (payload.designation) params.append('designation', payload.designation);
                if (payload.employee) params.append('employee', payload.employee);
                
                let url = V1API.get_employee_payroll + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results, pg_count} = result.data
                commit('SET_PAYROLL_LIST', {results, pg_count});
                

            } catch(error) {
                commit('PAYROLL_ERROR', true);
            }
            finally {
                commit('PAYROLL_LOADING', false);
            }
        },

        async deletePayroll ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_employee_payroll + payload.id, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchPayrollList', {perPage:payload.perPage, currentPage:payload.currentPage, search_term:payload.search_term, employee:payload.employee, department:payload.department, designation:payload.designation});

            return response
        },

        async fetchEmployeePayrollDetails ({ commit }, payload) {
            commit('PAYROLL_LOADING', true);
            commit('PAYROLL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_payroll_details + payload, config);
                let item = result.data.data

                commit('SET_PAYROLL_DETAILS', item);

            } catch(error) {
                commit('PAYROLL_ERROR', true);
            }
            finally {
                commit('PAYROLL_LOADING', false);
            }
        },

        async fetchEmployeePayrollHistory ({ commit }, payload) {
            commit('PAYROLL_LOADING', true);
            commit('PAYROLL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_payroll_history + payload, config);
                let items = result.data.data

                commit('SET_PAYROLL_HISTORY_LIST', items);

            } catch(error) {
                commit('PAYROLL_ERROR', true);
            }
            finally {
                commit('PAYROLL_LOADING', false);
            }
        },

        async toggleEmployeePayroll ({ commit, dispatch }, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.toggle_payroll_active + payload.id, config).then(result => {
                let items = result.data

                dispatch('fetchEmployeePayrollDetails', payload.e_id)
                dispatch('fetchEmployeePayrollHistory', payload.e_id)

                return items
            })

            return response
        },

        async deletePayrollHistory ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_employee_payroll + payload.id, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchEmployeePayrollDetails', payload.e_id)
            dispatch('fetchEmployeePayrollHistory', payload.e_id)

            return response
        }
    },

    getters: {}
}
