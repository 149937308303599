<template>
    <div>
        <Breadcrumbs main="Job" title="Job Responses" />
    
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <!-- <div class="card-header">
                            <div>
                                <h5>Response</h5>
                            </div>
                        </div> -->
    
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div style="display: flex; flex-direction: column;">
                                            <b-row>
                                                <b-col xl="6">
                                                    <b-input-group class="datatable-btn">
                                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                                        <b-input-group-append>
                                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
    
                                                </b-col>
    
                                                <b-col xl="6">
                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <div class="row">
    
                                                <div class="col-md-12">
                                                    <!-- <div v-for="(item, index) in filteredResponses" :key="index">
    
                                                        <div class="accordion" role="tablist">
                                                            <b-card no-body class="mb-1">
                                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                                    <b-button block v-b-toggle="'accordion-' + index" variant="primary">
                                                                        <div style="display: flex; flex-direction: column; gap: 5px;">
    
                                                                            <h7>{{ item.submitted_form.form_name }}</h7>
                                                                            <h7>Submitted By: {{ item.submitted_by.username }}</h7>
    
                                                                        </div>
                                                                    </b-button>
                                                                </b-card-header>
                                                                <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
                                                                    <b-card-body>
                                                                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                            <div>
                                                                                <div v-for="(info, index) in item.form_data" :key="index">
                                                                                    <div style="display: flex; gap: 16px; margin-bottom: 5px;">
                                                                                        <span>{{ info.data_label }}:</span>
                                                                                        <h6 v-if="info.data_type == 'filefield'">
                                                                                            <a :href="host + info.data_value" target="_blank" class="text-info">{{ host + info.data_value }}</a>
                                                                                        </h6>
                                                                                        <h6 v-else>{{ info.data_value }}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div style="display: flex; flex-direction: row; gap: 10px;">
                                                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(item.id)" title="Delete"></feather>
                                                                                <feather type="tag" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-add @click="add(item)" title="Add label"></feather>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
    
                                                        </div>
    
                                                    </div> -->
                                                 
                                                    <div class="table-responsive datatable-vue">
                                                        <b-table show-empty stacked="md" :items="responses" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                            <template #cell(index)="data">
                                                                {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                            </template>
    
                                                            <template #cell(submitted_by)="data">
                                                                <router-link :to="`/job/job-response/details/${data.item.id}`" class="link-style">
                                                                    <span>{{ data.item.applicant.first_name }} {{ data.item.applicant.last_name }}</span>
                                                                </router-link>
                                                                
                                                            </template>
    
                                                          
    
                                                            <template #cell(action)="data">
                                                                <div style="display: flex; flex-direction: row; gap: 10px;">
                                                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.id)" title="Delete"></feather>
                                                                </div>
                                                            </template>
    
                                                        </b-table>
                                                    </div>
    
                                                    <b-col class="my-1 p-0">
                                                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                    </b-col>
    
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    
        <!-- Delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this response?</div>
        </b-modal>

    
    </div>
    </template>
    
    <script>
    import {
        mapState
    } from 'vuex';
    import Datepicker from 'vuejs-datepicker';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    import {
        myHost
    } from "../../../_helpers/host";
    export default {
        components: {
            vueDropzone: vue2Dropzone,
            Datepicker
        },
    
        data() {
            return {
                host: myHost.host,
                delete_value: '',
    
          
               
    
                tablefields: [{
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'submitted_by',
                        label: 'Submitted By',
                        sortable: true
                    },
                 
                    {
                        key: 'action',
                        label: 'Action',
                        sortable: true
                    },
    
                ],
                filter: null,
    
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 100]
            }
        },
    
        computed: {
            ...mapState({
                responses: state => state.job.responses,
                
            }),
            // filteredResponses() {
            //     if (!this.selectedLabel) {
            //         return this.responses;
            //     }
            //     return this.responses.filter(response =>
            //         response.label.some(label => label.name === this.selectedLabel)
            //     );
            // }
            sortOptions() {
                return this.tablefields
                    .filter(f => f.sortable)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.key
                        };
                    });
            }
        },
    
        mounted() {
            this.$store.dispatch("job/fetchFormResponses", this.$route.params.id).then(() => {
                this.items = this.responses
                this.totalRows = this.items.length;
            });
          
    
        },
    
        watch: {
            responses(newValue, oldValue) {}
        },
        methods: {
            filter(label) {
                this.selectedLabel = label
            },
            deleteItem(id) {
                this.delete_value = id
            },
         
            handleDeleteOk() {
                this.$store.dispatch("job/deleterfpFormResonse", {
                    response_id: this.delete_value,
                    job_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.delete_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
          

    
        }
    };
    </script>
    
    <style scoped>
    .badge-style {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        background-color: #2c323f;
        padding: 0.375rem 1.75rem;
        color: white;
    
    }
    
    .badge-style:hover {
        background-color: #ff595d;
    }
    </style>
    