<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Trial Balance" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Trial Balance Breakdown</h5>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 20px">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                        <feather type="filter"></feather>
                                        <h7>Filter</h7>
                                    </div>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="start_date || end_date || statusSelected">
                                        <feather type="x"></feather>
                                        <h7>Clear All</h7>
                                    </div>
                                </div>
                            </b-col>

                        </b-row>
                        <div class="row mt-3" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!accountTrialLoading && !accountTrialError">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" sticky-header :items="items" :fields="tablefields" :filter="filter" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.item.journal_id}}
                                    </template>
                                    <template #cell(title)="data">
                                        {{data.item.title}} <br>
                                        <router-link :to="{ path: '/finance-and-accounts/account/ledger/' +  data.item.lid  }">
                                            <small style="font-weight: bold;">{{ data.item.lid }}</small>
                                        </router-link>
                                    </template>

                                    <template #cell(ledger_break_down)="data">
                                        <span style="display: flex; flex-direction: column; ">
                                            <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column">{{ledger.ledger_id.title}}
                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                        </span>

                                        <span style="display: flex; flex-direction: column;">

                                            <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column">
                                                <span style="margin-left: 16px;">{{ledger.ledger_id.title}}</span>
                                                <span v-if=" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>

                                        </span>

                                    </template>

                                    <template #cell(reference)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column">
                                                <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + ledger.ledger_id.lid }">
                                                    {{ ledger.ledger_id.lid }}
                                                </router-link>
                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>

                                            </span>
                                            <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column">
                                                <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + ledger.ledger_id.lid }">
                                                    {{ ledger.ledger_id.lid}}
                                                </router-link>
                                                <span v-if=" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                        </span>
                                    </template>

                                    <template #cell(debit)="data">
                                        <span>{{data.item.debit!=0?data.item.debit:""}}</span>
                                    </template>

                                    <template #cell(credit)="data">
                                        <span>{{data.item.credit!=0?(data.item.credit*-1):""}}</span>
                                    </template>

                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-row v-if="!accountTrialLoading && !accountTrialError">
                            <b-col class="col-7 ml-2" style="font-weight: bolder">Totals</b-col>
                            <b-col class="text-right mr-1" style="font-weight: bolder">{{trialBalanceSummary.debit}}</b-col>
                            <b-col class="text-right mr-1" style="font-weight: bolder">{{trialBalanceSummary.credit*-1}}</b-col>
                        </b-row>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment';
export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [
                // { key: 'index', label: '#', sortable:true },
                {
                    key: 'title',
                    label: 'Ledger title',
                    sortable: true,
                    class: 'text-start col-3'
                },
                // { key: 'lid', label: 'Ledger ID', sortable: true },
                {
                    key: 'group.title',
                    label: 'group',
                    class: 'text-start col-2'
                },
                {
                    key: 'group.pre_defined.title',
                    label: 'Pre-Defined',
                    class: 'text-start col-2',
                    sortable: true
                },
                {
                    key: 'debit',
                    label: 'Debit',
                    class: 'text-right'
                },
                {
                    key: 'credit',
                    label: 'Credit',
                    class: 'text-right'
                }

            ],

            items: [

            ],

           
            filter: "",
            initialLoad: true,
            isModifyingDate: false,
            filterShow: false,
            start_date: null,
            end_date: null,
            statusSelected: '',
            statuses: [{
                    id: 1,
                    name: 'Created Date',
                    value: 'date'
                },
                {
                    id: 2,
                    name: 'Entry Date',
                    value: 'entry_date'
                },
                
            ],
          

            totalRows: 1,
            currentPage: 2,
            // perPage: 25,
            pageOptions: [25, 50, 100],
            
        };
    },

    computed: {
        ...mapState('account', ["trialBalance", "trialBalanceSummary", "accountTrialError", "accountTrialLoading", "submitError", "submitSuccess", "submitLoading"]),
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        formattedOptions() {
            return [{
                    value: '',
                    text: 'Select Date Type',
                    disabled: true
                },
                ...this.statuses.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },
    },
    created() {
        // this.$store.dispatch('account/fetchAccountTrialBalance').then(result => {
        //     this.items = this.trialBalance
        //     this.totalRows = this.items.length
        // })

    },
    watch: {
        accountGroups(oldValue, newValue) {
        },
        start_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");

                        this.$store.dispatch("account/fetchAccountTrialBalance", {
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.items.length;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("account/fetchAccountTrialBalance", {
                          
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.items.length;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");

                        this.$store.dispatch("account/fetchAccountTrialBalance", {
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected
                        }).then(() => {
                       
                            this.totalRows = this.items.length;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("account/fetchAccountTrialBalance", {
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected
                        }).then(() => {
                           
                            this.totalRows = this.items.length;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },
        statusSelected(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("account/fetchAccountTrialBalance", {
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected
                }).then(() => {
                   
                    this.totalRows = this.items.length;
                    this.initialLoad = false;
                });
            }

        },


    },

    mounted() {
    

                this.fetchInitial();


    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("account/fetchAccountTrialBalance", {
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected
            }).then(() => {
                this.items = this.trialBalance
                this.totalRows = this.items.length
                this.initialLoad = false;
            });
        },
        clearFilter() {
                this.filterShow = false,
                this.filter = null,
                this.start_date = null,
                this.end_date = null,
                this.statusSelected = ''
        },
        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 2;
        },
    },
};
</script>
